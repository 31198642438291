/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Bloc, Flex } from 'blocjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import ContactContainer from './container';
import Text from '../text';
import { Inputfield } from '../inputs';
import Button from '../button';
import UserAPI from '../../services';

const EditGroupComponent = ({ handleSwitchPage, groupState }) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();

  const { mutate: updateContactGroup, isPending: isCreateGroupPending } =
    useMutation({
      mutationFn: (payload) =>
        userAPI.updateContactGroup({ id: groupState.id, payload }),
    });
  const [groupNameText, setGroupNameText] = useState(groupState?.name || '');

  const [groupNameError, setGroupNameError] = useState(null);

  const handleInputGroupName = (e) => {
    const textInput = e.target.value;

    if (textInput && textInput.length >= 30) {
      setGroupNameError('Option name should not exceed 30 characters');
    } else {
      setGroupNameText(textInput);
      setGroupNameError(null);
    }
    e.preventDefault();
    setGroupNameText(textInput);
  };

  const handleSaveChanges = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const check = groupNameText.length > 3 && !groupNameError;

    if (groupNameText.length < 3) {
      setGroupNameError('Field Name should have at least 3 characters');
    }

    if (check) {
      const payload = {
        group_name: groupNameText,
        org_id: groupState.org_id,
        criteria: groupState.criteria,
      };

      updateContactGroup(payload, {
        onSuccess: () => {
          toast.success('Group updated successfully');
          queryClient.invalidateQueries({
            queryKey: ['get_all_groups'],
          });
          setGroupNameText('');

          handleSwitchPage();
        },
        onError: (error) => {
          toast.error(
            error?.response?.data?.error?.message || 'Failed to update group'
          );
        },
      });
    }
  };

  return (
    <ContactContainer>
      <Bloc as="form" p="20px">
        <Flex flexDirection="column">
          <Bloc
            pb="20px"
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
            }}
          >
            <Text
              as="h1"
              fontSize={['16px', '28px']}
              style={{
                fontWeight: '500',
              }}
            >
              {!groupState ? 'New Group' : groupState?.name}
            </Text>
          </Bloc>
          <Flex
            pt="15px"
            flexWrap={['wrap', 'nowrap']}
            style={{
              columnGap: '15px',
            }}
          >
            <Flex
              width="100%"
              style={{
                gap: '6px',
              }}
              flexDirection="column"
            >
              <Text fontSize="14px">Rename your group</Text>
              <Inputfield
                size="contact"
                required
                name="text"
                value={groupNameText}
                placeholder="Group Name"
                onChange={(e) => handleInputGroupName(e)}
                hasLabel={false}
              />
              {groupNameError && (
                <Text fontSize="10px" color="red">
                  {groupNameError}
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex
            pt="20px"
            style={{
              borderTop: '1px solid rgba(0, 0, 0, 0.06)',
              columnGap: '6px',
            }}
          >
            <Button
              disabled={groupNameError}
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={(e) => handleSaveChanges(e)}
              loading={isCreateGroupPending}
            >
              Save changes
            </Button>
            <Button
              profile="secondary"
              size="contact"
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
                color: 'black',
              }}
              onClick={() => handleSwitchPage()}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Bloc>
    </ContactContainer>
  );
};

export default EditGroupComponent;
