import React, { useState, useEffect } from 'react';
import { Box, Bloc, Flex } from 'blocjs';
import { useLocation, Link, Redirect } from 'react-router-dom';
import {
  Text,
  Card,
  Spinner,
  Layout,
  Button,
  Inputfield,
  ToggleSwitch,
} from '../../../../components';
import TelcosTable from './telcos/table';
import AddOrEditService from './localServices/addOrUpdateService';
import EditCountry from './edit';
import { useFetchCountryRoutes } from '../../../../hooks';
import AddOrEditTelco from './telcos/addOrUpdateTelco';
import LocalServices from './localServices';

const CountryDetails = () => {
  const [isAddOrUpdateService, setIsAddOrUpdateService] = useState(false);
  const [isAddOrUpdateTelco, setIsAddOrUpdateTelco] = useState(false);
  const [isEditingService, setIsEditingService] = useState(false);
  const [isEditingTelco, setIsEditingTelco] = useState(false);
  const [isEditCountry, setIsEditCountry] = useState(false);
  const [countryId, setCountryId] = useState(null);
  const [selectedService, setSelectedService] = useState([]);
  const [selectedTelco, setSelectedTelco] = useState([]);
  const location = useLocation();

  const { data: countryRoutesData, isLoading } =
    useFetchCountryRoutes(countryId);

  const countryData = countryRoutesData?.country || [];

  const telcos = countryRoutesData?.telcos || [];

  const localServices = countryRoutesData?.local_services || [];
  const intlServicesFormatted = countryRoutesData?.international_services || [];
  const inUseServices = countryRoutesData?.in_use;

  useEffect(() => {
    if (location.state && location.state.id === 'editCountry') {
      setIsEditingService(true);
      setCountryId(location.state.countryId);
    }
  }, [location.state]);

  const { state } = location;
  useEffect(() => {
    if (state) {
      setCountryId(state.countryId);
    }
  }, [state]);

  const onAddService = () => {
    setIsAddOrUpdateService(true);
    setIsEditingService(false);
  };

  const cancelServiceUpdate = () => {
    setIsAddOrUpdateService(false);
    setIsEditingService(false);
    // setSelectedCountries([]);
  };

  const onEditService = (service) => {
    setIsEditingService(true);
    setIsAddOrUpdateService(true);

    const serviceObj = localServices.find((item) => item.id === service);
    setSelectedService(serviceObj);
  };

  const onAddTelco = () => {
    setIsAddOrUpdateTelco(true);
    setIsEditingTelco(false);
  };

  const onEditTelco = (telco) => {
    setIsAddOrUpdateTelco(true);
    setIsEditingTelco(true);
    const telcoObj = telcos.find((item) => item.id === telco);
    setSelectedTelco(telcoObj);
  };

  const cancelTelcoUpdate = () => {
    setIsAddOrUpdateTelco(false);
    setIsEditingTelco(false);
    // setSelectedTelcos([]);
  };

  if (!state) {
    return <Redirect to="/admin/manage/countries" />;
  }

  const serviceData = isEditingService ? selectedService : undefined;
  const telcoData = isEditingTelco ? selectedTelco : undefined;

  return (
    <Layout>
      <Flex
        justifyContent="space-between"
        width="100%"
        flexWrap="wrap"
        alignItems="center"
      >
        <Box style={{ float: 'left' }} minWidth="16%" mb={6}>
          <Text as="h1" style={{ lineHeight: '32px' }} fontSize={[24, 28]}>
            {countryData?.name} {isLoading && <Spinner size="large" />}
          </Text>
        </Box>
        <Flex justifyContent="space-between" mb={[0, 6]}>
          <Box
            style={{ float: 'right' }}
            width="100%"
            as={Link}
            to="/admin/manage/countries"
          >
            <Button
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
                float: 'right',
              }}
              profile="accentLight"
              size="contact"
              data-testid="go-back-to-organizations"
            >
              Back To Country list
            </Button>
          </Box>
        </Flex>
      </Flex>

      {!isEditCountry ? (
        <Bloc ml={[0, '16%']} mb={15}>
          <Card>
            <Flex justifyContent="space-between">
              <Box pb={18}>
                <Text variant="h5">
                  {' '}
                  Country Details {isLoading && <Spinner size="large" />}{' '}
                </Text>
              </Box>
              <Bloc>
                <Button
                  style={{
                    textTransform: 'capitalize',
                    fontWeight: 'normal',
                    float: 'right',
                  }}
                  profile="accentLight"
                  size="small"
                  onClick={() => setIsEditCountry(true)}
                  data-test="update-btn"
                >
                  Edit details
                </Button>
              </Bloc>
            </Flex>

            <Box mt={4} />
            <Box>
              <Bloc>
                <Flex justifyContent="center">
                  <Bloc width={[1, 1 / 2]} mr={[1, '10%']}>
                    <Bloc pl={0} color="rgb(62, 72, 77)">
                      <Inputfield
                        type="text"
                        name="Country name"
                        defaultValue={countryData?.name || ''}
                        placeholder="Country name"
                        hint="Fill in name of the Country"
                        disabled
                      />
                    </Bloc>

                    <Bloc pl={0} color="rgb(62, 72, 77)">
                      <Inputfield
                        type="text"
                        name="Region Code"
                        defaultValue={countryData?.region_code || ''}
                        placeholder="Region Code"
                        hint="Fill in Region Code"
                        disabled
                      />
                    </Bloc>

                    <Box width={1} mb={6}>
                      <ToggleSwitch
                        label="Phone Number Portability"
                        id="isSenderIdRegulated"
                        checked={
                          countryData?.has_phone_number_portability || false
                        }
                        onChange={() => {}}
                        disabled
                      />
                    </Box>
                  </Bloc>

                  <Bloc width={[1, 1 / 2]} mr={[1, '10%']}>
                    <Bloc pl={0} color="rgb(62, 72, 77)">
                      <Inputfield
                        type="text"
                        name="MCC"
                        defaultValue={countryData?.mcc || ''}
                        placeholder="MCC"
                        hint="Fill in MCC"
                        disabled
                      />
                    </Bloc>

                    <Bloc pl={0} color="rgb(62, 72, 77)">
                      {countryData.country_code && (
                        <Inputfield
                          type="text"
                          name="Country Code"
                          defaultValue={
                            countryData ? `+${countryData?.country_code}` : ''
                          }
                          placeholder="Country Code"
                          hint="Fill in Country Code"
                          disabled
                        />
                      )}
                    </Bloc>

                    <Box width={1} mb={6}>
                      <ToggleSwitch
                        label="SenderId Regulated"
                        id="isSenderIdRegulated"
                        checked={countryData?.is_sender_id_regulated || false}
                        onChange={() => {}}
                        disabled
                      />
                    </Box>
                  </Bloc>
                </Flex>
              </Bloc>
            </Box>
          </Card>
        </Bloc>
      ) : (
        <EditCountry
          setIsEditCountry={setIsEditCountry}
          countryData={countryData}
        />
      )}

      {!isAddOrUpdateTelco && (
        <Box mt={32}>
          <Flex
            justifyContent="space-between"
            width="100%"
            flexWrap="wrap"
            alignItems="center"
          >
            <Box style={{ float: 'right' }} width="100%" onClick={onAddTelco}>
              <Button
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 'normal',
                  float: 'right',
                }}
                profile="accentLight"
                size="small"
                data-testid="add-new-telco"
              >
                Add New Telco
              </Button>
            </Box>
          </Flex>
          <Bloc
            mt={27}
            ml={[0, '16%']}
            bg="surfaces.0"
            border="1px solid"
            borderColor="#D4DEE8"
            borderRadius="10px"
          >
            <TelcosTable
              telcos={telcos}
              isLoading={isLoading}
              countryData={countryData}
              onAddService={onAddService}
              onEditTelco={onEditTelco}
              localServices={localServices}
              intlServices={intlServicesFormatted}
              inUseServices={inUseServices}
            />
          </Bloc>
        </Box>
      )}

      {!isAddOrUpdateService && (
        <LocalServices
          localServicesData={localServices}
          isLoading={isLoading}
          countryData={countryData}
          onAddService={onAddService}
          onEditService={onEditService}
        />
      )}

      {isAddOrUpdateService && (
        <AddOrEditService
          isEditingService={isEditingService}
          serviceData={serviceData}
          cancelServiceUpdate={cancelServiceUpdate}
          countryData={countryData}
          setIsAddOrUpdateService={setIsAddOrUpdateService}
        />
      )}

      {isAddOrUpdateTelco && (
        <AddOrEditTelco
          setIsAddOrUpdateTelco={setIsAddOrUpdateTelco}
          isEditingTelco={isEditingTelco}
          telcoData={telcoData}
          countryData={countryData}
          localServices={localServices}
          intlServices={intlServicesFormatted}
          cancelTelcoUpdate={cancelTelcoUpdate}
        />
      )}
      <Bloc mt={100} />
    </Layout>
  );
};

export default CountryDetails;
