/* eslint-disable import/no-unresolved */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Flex, Bloc } from 'blocjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from 'sonner';
import AddOrUpdateSenderID from './addOrUpdateSenderID';
import {
  useFetchOrg,
  useFetchSenderIDDetails,
  useFetchSenderIDs,
} from '../../../../hooks';
import UserAPI from '../../../../services';
import { CurrentUserContext } from '../../../../contexts';
import SenderIDsTable from './senderIDsTable';
import {
  Text,
  Button,
  Spinner,
  Icon,
  CheckedBox,
  Inputfield,
  Pagination,
} from '../../../../components';
import AccountContainer from '../../../userAccount/container';

const ListSenderIDs = ({
  setSelectedSenderIDs,
  selectedSenderIDs,
  setIsSettingDefault,
  setIsRemoving,
  isRemoving,
  isSettingDefault,
}) => {
  const myRef = useRef(null);
  const itemsPerPage = 25;
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const { currentUser } = useContext(CurrentUserContext);
  const [isAddOrUpdateSenderID, setIsAddOrUpdateSenderID] = useState(false);
  const [isEditingSenderID, setIsEditingSenderID] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [fetchEnable, setFetchEnable] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const { push } = useHistory();
  const location = useLocation();
  const { data: orgData } = useFetchOrg({ enabled: true });
  const inUseOrgData = orgData?.filter((org) => org.in_use)[0];

  const { data, isLoading, isFetching } = useFetchSenderIDs({
    enabled: fetchEnable,
    name: searchValue,
  });

  const orgSenderIDs = data?.sender_ids || [];

  const { data: selectedSenderIDData, isLoading: isSelectedSenderIDLoading } =
    useFetchSenderIDDetails(selectedSenderIDs[0]?.id);

  useEffect(() => {
    if (selectedSenderIDs.length > 0 && selectedSenderIDData) {
      const senderIDCountries = selectedSenderIDData.countries || [];
      const hasDifferentPendingStatus = senderIDCountries.some(
        (item) => item.status !== 'pending'
      );
      if (hasDifferentPendingStatus) {
        setIsSettingDefault(true);
      } else {
        setIsSettingDefault(false);
      }
    }
  }, [selectedSenderIDData, selectedSenderIDs, setIsSettingDefault]);

  const { mutate: mutateSetDefault, isPending: isSetDefaultLoading } =
    useMutation({ mutationFn: (id) => userAPI.setDefaultSenderID(id) });

  useEffect(() => {
    if (location.state && location.state.id === 'addSenderId') {
      setIsAddOrUpdateSenderID(true);
      if (myRef.current) {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.state]);

  const onCheckBox = (senderID, e) => {
    e.stopPropagation();
    const isCurrentlySelected = selectedSenderIDs.some(
      (item) => item.id === senderID.id
    );
    if (isCurrentlySelected) {
      setSelectedSenderIDs([]);
    } else {
      setSelectedSenderIDs([senderID]);
    }
  };

  const setCheckbox = (rowId) => {
    if (selectedSenderIDs.some((item) => item.id === rowId)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16}>
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  const setRowBackgroundColor = (rowId) => {
    if (selectedSenderIDs.some((item) => item.id === rowId)) {
      return '#BDFBF3';
    }
    return 'transparent';
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  const onRemoveSenderID = (e) => {
    e.stopPropagation();
    setIsRemoving(true);
  };

  const handleSetDefault = async (e) => {
    e.stopPropagation();
    if (selectedSenderIDs.length === 1) {
      const senderId = selectedSenderIDs.map((value) => value.id);
      mutateSetDefault(senderId[0], {
        onSuccess: async () => {
          toast.success('The sender ID is set default successfully');
          await queryClient.invalidateQueries({ queryKey: ['listSenderIDs'] });
          setSelectedSenderIDs([]);
          setIsSettingDefault(false);
        },
        onError: (error) => {
          toast.error(error?.response?.data?.error?.message);
        },
      });
    }
  };

  const handleRowClick = (e, item) => {
    e.preventDefault();
    push('/sender-id-details', item);
  };

  const onAddSenderID = () => {
    setIsAddOrUpdateSenderID(true);
    setIsEditingSenderID(false);
    setSelectedSenderIDs([]);
  };

  const onEditSenderID = () => {
    setIsAddOrUpdateSenderID(true);
    setIsEditingSenderID(true);
    setSelectedSenderIDs([]);
  };
  let debounceTimer;

  const handleSearch = (e) => {
    const query = e.target.value;
    e.preventDefault();
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      setFetchEnable(false);

      setTimeout(() => {
        setSearchValue(query);

        setFetchEnable(true);

        setTimeout(() => {
          setSearchValue('');
        }, 0);
      }, 500);
    }, 1000);
  };

  const isMainUser = inUseOrgData?.created_by_id === currentUser?.id;

  return (
    <AccountContainer pt={0}>
      <Flex
        flexDirection="column"
        style={{
          gap: '20px',
        }}
        p="20px"
        id="senderIds"
        ref={myRef}
      >
        <Text color="black" fontSize="18px" fontWeight="600">
          Organization sender IDs
        </Text>

        {isAddOrUpdateSenderID && (
          <Bloc
            borderRadius=" 7.741px"
            p="20px"
            boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.32)"
          >
            <AddOrUpdateSenderID
              setIsAddOrUpdateSenderID={setIsAddOrUpdateSenderID}
              isEditingSenderID={isEditingSenderID}
              senderIDData={selectedSenderIDData}
            />
          </Bloc>
        )}

        <Bloc
          style={{
            position: 'relative',
            opacity: isAddOrUpdateSenderID ? 0.2 : 1,
          }}
        >
          {isAddOrUpdateSenderID && (
            <Bloc
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
              }}
            />
          )}
          <Bloc borderRadius="3px" bg="surfaces.0" border="1px solid #E2E2E2">
            <Flex
              justifyContent="space-between"
              flexWrap="wrap"
              alignItems="center"
              padding="15px 20px"
              style={{
                gap: '10px',
                borderBottom: '1px solid #E2E2E2',
              }}
            >
              <Flex
                alignItems="center"
                width="fit-content"
                style={{
                  border: '1px solid #E0DBDB',
                  borderRadius: '4px',
                  padding: '0 10px',
                }}
              >
                <Icon
                  name="search"
                  color="#9DA1A8"
                  size={16}
                  dimension="24x24"
                />
                <Inputfield
                  // ref={searchInputRef}
                  width="200px"
                  size="contact"
                  style={{
                    border: 'none',
                  }}
                  required
                  name="text"
                  placeholder="Find a sender ID"
                  onChange={handleSearch}
                  hasLabel={false}
                  hasHint={false}
                />
              </Flex>
              {isMainUser && selectedSenderIDs.length < 1 && (
                <Button
                  profile="accentLight"
                  size="contact"
                  onClick={onAddSenderID}
                  data-testid="add-senderID"
                >
                  Add sender ID
                </Button>
              )}
              {selectedSenderIDs.length === 1 && (
                <Flex flexWrap="wrap">
                  {!isRemoving && (
                    <Flex
                      flexWrap="wrap"
                      style={{ gap: '6px' }}
                      alignItems="center"
                    >
                      {isSelectedSenderIDLoading && (
                        <Bloc>
                          <Spinner size="large" />
                        </Bloc>
                      )}

                      {isSettingDefault && !selectedSenderIDs[0].is_default && (
                        <Bloc>
                          <Button
                            profile="accentLight"
                            size="contact"
                            type="button"
                            loading={isSetDefaultLoading}
                            data-testid="refresh-token"
                            onClick={(e) => handleSetDefault(e)}
                          >
                            Set as default sender id
                          </Button>
                        </Bloc>
                      )}

                      <Bloc>
                        <Button
                          profile="accentLight"
                          size="contact"
                          type="button"
                          loading={isLoading}
                          data-testid="refresh-token"
                          onClick={onEditSenderID}
                        >
                          Edit Sender ID
                        </Button>
                      </Bloc>

                      <Bloc>
                        <Button
                          profile="secondary"
                          size="contact"
                          type="button"
                          loading={isLoading}
                          data-testid="refresh-token"
                          onClick={(e) => onRemoveSenderID(e)}
                        >
                          Remove Sender ID
                        </Button>
                      </Bloc>
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>

            {orgSenderIDs.length < 1 && (
              <Bloc
                bg="warning"
                borderRadius={2}
                px={4}
                py={22}
                mx={15}
                mt={4}
                boxSizing="border-box"
              >
                <Text fontSize={12} color="accents.8">
                  You must create a Sender ID. A Sender ID is a unique
                  identifier that ensures your recipients can recognize the
                  sender of the message. It&apos;s a quick and easy process to
                  set up your Sender ID.
                </Text>
              </Bloc>
            )}

            {isFetching ? (
              <Spinner size="small" />
            ) : (
              <SenderIDsTable
                isLoading={isFetching}
                orgSenderIDs={orgSenderIDs}
                isMainInUseOrg={inUseOrgData}
                onCheckBox={onCheckBox}
                setCheckbox={setCheckbox}
                setRowBackgroundColor={setRowBackgroundColor}
                handleRowClick={handleRowClick}
                isMainUser={isMainUser}
              />
            )}
          </Bloc>
        </Bloc>
      </Flex>
      {data?.total > 25 && (
        <Bloc px="20px">
          <Pagination
            itemsPerPage={itemsPerPage}
            pageNumber={pageNumber}
            pages={data?.pages}
            pageCount={data?.pages?.pages}
            ml={0}
            isFetching={isLoading}
            isLoading={isLoading}
            changePage={changePage}
          />
        </Bloc>
      )}
    </AccountContainer>
  );
};

export default ListSenderIDs;
