import styled from 'styled-components';
import { space } from 'styled-system';

const Divider = styled('div')(
  {
    height: '1px',
    backgroundColor: '#DFDBDB',
  },
  space,
);

export default Divider;
