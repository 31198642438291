
export const search = {
  position: 'sticky',
  top: 0,
  backgroundColor: '#fff',
  borderBottom: '1px solid #D9DEE3'
};

export const searchInput = {
  width: '100%',
  fontSize: 14,
  height: '40px',
  borderRadius: '3px',
  boxSizing: 'border-box',
  lineHeight: '15px',
  padding: '0 8px',
  outline: 'none',
  color: 'black',
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  border: 'none',
  '::placeholder': {
    color: '#9DA1A8',
  },
};

export const inputSelectBox = {
  width: '100%',
};

export const dropdownLayout = {
  '::webkitScrollbar': {
    width: 7
  },
  '::webkitScrollbarTrack': {
    boxShadow: 'inset 0 0 5px white', 
    borderRadius: 10,
  },
  '::webkitScrollbarThumb': {
    backgroundColor: 'green',
    borderRadius: 10,
  },
};

