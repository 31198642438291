/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Bloc, Flex } from 'blocjs';
import { search, searchInput } from './style';

import Icon from '../icon';

const styles = {
  search,
  searchInput,
};

function Content({
  handleSelectedItem,
  searchItemName,
  searchedTerm,
  itemsList,
  hasSearch,
}) {
  return (
    <>
      {hasSearch && (
        <Bloc
          style={{
            border: '1px solid #E0DBDB',
            borderRadius: '5px',
            margin: '12px 8px 0px',
          }}
        >
          <Bloc display="flex" alignItems="center" padding="0 12px">
            <Icon name="search" color="#9DA1A8" size={16} dimension="24x24" />
            <Bloc
              as="input"
              type="text"
              value={searchedTerm}
              onChange={searchItemName}
              placeholder="Search"
              style={styles.searchInput}
              data-testid="search"
              margin={0}
              color="#9DA1A8"
            />
          </Bloc>
        </Bloc>
      )}
      {itemsList.length > 0 ? (
        <>
          <Bloc as="ul" py="10px" px="2px" margin={0}>
            {itemsList.map((item, index) => (
              <Bloc
                as="li"
                display="flex"
                alignItems="center"
                key={index}
                onClick={(e) => handleSelectedItem(e, item)}
                data-testid="country"
                aria-hidden="true"
                padding={12}
                style={{
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  overflow: 'auto',
                  borderRadius: '6px',
                }}
                className="hoverDefault"
              >
                <Flex alignItems="center">
                  <Bloc
                    as="span"
                    fontSize="12px"
                    mr="6px"
                    fontWeight="500"
                    style={{ textTransform: 'capitalize' }}
                    color={item.placeholder ? '#e0dbdb' : 'initial'}
                  >
                    {item.name}
                  </Bloc>

                  {item.is_default && (
                    <Icon
                      name="check-broken-circle"
                      height={14}
                      color="#7A8794"
                      size={12}
                      dimension="24x24"
                    />
                  )}
                </Flex>
              </Bloc>
            ))}
          </Bloc>
        </>
      ) : (
        <Bloc
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={12}
        >
          <Bloc as="span" bold fontSize={16} fontWeight="bold">
            No result found
          </Bloc>
          <Bloc as="span" color="#9DA1A8" pt={3} fontSize={12}>
            {' '}
            Try a different search.{' '}
          </Bloc>
        </Bloc>
      )}
    </>
  );
}

export default Content;
