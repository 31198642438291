import { useQuery } from '@tanstack/react-query';
import UserAPI from '../services';

const useGetContactFields = ({ isMessageForm }) => {
  const userAPI = new UserAPI();
  const { data: fieldsData, isLoading } = useQuery({
    queryKey: ['get_all_fields'],
    queryFn: () => userAPI.getContactFields(),
  });

  let fieldHeaders = [];

  if (!isLoading) {
    fieldHeaders = fieldsData
      ? fieldsData
          .filter((item) => {
            if (isMessageForm) {
              return !['phonenumber', 'name', 'phone_number'].includes(
                item.name
              );
            }

            return (
              !['phonenumber', 'name', 'phone_number'].includes(item.name) &&
              item.featured === true
            );
          })
          .map((item) => item.name.toLowerCase())
      : [];
  }

  return {
    isLoading,
    fieldsData,
    fieldHeaders,
  };
};

export default useGetContactFields;
