import React from 'react';
import DatePicker from 'react-datepicker';
import { Box, Flex } from 'blocjs';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../button';
import Spinner from '../spinner';

const DateFilter = ({
  startDate,
  endDate,
  onDateChange,
  onSearch,
  isLoading,
}) => (
  <Flex flexWrap="wrap" style={{ gap: '10px' }}>
    <Flex flexWrap="wrap" style={{ gap: '10px' }}>
      <Box
        height={40}
        width="200px"
        style={{ border: '1px solid #DEE1E7', borderRadius: '4px' }}
      >
        <DatePicker
          placeholderText="Start date"
          selected={startDate}
          selectsStart
          maxDate={new Date()}
          startDate={startDate}
          calendarStartDay={1}
          endDate={endDate}
          dateFormat="dd/MM/yyyy"
          onChange={(date) => onDateChange('start', date)}
          calendarClassName="calendar"
        />
      </Box>
      <Box
        height={40}
        width="200px"
        style={{ border: '1px solid #DEE1E7', borderRadius: '4px' }}
      >
        <DatePicker
          placeholderText="End date"
          selected={endDate}
          selectsEnd
          maxDate={new Date()}
          startDate={startDate}
          calendarStartDay={1}
          minDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yyyy"
          onChange={(date) => onDateChange('end', date)}
          calendarClassName="calendar"
        />
      </Box>
    </Flex>
    <Button
      profile="accentLight"
      size="normal"
      onClick={onSearch}
      data-test="filter-btn"
      style={{
        width: '95px',
        height: '40px',
        padding: 0,
        float: 'right',
      }}
    >
      {!isLoading ? (
        'Search'
      ) : (
        <Spinner size="large" style={{ marginLeft: 0, marginBottom: '-4px' }} />
      )}
    </Button>
  </Flex>
);

export default DateFilter;
