/* eslint-disable import/no-unresolved */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import UserAPI from '../../../services';

const useRemoveMember = ({
  setSelectedMembers,
  selectedMembers,
  setIsMemberRemoving,
}) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();

  const { mutate: mutateRemove, isPending: memberIsPending } = useMutation({
    mutationFn: (payload) => userAPI.removeOrgMembers(payload),
  });

  const handleRemoveMember = async (e) => {
    e.stopPropagation();

    if (selectedMembers.length > 0) {
      const users = selectedMembers.map((value) => value.id);
      mutateRemove(users, {
        onSuccess: async () => {
          toast.success('The member is removed successfully');
          await queryClient.invalidateQueries({ queryKey: ['listMembers'] });
          setIsMemberRemoving(false);
          setSelectedMembers([]);
        },
        onError: (error) => {
          toast.error(error?.response?.data?.error?.message);
          setIsMemberRemoving(false);
        },
      });
    }
  };

  return {
    handleRemoveMember,
    memberIsPending,
  };
};

export default useRemoveMember;
