import React, { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Bloc, Box, Flex } from 'blocjs';
import { Link } from 'react-router-dom';
import Chevron from '../carets/chevron';
import Avatar from '../avatar';
import Dropdown from './dropdown';
import { refreshProfileImg } from '../../utils';
// import NavigationButton from '../navigationLink';
// import useIsPathsOn from '../../hooks/useCheckPathname';
import Text from '../text';
import LinksWithDropdown from '../navigation/linksWithDropdown';
import { useFetchOrg } from '../../hooks';

const AvatarWithDropdown = ({
  user,
  profile,
  uniqueKey,
  isRootPath,
  isAdminView,
  isAuthenticated = false,
}) => {
  const { data: orgData } = useFetchOrg({
    enabled: !isAdminView,
  });
  const inUseOrgData = orgData?.filter((org) => org.in_use)[0];
  const orgName = inUseOrgData?.name;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRotated, setRotate] = useState(false);
  const firstLetterOfUsername =
    orgName?.charAt(0) || user?.username?.charAt(0) || '';

  const profileImg = refreshProfileImg(profile, uniqueKey);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsMenuOpen(false);
        setRotate(false);
      }}
      style={{
        zIndex: 100,
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
      }}
    >
      <Bloc
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          cursor: 'pointer',
          columnGap: '6px',
        }}
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
          setRotate(!isMenuOpen);
        }}
        data-test="avatar-caret"
      >
        <Flex alignItems="center">
          <Box>
            <Avatar
              imageUrl={profileImg}
              iconColor="accents.2"
              firstLetterOfUsername={firstLetterOfUsername}
            />
          </Box>
        </Flex>
        <Box>
          <Chevron color={!isRootPath ? 'black' : 'white'} rotate={isRotated} />
        </Box>
      </Bloc>
      {isMenuOpen && (
        <Dropdown isAdminView={isAdminView} isAuthenticated={isAuthenticated}>
          {isAuthenticated ? (
            <LinksWithDropdown
              isAdmin={isAdminView}
              setIsMenuOpen={setIsMenuOpen}
            />
          ) : (
            <Bloc>
              <Bloc py={4} borderBottom="1px solid #d5dae2">
                <Bloc as="a" href="/#pricing">
                  <Text as="span" hoverAsLink variant="normal">
                    {' '}
                    Pricing{' '}
                  </Text>
                </Bloc>
              </Bloc>
              <Bloc py={4} borderBottom="1px solid #d5dae2">
                <Bloc
                  as="a"
                  href="https://github.com/pindoio/pindo-cli"
                  target="_blank"
                >
                  <Text as="span" hoverAsLink variant="normal">
                    {' '}
                    Docs{' '}
                  </Text>
                </Bloc>
              </Bloc>
              <Bloc py={4}>
                <Bloc
                  as={Link}
                  to="/login"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Text as="span" hoverAsLink variant="normal">
                    {' '}
                    Login{' '}
                  </Text>
                </Bloc>
              </Bloc>
            </Bloc>
          )}
        </Dropdown>
      )}
    </ClickAwayListener>
  );
};

export default AvatarWithDropdown;
