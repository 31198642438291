import React from 'react';
import { Box } from 'blocjs';

import Icon from '../icon';

const CheckedBox = ({ color, size }) => (
    <Box position="relative" width={16} height={16}>
      <Icon name="square" color={color} size={size} style={{ position: "absolute" }}/>
      <Icon name="check" color={color} size={size} style={{ position: "absolute", bottom: "4px", left: "3px" }} />
    </Box>
);

export default CheckedBox;
