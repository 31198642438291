/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Bloc, Flex } from 'blocjs';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ClickAwayListener from 'react-click-away-listener';
// eslint-disable-next-line import/no-unresolved
import { toast } from 'sonner';
import UserAPI from '../../../../services';
import {
  countriesList,
  requestMethod,
  urlValidation,
  SENDERID_REGEX,
} from '../../../../utils';

import {
  Icon,
  Button,
  Inputfield,
  CheckedBox,
  Countries,
  Chevron,
  Text,
} from '../../../../components';

const AddOrUpdateSenderID = ({
  setIsAddOrUpdateSenderID,
  isEditingSenderID,
  senderIDData = undefined,
}) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [headerMethod, setHeaderMethod] = React.useState('');
  const [headers, setHeaders] = React.useState([{ key: '', value: '' }]);

  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    formState,
    setValue,
  } = useForm();

  const [checkedBox, setCheckedBox] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countryError, setCountryError] = useState(null);
  const [aggrementError, setAggrementError] = useState('');
  const [senderIdName, setSenderIdName] = useState('');
  const [, setOverallError] = useState(false);

  const { mutate, isPending } = useMutation({
    mutationFn: (payload) => userAPI.requestSenderID(payload),
  });

  const { mutate: mutateUpdate } = useMutation({
    mutationFn: (payload) => userAPI.updateSenderID(payload),
  });

  useEffect(() => {
    if (isEditingSenderID && senderIDData) {
      const senderIDName = senderIDData.name;
      setSenderIdName(senderIDName);
      const senderIDCountries = senderIDData.countries || [];
      const formattedHeaders = Object.entries(
        senderIDData?.webhook?.headers || {}
      ).map(([key, value]) => ({ key, value }));
      setHeaderMethod(senderIDData?.webhook?.method?.split('.')[1] || 'GET');
      setHeaders(formattedHeaders);

      if (senderIDCountries.length > 0) {
        const senderIDCountriesFormatted = countriesList.filter((obj) =>
          senderIDCountries.some(
            (existingCountry) => existingCountry.iso_code === obj.code
          )
        );
        setSelectedCountries(senderIDCountriesFormatted);
      }
    }
  }, [senderIDData, isEditingSenderID]);

  const onCheckBox = (e) => {
    e.stopPropagation();
    setCheckedBox(!checkedBox);
    setAggrementError('');
  };

  const setCheckbox = () => {
    if (checkedBox) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16} display="flex">
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  useEffect(() => {
    if (selectedCountries < 1 || !checkedBox) {
      setOverallError(true);
    } else {
      setOverallError(false);
    }
  }, [checkedBox, formState.isValid, selectedCountries]);

  const handleHeaderChange = (index, event) => {
    const newHeaders = headers.map((header, i) => {
      if (i === index) {
        return { ...header, [event.target.name]: event.target.value };
      }
      return header;
    });
    setHeaders(newHeaders);
  };

  const addHeaderField = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };

  const deleteHeaderField = (indexToDelete) => {
    setHeaders(headers.filter((_, index) => index !== indexToDelete));
  };

  const onFormSubmit = async (dataInputs) => {
    if (selectedCountries < 1 || !checkedBox) {
      if (selectedCountries < 1) setCountryError('Select a country');
      if (!checkedBox)
        setAggrementError(
          'Agree to the terms and conditions before requesting'
        );
      return;
    }

    const codeCountries = selectedCountries.map((country) => country.code);

    const parsedSenderID = dataInputs.sender_id;
    const payload = {
      sender_id: parsedSenderID,
      countries: codeCountries,
    };
    if (dataInputs?.outbound_sms_webhook_url) {
      payload.webhook = {
        url: dataInputs.outbound_sms_webhook_url,
        method: headerMethod.toLowerCase(),
        headers: headers.reduce((acc, header) => {
          if (header.key && header.value) {
            acc[header.key] = header.value;
          }
          return acc;
        }, {}),
      };
    }

    if (isEditingSenderID) {
      const { id } = senderIDData;
      const request = { bodyReq: payload, id };
      await mutateUpdate(request, {
        onSuccess: async () => {
          toast.success('Sender ID updated successfully');
          await queryClient.invalidateQueries({ queryKey: ['listSenderIDs'] });
          setIsAddOrUpdateSenderID(false);
        },
        onError: (error) => {
          toast.error(error?.response?.data?.error?.message);
        },
      });
    } else {
      mutate(payload, {
        onSuccess: async () => {
          toast.success('Sender ID requested successfully');
          await queryClient.invalidateQueries({ queryKey: ['listSenderIDs'] });
          setIsAddOrUpdateSenderID(false);
        },
        onError: (error) => {
          toast.error(error?.response?.data?.error?.message);
        },
      });
    }
  };

  // Function to handle sender ID input change
  const handleSenderIDChange = (e) => {
    if (e.target.value.length > 11) {
      setError('sender_id', {
        type: 'maxLength',
        message: 'Sender ID must be 11 characters or less',
      });
      setValue('sender_id', e.target.value);
      setOverallError(true);
    } else {
      setOverallError(false);
      clearError('sender_id');
      setValue('sender_id', e.target.value);
    }
  };

  return (
    <Bloc as="form" onSubmit={handleSubmit(onFormSubmit)}>
      <Box mt={2} />

      <Bloc
        fontSize="18px"
        fontWeight="500"
        borderBottom="1px solid #E5E5E5"
        pb="20px"
        color="black"
      >
        New sender ID request
      </Bloc>
      <Bloc
        display={['flex', 'grid']}
        flexDirection={['column', 'row']}
        pb="20px"
        pt="20px"
        style={{
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          columnGap: '20px',
        }}
      >
        <Box>
          <Inputfield
            name="Sender ID"
            hint="Sender ID should be 11 characters max."
            defaultValue={senderIdName}
            onChange={handleSenderIDChange}
            ref={register({
              required: 'Sender ID is required',
              maxLength: {
                value: 11, // Maximum length validation
                message: 'Sender ID must be 11 characters or less',
              },
              pattern: {
                value: SENDERID_REGEX,
                message: 'Enter a valid Sender ID',
              },
            })}
            error={
              errors?.sender_id?.message || errors?.sender_id?.types?.message
            }
          />
        </Box>
        <Bloc
          display="flex"
          flexDirection="column"
          style={{
            gap: '8px',
          }}
          width={1}
        >
          <Bloc fontSize="14px" fontWeight="500">
            Country
          </Bloc>
          <Countries
            hasSearch
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            hasError={countryError || false}
            setHasError={setCountryError}
          />
          {countryError && (
            <Bloc as="p" color="surfaces.4" fontSize={12} mt={3} mb={0}>
              {countryError}
            </Bloc>
          )}
        </Bloc>
        <Flex
          width={1}
          style={{
            gap: '14px',
          }}
        >
          <Flex
            style={{
              flex: '1 1 0%',
            }}
          >
            <Bloc width={1}>
              <Inputfield
                type="text"
                placeholder="Outbound SMS Webhook URL"
                name="Outbound SMS Webhook URL"
                ref={register({
                  pattern: urlValidation,
                })}
                error={errors?.outbound_sms_webhook_url?.message}
                defaultValue={senderIDData?.webhook?.url || ''}
              />
            </Bloc>
          </Flex>
          <Bloc
            display="flex"
            flexDirection="column"
            style={{
              gap: '9px',
            }}
          >
            <Bloc fontSize="14px" fontWeight="500">
              Method
            </Bloc>
            <ClickAwayListener
              onClickAway={() => setIsDropdownOpen(false)}
              data-test="hide-dropdown"
              style={{
                position: 'relative',
              }}
            >
              <Flex
                height={40}
                justifyContent="space-between"
                alignItems="center"
                px="6px"
                style={{
                  borderRadius: '4px',
                  gap: '10px',
                  border: '1px solid #d3d3d3',
                  cursor: 'pointer',
                }}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <Text fontSize="14px">{headerMethod || 'Select Method'}</Text>
                <Chevron
                  color={isDropdownOpen ? 'accents.8' : 'surfaces.3'}
                  size={16}
                />
              </Flex>
              {isDropdownOpen && (
                <Bloc
                  position="absolute"
                  top={40}
                  width="100%"
                  right={0}
                  marginTop={2}
                  padding="14px 10px"
                  zIndex={999}
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    boxShadow:
                      '0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16), 0px 16px 32px rgba(160, 143, 143, 0.08), 0px 32px 64px rgba(160, 143, 143, 0.16)',
                  }}
                >
                  {Object.entries(requestMethod).map(([value]) => (
                    <Bloc
                      key={value}
                      py={2}
                      onClick={() => {
                        setHeaderMethod(value);
                        setIsDropdownOpen(false);
                      }}
                    >
                      <Text
                        hoverAsLink
                        variant="small"
                        style={{ cursor: 'pointer' }}
                      >
                        {value}
                      </Text>
                    </Bloc>
                  ))}
                </Bloc>
              )}
            </ClickAwayListener>
          </Bloc>
        </Flex>
        <Bloc
          display="flex"
          flexDirection="column"
          style={{
            gap: '8px',
          }}
          width={1}
        >
          <Bloc fontSize="14px" fontWeight="500">
            Outbound SMS webhook headers
          </Bloc>

          {headers.map((header, index) => (
            <Flex
              style={{
                gap: '10px',
              }}
              key={index}
            >
              <Bloc width={1}>
                <Inputfield
                  type="text"
                  hasLabel={false}
                  name="key"
                  onChange={(event) => handleHeaderChange(index, event)}
                  placeholder="Key"
                  value={header.key}
                />
              </Bloc>
              <Bloc width={1}>
                <Inputfield
                  type="text"
                  hasLabel={false}
                  name="Value"
                  value={header.value}
                  onChange={(event) => handleHeaderChange(index, event)}
                  placeholder="Value"
                />
              </Bloc>
              {headers.length > 1 && (
                <Bloc
                  cursor="pointer"
                  fontSize="15px"
                  height="40px"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  fontWeight="bold"
                  color="#929292"
                  onClick={() => deleteHeaderField(index)}
                  data-testid="remove-real-contact"
                >
                  x
                </Bloc>
              )}
            </Flex>
          ))}

          <Text
            onClick={addHeaderField}
            fontSize="12px"
            color="#0EB6A2"
            style={{
              cursor: 'pointer',
            }}
          >
            + Add Header
          </Text>
        </Bloc>
      </Bloc>
      <Flex flexDirection={['column', 'row']}>
        <Box mt="20px">
          <Bloc fontSize={1} fontWeight="500" my={3} as="label">
            Terms and Conditions:
          </Bloc>
          <Flex
            style={{
              cursor: 'pointer',
            }}
            alignItems="center"
            mt="15px"
          >
            <Bloc
              as="span"
              data-test="check-box"
              data-testid="check-box"
              onClick={(e) => onCheckBox(e)}
            >
              {setCheckbox(true)}
            </Bloc>
            <Bloc
              style={{
                cursor: 'pointer',
              }}
              width="100%"
              fontSize={12}
              color="#757F84"
              ml={12}
            >
              <Text
                color="accents.1"
                as={Link}
                fontWeight="normal"
                fontSize={1}
                to="/senderid-policies"
                target="_blank"
              >
                I agree to abide by the sender ID usage policies and guidelines
              </Text>
            </Bloc>
          </Flex>
          {aggrementError && (
            <Bloc as="p" color="surfaces.4" fontSize={12} mt={3} mb={0}>
              {aggrementError}
            </Bloc>
          )}
        </Box>
      </Flex>

      <Flex
        mt="20px"
        style={{
          borderTop: '1px solid #E5E5E5',
          paddingTop: '20px',
          columnGap: '10px',
        }}
        flexWrap="wrap"
      >
        <Box>
          <Button
            disabled={!formState.dirty || isPending}
            profile="accentLight"
            size="contact"
            type="submit"
            loading={isPending}
            data-testid="update-btn"
          >
            Send Request
          </Button>
        </Box>
        <Box>
          <Button
            profile="secondary"
            size="contact"
            onClick={() => setIsAddOrUpdateSenderID(false)}
            data-test="cancel-btn"
          >
            Cancel
          </Button>
        </Box>
      </Flex>
    </Bloc>
  );
};

export default AddOrUpdateSenderID;
