/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Bloc } from 'blocjs';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { toast } from 'sonner';
import { useFetchPublishableKey } from '../../hooks';
import { AuthContext } from '../../contexts';
import CardPaymentForm from './cardPaymentForm';

const PaymentForm = ({
  handleHideAccountForm,
  isLoadingRemoveCard,
  removeSavedMethod,
}) => {
  const [stripeObject, setStripeObject] = useState(null);
  const auth = useContext(AuthContext);
  const { token } = auth;
  const { data: publishableKeyData } = useFetchPublishableKey(token);

  const fetchStripeObject = useRef(() => {});

  fetchStripeObject.current = async () => {
    try {
      const res = await loadStripe(publishableKeyData.publishable_key);
      setStripeObject(res);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (publishableKeyData && publishableKeyData.publishable_key) {
      fetchStripeObject.current();
    }
  }, [fetchStripeObject, publishableKeyData]);

  const options = {
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap',
        family: 'Space Grotesk',
      },
    ],
  };

  return (
    <Bloc>
      {stripeObject && (
        <Elements stripe={stripeObject} options={options}>
          <Bloc
            maxHeight={['400px', '100%']}
            overflowY={['auto', 'initial']}
            borderRadius="8px"
          >
            <CardPaymentForm
              handleHideAccountForm={handleHideAccountForm}
              removeSavedMethod={removeSavedMethod}
              isLoadingRemoveCard={isLoadingRemoveCard}
            />
          </Bloc>
        </Elements>
      )}
    </Bloc>
  );
};

export default PaymentForm;
