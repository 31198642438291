/* eslint-disable import/no-unresolved */
import { Bloc, Flex } from 'blocjs';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { toast } from 'sonner';
import Icon from '../icon';
import Text from '../text';
import Button from '../button';
import UserAPI from '../../services';
import { Inputfield } from '../inputs';

const RenderToaster = ({ action, handleAction, handleSwitchPage }) => {
  const queryClient = useQueryClient();
  const [groupNameError, setGroupNameError] = useState(null);
  const [groupExistMessage, setGroupExistMessage] = useState(null);

  const [groupNameText, setGroupNameText] = useState('');

  const userAPI = new UserAPI();

  const { data: orgId } = useQuery({
    queryKey: ['selfOrg'],
    queryFn: (payload) => userAPI.getSelfOrg(payload),
  });

  const { mutate: archiveContacts, isPending: archiveContactsIsPending } =
    useMutation({
      mutationFn: (payload) => userAPI.archiveContacts(payload),
    });

  const { mutate: unarchiveContacts, isPending: unarchiveContactIsPending } =
    useMutation({
      mutationFn: (payload) => userAPI.unarchiveContacts(payload),
    });

  const { mutate: deleteContactField } = useMutation({
    mutationFn: (payload) => userAPI.deleteContactField(payload),
  });

  const {
    mutate: deleteContactGroups,
    isPending: deleteContactGroupsIsPending,
  } = useMutation({
    mutationFn: (payload) => userAPI.deleteContactGroup(payload),
  });

  const { mutate: deleteContact, isPending: deleteContactIsPending } =
    useMutation({
      mutationFn: (payload) => userAPI.deleteContact(payload),
    });

  const { mutate: createGroup, isPending: createGroupIsPending } = useMutation({
    mutationFn: (payload) => userAPI.createContactGroup(payload),
  });

  const {
    mutate: deleteContactFromGroup,
    isPending: deleteContactFromGroupIsPending,
  } = useMutation({
    mutationFn: (payload) =>
      userAPI.deleteContactsInGroup(payload, action?.result.group_id),
  });

  const onDeleteContactFromGroup = () => {
    const payload = {
      contact_ids: action?.result.payload.map((contact) => contact.id),
    };

    deleteContactFromGroup(payload, {
      onError: () => {
        toast.error('Failed to remove contacts from group');
        queryClient.invalidateQueries({ queryKey: ['get_all_groups'] });
        handleAction('preview-group');
      },
      onSuccess: () => {
        toast.success('Contacts removed from group successfully');
        queryClient.invalidateQueries({ queryKey: ['get_all_groups'] });
        queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });
        queryClient.invalidateQueries({ queryKey: ['get_all_contacts'] });
        handleAction();
      },
    });
  };

  const onDeleteContact = () => {
    const payload = {
      ids: action?.result.map((contact) => contact.id),
    };

    deleteContact(payload, {
      onError: () => {
        toast.error('Failed to delete contacts');
        queryClient.invalidateQueries({
          queryKey: ['get_all_archived'],
        });
        queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

        handleAction();
      },
      onSuccess: () => {
        toast.success('Contacts deleted successfully');
        queryClient.invalidateQueries({ queryKey: ['get_all_archived'] });
        queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

        handleAction();
      },
    });
  };

  const handleInputGroupName = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const textInput = e.target.value;

    if (textInput && textInput.length >= 30) {
      setGroupExistMessage(null);
      setGroupNameError('Group name should not exceed 30 characters');
    } else {
      setGroupNameText(textInput);
      setGroupNameError(null);
    }
  };

  const onDeleteSelectedField = () => {
    const payload = {
      ids: action?.result.map((contact) => contact.id),
    };

    deleteContactField(payload, {
      onError: () => {
        toast.error('Failed to delete fields');
        handleAction();
      },
      onSuccess: () => {
        toast.success('Fields deleted successfully');
        queryClient.invalidateQueries({ queryKey: ['get_all_fields'] });
        queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });
        handleAction();
      },
    });
  };

  const onDeleteContactGroups = () => {
    deleteContactGroups(
      {
        ids: action?.result.map((group) => group.id),
      },
      {
        onError: () => {
          toast.error('Failed to delete groups');
          handleAction('edit-group', action.result);
        },
        onSuccess: () => {
          toast.success('Groups deleted successfully');
          queryClient.invalidateQueries({ queryKey: ['get_all_groups'] });
          queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });
          handleAction('edit-group', action.result);
          handleSwitchPage();
        },
      }
    );
  };

  const onArchivedContacts = () => {
    const payload = {
      contact_ids: action?.result.map((contact) => contact.id),
    };

    archiveContacts(payload, {
      onSuccess: () => {
        toast.success('Contacts archived successfully');
        queryClient.invalidateQueries({
          queryKey: ['get_all_contacts'],
        });

        queryClient.invalidateQueries({
          queryKey: ['get_all_archived'],
        });
        queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

        handleAction();
        handleSwitchPage();
      },
      onError: () => {
        toast.error('Failed to archive contacts');
        queryClient.invalidateQueries({
          queryKey: ['get_all_contacts'],
        });
        handleAction('active-tab', action.result);
      },
    });
  };

  const onUnarchiveContacts = () => {
    const payload = {
      contact_ids: action?.result.map((contact) => contact.id),
    };

    unarchiveContacts(payload, {
      onSuccess: () => {
        toast.success('Contacts unarchived successfully');
        queryClient.invalidateQueries({
          queryKey: ['get_all_contacts'],
        });
        queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

        queryClient.invalidateQueries({
          queryKey: ['get_all_archived'],
        });

        handleAction('archived-tab');
      },
      onError: () => {
        toast.error('Failed to unarchive contacts');
        handleAction('archived-tab', action.result);
      },
    });
  };

  const handleCreateGroupList = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const check = groupNameText.length > 3 && !groupNameError;
    if (groupNameText.length < 3) {
      setGroupNameError('Field Name should have at least 3 characters');
    }
    if (check) {
      const payload = {
        group_name: groupNameText,
        org_id: orgId[0].id,
        criteria: {},
        contact_ids: action?.result.map((contact) => contact.id),
      };

      createGroup(payload, {
        onSuccess: () => {
          toast.success('Group created successfully');
          handleSwitchPage(null);

          queryClient.invalidateQueries({
            queryKey: ['get_all_contacts'],
          });
          queryClient.invalidateQueries({
            queryKey: ['get_all_groups'],
          });
          queryClient.invalidateQueries({ queryKey: ['contactsStatistics'] });

          queryClient.invalidateQueries({
            queryKey: ['get_all_archived'],
          });
          handleAction('active-tab');
        },
        onError: () => {
          toast.error('Failed to create group');
        },
      });
    }
  };

  switch (action.toggleToast) {
    case 'move-to-group':
      return (
        <Flex
          px="22px"
          py="20px"
          flexDirection="column"
          style={{
            gap: '10px',
          }}
        >
          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to create a New Group
            </Text>
          </Flex>
          <Flex
            width="100%"
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
              gap: '6px',
            }}
            pb="10px"
            flexDirection="column"
          >
            <Text fontSize="14px">Group name</Text>
            <Inputfield
              size="contact"
              required
              name="text"
              value={groupNameText}
              placeholder="Group Name"
              onChange={(e) => handleInputGroupName(e)}
              hasHint={false}
              hasLabel={false}
            />
            {groupNameError ||
              (groupExistMessage && (
                <Text fontSize="10px" color="red">
                  {groupNameError ?? groupExistMessage}
                </Text>
              ))}
          </Flex>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              loading={createGroupIsPending}
              onClick={handleCreateGroupList}
            >
              Proceed
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('active-tab', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );
    case 'preview-group':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to archive a contact !
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            Archiving this contact removes it from your active list and places
            it in the archived section. You&apos;ll need to unarchive it to use
            it again.
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={() => {
                handleAction('preview-group', action.result);
              }}
            >
              Remove To group
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('preview-group', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );

    case 'move-to-archived':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to archive a contact !
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            Archiving this contact removes it from your active list and places
            it in the archived section. You&apos;ll need to unarchive it to use
            it again.
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={onArchivedContacts}
              loading={archiveContactsIsPending}
            >
              Continue to archived
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                color: 'black',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('active-tab', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );

    case 'delete-field':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to delete fields
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            This action will permanently remove the selected field from all your
            contacts, this action cannot be undone.
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={onDeleteSelectedField}
            >
              Proceed
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('contact-field', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );
    case 'move-to-unarchive':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to unarchive a contact !
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            Un-archiving this contact removes it from your active list and
            places it in the archived section. You&apos;ll need to unarchive it
            to use it again.
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={onUnarchiveContacts}
              loading={unarchiveContactIsPending}
            >
              Proceed
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('archived-tab', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );

    case 'delete-contacts-from-group':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to Delete these contacts in this group!
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            This action will remove all the selected contacts in these groups.
            Are you sure you want to proceed?
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={onDeleteContactFromGroup}
              loading={deleteContactFromGroupIsPending}
            >
              Proceed
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('edit-group', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );
    case 'delete-group':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to Delete this groups !
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            This action will remove all the contacts in these groups. Are you
            sure you want to proceed?
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={onDeleteContactGroups}
              loading={deleteContactGroupsIsPending}
            >
              Proceed
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('edit-group', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );
    case 'delete-contact':
      return (
        <Flex
          p="30px"
          flexDirection="column"
          style={{
            gap: '15px',
          }}
        >
          <Flex
            alignItems="center"
            style={{
              gap: '10px',
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              width="40px"
              style={{
                borderRadius: '50%',
                backgroundColor: '#FFB1321A',
              }}
              height="40px"
            >
              <Icon
                name="warning"
                color="#FFB132"
                dimension="14x14"
                size={14}
              />
            </Flex>

            <Text fontSize="14px" fontWeight={700}>
              You are about to delete contacts !
            </Text>
          </Flex>
          <Bloc fontSize="14px">
            This action will permanently delete all these contacts. Are you sure
            you want to proceed?
          </Bloc>

          <Flex
            style={{
              columnGap: '10px',
            }}
          >
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="accentLight"
              size="contact"
              onClick={onDeleteContact}
              loading={deleteContactIsPending}
            >
              Proceed
            </Button>
            <Button
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
              profile="secondary"
              size="contact"
              onClick={() => {
                handleAction('archived-tab', action.result);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      );
    default:
      return null;
  }
};

export default RenderToaster;
