import axios from 'axios';

const ipAPI = process.env.REACT_APP_PUBLIC_IP_API;

const getUserLocation = async () => {
    const response = (await axios.get(ipAPI)).data;
    return response;
}

export default getUserLocation;
