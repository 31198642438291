import { useQuery } from '@tanstack/react-query';
import UserAPI from '../../services';

const useGlobalContactSearch = ({ searchValue, fetchEnable }) => {
  const userAPI = new UserAPI();
  const {
    data: contactsData,
    isLoading: contactIsLoading,
    isFetching: contactIsFetching,
  } = useQuery({
    queryKey: ['get_all_contacts_in_message'],
    queryFn: () =>
      userAPI.getContacts({
        name: searchValue,
      }),
    enabled: fetchEnable,
    select: (data) => data?.slice(0, 25),
    gcTime: 0,
  });
  const { data: archivedContactsData } = useQuery({
    queryKey: ['get_all_archived'],
    queryFn: () => userAPI.getArchivedContacts(),
  });

  const {
    data: groupsData,
    isLoading: groupIsLoading,
    isFetching: groupIsFetching,
  } = useQuery({
    queryKey: ['get_all_groups_in_message'],
    queryFn: () =>
      userAPI.getContactGroups({
        name: searchValue,
      }),
    enabled: fetchEnable,
    gcTime: 0,
  });

  const contactsDataIsLoading = contactIsLoading || contactIsFetching;
  const groupsDataIsLoading = groupIsLoading || groupIsFetching;
  const isLoading = contactsDataIsLoading || groupsDataIsLoading;

  const isAnyContactPresent =
    contactsData?.length > 0 || archivedContactsData?.length > 0;

  return {
    contactsData,
    isLoading,
    isAnyContactPresent,
    groupsData,
  };
};

export default useGlobalContactSearch;
