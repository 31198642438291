import React from 'react';
import { Bloc } from 'blocjs';
import { format } from 'date-fns';
import { Pagination } from '../../../../components';
import BillingOrgTable from './billlingOrgTable';
import FilterForm from './filterform';
import { formatBalance } from '../../../../utils';

const MainUserCell = ({ orgName }) => <Bloc>{orgName}</Bloc>;

const BalanceStatus = ({ hasSufficientBalance }) => {
  let statusColor = '#088C7D';
  let statusBg = '#EBF9F5';
  let statusText = 'ENOUGH FUNDS';
  if (!hasSufficientBalance) {
    statusColor = '#FF3049';
    statusBg = '#FFD6DB';
    statusText = 'LOW BALANCE';
  }
  return (
    <Bloc display="flex" p={0} alignItems="center" fontSize={14} pl={0}>
      <Bloc pl={0} style={{ whiteSpace: 'initial' }} width={1}>
        <Bloc
          as="span"
          color={statusColor}
          bg={statusBg}
          padding="4px 10px"
          borderRadius={27}
          fontSize={10}
          fontWeight="bold"
          display="block"
          boxSizing="border-box"
          width="fit-content"
          style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}
        >
          {statusText}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

const ListOrganisations = ({
  data,
  setRowBackgroundColor,
  goToOrgDetails,
  pageNumber,
  itemsPerPage,
  isFetching,
  isLoading,
  changePage,
  setIsFetchEnabled,
  searchByName,
  handleFilterFormDates,
  filterSenderIDs,
  startDate,
  endDate,
  searchedName,
  showAddFunds,
  onAddFunds,
  onCheckBox,
  setCheckbox,
  selectedOrgs,
  handleBalanceStatus,
  balanceStatuses,
  balanceStatus,
  filterOrgs,
  hasInputFilled,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        floatRight: false,
        Cell: ({ row: { original } }) => (
          <MainUserCell orgName={original.name} />
        ),
      },
      {
        Header: 'Balance',
        floatRight: false,
        Cell: ({ row: { original } }) =>
          formatBalance(original?.wallet_plus_bonus || 0),
      },
      {
        Header: 'Status',
        floatRight: false,
        Cell: ({ row: { original } }) => (
          <BalanceStatus
            hasSufficientBalance={original.has_sufficient_balance}
          />
        ),
      },
      {
        Header: 'Created Date',
        floatRight: false,
        accessor: (row) => format(new Date(row.created_at), 'MMM, dd, yyyy'),
      },
    ],
    []
  );

  const orgsData = data || [];

  return (
    <>
      <FilterForm
        startDate={startDate}
        endDate={endDate}
        handleFilterFormDates={handleFilterFormDates}
        filterSenderIDs={filterSenderIDs}
        setIsFetchEnabled={setIsFetchEnabled}
        searchedName={searchedName}
        searchByName={searchByName}
        balanceStatuses={balanceStatuses}
        handleBalanceStatus={handleBalanceStatus}
        balanceStatus={balanceStatus}
        isLoading={isLoading}
        isFetching={isFetching}
        filterOrgs={filterOrgs}
        hasInputFilled={hasInputFilled}
      />
      <BillingOrgTable
        columns={columns}
        data={orgsData}
        setRowBackgroundColor={setRowBackgroundColor}
        onRowClick={goToOrgDetails}
        onCheckBox={onCheckBox}
        setCheckbox={setCheckbox}
        showAddFunds={showAddFunds}
        onAddFunds={onAddFunds}
        selectedOrgs={selectedOrgs}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        pageNumber={pageNumber}
        pages={data?.pages}
        pageCount={data?.pages?.pages}
        changePage={changePage}
        isFetching={isFetching}
        isLoading={isLoading}
      />
    </>
  );
};

export default ListOrganisations;
