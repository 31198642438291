import React, { useState } from 'react';
import { Flex, Box, Bloc } from 'blocjs';
import ListMembers from './listMembers';
import { useFetchOrgMembers } from '../../../../hooks';
import { Text, Button, Icon, CheckedBox } from '../../../../components';
import AccountContainer from '../../../userAccount/container';
import InviteMembersForm from './inviteMembers';

const Members = ({
  inUseOrgData,
  isMainUser,
  membersText,
  selectedMembers,
  setSelectedMembers,
  setIsRemoving,
  isRemoving,
  isRemoveLoading,
}) => {
  const [addMember, setAddMember] = useState(false);

  const { data: membersData, isLoading: isMembersLoading } =
    useFetchOrgMembers();

  const onCheckBox = (message, e) => {
    e.stopPropagation();
    const items = [...selectedMembers];
    const index = items.findIndex((item) => item.id === message.id);
    if (index > -1) {
      items.splice(index, 1);
      setSelectedMembers(items);
    } else {
      setSelectedMembers([...items, message]);
    }
  };

  const setCheckbox = (rowId) => {
    if (selectedMembers.some((item) => item.id === rowId)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16}>
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  const setRowBackgroundColor = (rowId) => {
    if (selectedMembers.some((item) => item.id === rowId)) {
      return '#BDFBF3';
    }
    return 'transparent';
  };

  const onRemoveMember = (e) => {
    e.stopPropagation();
    setIsRemoving(true);
  };

  return (
    <AccountContainer pt={0}>
      <Flex
        flexDirection="column"
        style={{
          gap: '20px',
        }}
        p="20px"
      >
        <Text color="black" fontSize="18px" fontWeight="600">
          Organization members
        </Text>

        {addMember && (
          <InviteMembersForm setAddMember={setAddMember} orgs={inUseOrgData} />
        )}

        <Bloc
          borderRadius="3px"
          bg="surfaces.0"
          border="1px solid #E2E2E2"
          style={{
            position: 'relative',
            opacity: addMember ? 0.2 : 1,
          }}
        >
          {addMember && (
            <Bloc
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
              }}
            />
          )}
          {isMainUser && (
            <Flex
              justifyContent="end"
              flexWrap="wrap"
              alignItems="center"
              padding="15px 20px"
              style={{
                gap: '10px',
                borderBottom: '1px solid #E2E2E2',
              }}
            >
              {!isRemoving && selectedMembers.length > 0 && (
                <Button
                  profile="accentLight"
                  size="contact"
                  type="button"
                  loading={isRemoveLoading}
                  data-testid="refresh-token"
                  onClick={(e) => onRemoveMember(e)}
                >
                  Remove {membersText}
                </Button>
              )}
              {selectedMembers.length < 1 && (
                <Button
                  profile="accentLight"
                  size="contact"
                  onClick={() => setAddMember(true)}
                  data-testid="add-members"
                >
                  Add members
                </Button>
              )}
            </Flex>
          )}

          <ListMembers
            isLoading={isMembersLoading}
            data={membersData}
            onCheckBox={onCheckBox}
            setCheckbox={setCheckbox}
            setRowBackgroundColor={setRowBackgroundColor}
            isMainUser={isMainUser}
          />
        </Bloc>
      </Flex>
    </AccountContainer>
  );
};

export default Members;
