/* eslint-disable import/no-unresolved */
import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { format } from 'date-fns';
import posthog from 'posthog-js';

import styled from 'styled-components';
import { variant } from 'styled-system';
import { toast } from 'sonner';
import { WebWorker, ExportCSVWorker } from '../../worker';
import { CurrentUserContext } from '../../contexts';
import {
  Layout,
  Text,
  Button,
  Table,
  Icon,
  CheckedBox,
  Progressbar,
} from '../../components';
import FilterForm from './filterform';
import ReadMessage from './readmessage';
import Export from './export';
import {
  textTruncate,
  formatStatus,
  exportCSV,
  exportJSON,
  formatDate,
  getTimezone,
} from '../../utils';
import { useFetchOrg, useExportMessages, useFilterMessages } from '../../hooks';
import SendMessage from '../../components/sendMessages';

const webWorkerExportCSV = new WebWorker(ExportCSVWorker);

const Messages = () => {
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [statusInput, setStatusInput] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredStartDate, setFilteredStartDate] = useState('');
  const [filteredEndDate, setFilteredEndDate] = useState('');
  const [hasDateChanged, setHasDateChanged] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [selectedSMS, setSelectedSMS] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [shownMessages, setShownMessages] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [isFetchEnabled, setIsFetchEnabled] = useState(true);
  const [isExportEnabled, setIsExportEnabled] = useState(false);
  const [exportFormat, setExportFormat] = useState('csv');
  const [progressPercentage, setProgressPercentage] = useState(0);

  const [direction, setDirection] = useState('next');
  const [cursor, setCursor] = useState('');

  const { currentUser } = useContext(CurrentUserContext);
  const { data: orgData } = useFetchOrg({
    enabled: true,
  });

  const inUseOrgData = orgData?.filter((org) => org.in_use)[0];

  const messagesPerPage = 25;
  let totalMessages = messagesPerPage;
  const timezone = getTimezone();

  useEffect(() => {
    let timer;

    if (phoneNumber) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setIsFetchEnabled(true);
      }, 1000);

      setIsFetchEnabled(false);
    }

    return () => clearTimeout(timer);
  }, [phoneNumber]);

  const {
    filteredMessages,
    isFilterLoading,
    isFilterFetching,
    isSuccess,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useFilterMessages(
    cursor,
    messagesPerPage,
    statusInput,
    phoneNumber,
    direction,
    filteredStartDate,
    filteredEndDate,
    isFetchEnabled,
    'filterMessages',
    timezone
  );

  if (filteredMessages) totalMessages = filteredMessages?.pages?.total;
  const cachedValueForExport = `exportMessages${totalMessages}-${filteredStartDate}-${filteredEndDate}`;
  const { dataToExport, isFetchingExport, exportStatus, exportError } =
    useExportMessages(
      statusInput,
      phoneNumber,
      filteredStartDate,
      filteredEndDate,
      exportFormat,
      isExportEnabled,
      cachedValueForExport,
      timezone
    );

  let messages = useMemo(() => [], []);
  // let messagesToDisplay;
  if (filteredMessages && filteredMessages.pages) {
    messages = filteredMessages?.pages[0]?.sms;
    // messagesToDisplay =
    //   filteredMessages.pages[filteredMessages?.pages.length - 1].sms;
  }

  useEffect(() => {
    if (
      filteredMessages &&
      filteredMessages.pages &&
      filteredMessages.pages[0]?.sms.length > 0
    ) {
      const defaultDate = filteredMessages?.pages[0]?.sms[0]?.created_at;
      const defaultCreatedDate = new Date(defaultDate);
      if (!hasDateChanged) {
        setStartDate(defaultCreatedDate);
        setEndDate(defaultCreatedDate);
        const formatedDate = formatDate(defaultCreatedDate);
        setFilteredStartDate(formatedDate);
        setFilteredEndDate(formatedDate);
      }
    }
  }, [filteredMessages, hasDateChanged]);

  const handleFilterFormDates = (timeStatus, date) => {
    const formatedDate = formatDate(date);

    if (timeStatus === 'start') {
      setStartDate(date);
      setFilteredStartDate(formatedDate);
    } else if (timeStatus === 'end') {
      setEndDate(date);
      setFilteredEndDate(formatedDate);
    }
    setIsFetchEnabled(false);
    setHasDateChanged(true);
  };

  const makeFileName = useRef(() => {});

  makeFileName.current = () => {
    const title = inUseOrgData?.name || currentUser?.username;
    const timestamp = Date.now();
    const fileName = `pindo-${title}-${timestamp}`;
    return { title, fileName };
  };

  useEffect(() => {
    webWorkerExportCSV.onmessage = (message) => {
      const { data } = message;
      if (data) {
        exportCSV(data);
      }
    };
  }, []);

  const exportSelecteMessages = async (option, data) => {
    const { title, fileName } = makeFileName.current();
    if (option === 'csv')
      webWorkerExportCSV.postMessage({
        data,
        fileName,
        title,
        page: 'messages',
      });
    if (option === 'json') exportJSON(data, fileName);
    setIsExporting(false);
    posthog.capture('Exported SMS', { property: option });
  };

  const resetExportFileStates = () => {
    setIsExportEnabled(false);
    setProgressPercentage(0);
  };

  const handleExportFile = useRef(() => {});
  handleExportFile.current = async () => {
    const { data, error } = dataToExport;
    if (typeof data !== 'undefined') {
      if (error) {
        toast.error(error.message);
        resetExportFileStates();
      } else {
        const { progress, download_url: downloadUrl } = data;
        setProgressPercentage(progress);
        if (progress === 100 && downloadUrl) {
          setProgressPercentage(100);
          // download the file
          setTimeout(() => {
            window.location.replace(downloadUrl);
            resetExportFileStates();
          }, 2000);
        }
      }
    }
  };

  const checkExportError = useRef(() => {});
  checkExportError.current = async () => {
    const errorMessage = exportError?.response?.data?.error?.message;
    toast.error(errorMessage || 'Something went wrong, Please try again');
    resetExportFileStates();
  };

  useEffect(() => {
    if (dataToExport && isExportEnabled && !isFetchingExport) {
      handleExportFile.current();
    }
    if (exportStatus === 'error') {
      checkExportError.current();
    }
  }, [dataToExport, exportStatus, isExportEnabled, isFetchingExport]);

  const handleExportSMS = async (option) => {
    setIsDropdownOpen(false);
    if (selectedMessages.length > 0) {
      setIsExporting(true);
      await exportSelecteMessages(option, selectedMessages);
    } else {
      setIsExportEnabled(true);
      setExportFormat(option);
    }
  };

  const filterMessages = () => {
    setCursor(null);
    setIsFetchEnabled(true);
  };

  const onCheckBox = (message, e) => {
    e.stopPropagation();
    const items = [...selectedMessages];
    const index = items.findIndex((item) => item.id === message.id);
    if (index > -1) {
      items.splice(index, 1);
      setSelectedMessages(items);
    } else {
      setSelectedMessages([...items, message]);
    }
  };

  const handleText = useCallback(
    (messageId, e) => {
      e.stopPropagation();
      const items = [...shownMessages];
      const index = items.indexOf(messageId);
      if (index > -1) {
        items.splice(index, 1);
        setShownMessages(items);
      } else {
        setShownMessages([...items, messageId]);
      }
    },
    [shownMessages]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Sender',
        accessor: 'sender',
      },
      {
        Header: 'To',
        accessor: 'to',
      },
      {
        Header: 'Message',
        Cell: ({ row: { original } }) => {
          const isFullMessage = shownMessages.includes(original.id);
          const fullMessage = original.text;
          return (
            <Flex data-testid="bloc-message" alignItems="center">
              <Bloc
                as="span"
                style={{ whiteSpace: isFullMessage ? 'initial' : 'no-wrap' }}
              >
                {isFullMessage ? fullMessage : textTruncate(fullMessage, 40)}
              </Bloc>
              {fullMessage.length > 40 && (
                <Bloc
                  as="button"
                  className="dots-btn"
                  onClick={(e) => handleText(original.id, e)}
                  data-testid="handlelongtext"
                  width={48}
                  height={16}
                  background="transparent"
                  border="none"
                  marginLeft="10px"
                  lineHeight="0px"
                  style={{ cursor: 'pointer' }}
                >
                  <Box as="span" className="dot" />
                  <Box as="span" className="dot" />
                  <Box as="span" className="dot" />
                </Bloc>
              )}
            </Flex>
          );
        },
      },
      {
        Header: 'Status',
        accessor: (row) => formatStatus(row.status),
      },
      {
        Header: 'Sent Date',
        accessor: (row) => format(new Date(row.created_at), 'MMM, dd, yyyy'),
      },
    ],
    [handleText, shownMessages]
  );

  const dataMessages = useMemo(() => messages, [messages]);

  const onRowClick = (row, e) => {
    e.stopPropagation();
    setSelectedSMS(row.original);
    setShowFullMessage(true);
  };

  const hasInputFilled = !!(statusInput || phoneNumber || startDate || endDate);

  const setRowBackgroundColor = (rowId) => {
    if (selectedMessages.some((item) => item.id === rowId)) {
      return '#BDFBF3';
    }
    if (shownMessages.includes(rowId)) {
      return '#E4FFFC';
    }
    return 'transparent';
  };

  const setCheckbox = (rowId) => {
    if (selectedMessages.some((item) => item.id === rowId)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16}>
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  return (
    <Layout>
      <Flex justifyContent="space-between" width="100%" flexWrap="wrap">
        {!showMessageForm && (
          <Box style={{ float: 'left' }} minWidth="16%" mb={6}>
            <Text as="h1" style={{ lineHeight: '32px' }} fontSize={[24, 28]}>
              Message
            </Text>
          </Box>
        )}

        {!showMessageForm && (
          <Flex justifyContent="space-between" mb={[0, 6]}>
            <Box display="none">
              <Button profile="primary" size="small" display="none">
                Archive
              </Button>
            </Box>
            <Box style={{ float: 'right' }} width="100%">
              {showFullMessage ? (
                <Button
                  profile="accentLight"
                  style={{
                    textTransform: 'capitalize',
                    fontWeight: 'normal',
                    fontSize: '12px',
                  }}
                  size="dashboard"
                  onClick={() => {
                    setShowFullMessage(false);
                    setSelectedSMS({});
                    posthog.capture('back to all messages', { property: true });
                  }}
                  data-testid="go-back-to-messages"
                >
                  Go back to all messages
                </Button>
              ) : (
                <Flex justifyContent="flex-end" flexWrap="wrap">
                  <Box ml={[0, 4]} mb={[4, 0, 0]}>
                    <Button
                      profile="accentLight"
                      size="dashboard"
                      style={{
                        textTransform: 'capitalize',
                        fontWeight: 'normal',
                        fontSize: '12px',
                      }}
                      onClick={() => {
                        setShowMessageForm(true);
                        posthog.capture('opened the message form', {
                          property: true,
                        });
                      }}
                    >
                      Send Message
                    </Button>
                  </Box>
                  {isSuccess && (
                    <Export
                      handleExportSMS={handleExportSMS}
                      isDropdownOpen={isDropdownOpen}
                      setIsDropdownOpen={setIsDropdownOpen}
                      selectedMessages={selectedMessages}
                      isExporting={isExporting}
                      isExportEnabled={isExportEnabled}
                    />
                  )}
                </Flex>
              )}
            </Box>
          </Flex>
        )}
      </Flex>
      <Box mt={0} />
      {showMessageForm && (
        <SendMessage setShowMessageForm={setShowMessageForm} />
      )}
      <Box mt={showMessageForm ? 64 : 0} />

      {showFullMessage ? (
        <ReadMessage selectedSMS={selectedSMS} />
      ) : (
        <>
          {progressPercentage > 0 && isExportEnabled && (
            <Bloc ml={[0, '16%']}>
              <Bloc
                height={20}
                overflow="hidden"
                borderRadius={2}
                margin="0 0 32px"
                bg="accents.6"
              >
                <Progressbar progressPercentage={progressPercentage} />
              </Bloc>
            </Bloc>
          )}
          {!showMessageForm && (
            <FilterForm
              setPhoneNumber={setPhoneNumber}
              setStatusInput={setStatusInput}
              startDate={startDate}
              endDate={endDate}
              handleFilterFormDates={handleFilterFormDates}
              filterMessages={filterMessages}
              isFetching={isFilterFetching}
              isLoading={isFilterLoading}
              hasInputFilled={hasInputFilled}
              setIsFetchEnabled={setIsFetchEnabled}
              setHasDateChanged={setHasDateChanged}
              phoneNumber={phoneNumber}
            />
          )}
          {!showMessageForm && (
            <Table
              columns={columns}
              data={dataMessages}
              onCheckBox={onCheckBox}
              setRowBackgroundColor={setRowBackgroundColor}
              setCheckbox={setCheckbox}
              onRowClick={onRowClick}
            />
          )}
        </>
      )}
      {!showFullMessage && !showMessageForm && (
        <Flex
          justifyContent="center"
          width={1}
          style={{
            gap: '20px',
          }}
        >
          <CustomButtonPagination
            pagination={!hasPreviousPage || isFilterFetching ? null : 'primary'}
            fontSize="12px"
            fontWeight="bold"
            borderBottom="1px solid currentColor"
            color={
              !hasPreviousPage || isFilterFetching ? '#757f8469' : '#16C1AD'
            }
            onClick={() => {
              setDirection('next');
              setCursor(null);
              if (hasPreviousPage && !isFilterFetching) {
                setTimeout(() => {
                  fetchPreviousPage();
                }, 200);
              }
            }}
          >
            First
          </CustomButtonPagination>

          <CustomButtonPagination
            pagination={!hasPreviousPage || isFilterFetching ? null : 'primary'}
            fontSize="12px"
            fontWeight="bold"
            borderBottom="1px solid currentColor"
            color={
              !hasPreviousPage || isFilterFetching ? '#757f8469' : '#16C1AD'
            }
            onClick={() => {
              setDirection('prev');
              if (hasPreviousPage && !isFilterFetching) {
                setTimeout(() => {
                  fetchPreviousPage();
                }, 200);
              }
            }}
          >
            Previous
          </CustomButtonPagination>

          <CustomButtonPagination
            pagination={!hasNextPage || isFilterFetching ? null : 'primary'}
            borderBottom="1px solid currentColor"
            color={!hasNextPage || isFilterFetching ? '#757f8469' : '#16C1AD'}
            fontSize="12px"
            fontWeight="bold"
            onClick={async () => {
              if (hasNextPage && !isFilterFetching) {
                setCursor('');
                setTimeout(() => {
                  fetchNextPage();
                }, 200);
              }
            }}
          >
            Next
          </CustomButtonPagination>
        </Flex>
      )}

      <Box mt={140} />
    </Layout>
  );
};

export default Messages;

const CustomButtonPagination = styled(Bloc)(
  {
    cursor: 'pointer',
  },
  variant({
    prop: 'pagination',
    variants: {
      primary: {
        '&:hover': {
          color: '#16C1AD',
        },
      },
    },
  })
);
