import React from 'react';
import styled, { css } from 'styled-components';

const BaseSwitch = ({ checked, className, ...rest }) => (
  <label className={className} htmlFor="slider">
    <input type="checkbox" checked={checked} {...rest} />
    <span className="slider" />
  </label>
);

const Switch = styled(BaseSwitch)`
    .slider {
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        display: inline-flex;
        align-items: center;
    }

    .slider:before {
        content: '';
        position: absolute;
        height: 32px;
        width: 32px;
        left: 8px;
        transition: 0.3s ease;
        border-radius: 50%;
        background: #16C1AD;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked + .slider:before {
        transform: translateX(48px);
    }

    ${({ theme }) => css`
        box-sizing: border-box;
        width: 96px;
        height: 48px;
        display: inline-block;
        position: relative;
        border-radius: 34px;
        padding: 8px 7px;
        background-color: ${theme.colors.accents[5]}
    `}
`;

export default Switch;
