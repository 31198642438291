import { Bloc, Flex } from 'blocjs';
import { format } from 'date-fns';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { convertUTCToLocalTime, textTruncate } from '../../../../../utils';
import { Text, ToggleSwitch } from '../../../../../components';

const DealTable = ({ deals, orgName = false, switchInUseDeal, isInUse }) => {
  const location = useLocation();
  const history = useHistory();
  const [showSwitchDeal, setShowSwitchDeal] = React.useState(false);
  const dealId = new URLSearchParams(location.search).get('deal_id');

  const [selectedDeal, setSelectedDeal] = React.useState({});

  const gotToEditDealDetailsHandler = (e, deal) => {
    e.stopPropagation();
    const searchParams = new URLSearchParams(location.search);
    if (orgName) {
      searchParams.set('orgId', deal.org_id);
      history.push({
        search: searchParams.toString(),
        pathname: '/admin/manage/organizations',
      });
    } else {
      setShowSwitchDeal(true);
      setSelectedDeal(deal);
      searchParams.set('deal_id', deal.id);
      history.push({
        search: searchParams.toString(),
      });
    }
  };

  function checkExpiredField(data) {
    return data?.some((deal) => deal?.expired === true);
  }

  const setRowBackgroundColor = (rowId) => {
    if (selectedDeal.id === rowId) {
      return '#BDFBF3';
    }
    return 'transparent';
  };

  return (
    <Bloc overflow="auto">
      <Bloc
        mb={0}
        as="table"
        width="100%"
        style={{ borderCollapse: 'collapse' }}
      >
        <Bloc as="thead">
          <Bloc
            as="tr"
            fontWeight="bold"
            textAlign="left"
            borderBottom="1px solid #DFDBDB"
          >
            {orgName && (
              <Bloc as="td" py={3} width={['auto', 'auto', 'auto']}>
                Org name
              </Bloc>
            )}

            <Bloc as="td" py={3} width={['auto', 'auto', 'auto']}>
              Bonus
            </Bloc>

            <Bloc as="td" py={12} width={['auto', 'auto', 'auto']}>
              Discount
            </Bloc>

            <Bloc as="td" py={12} width={['auto', 'auto', 'auto']}>
              Start Date
            </Bloc>
            <Bloc as="td" py={12} width={['auto', 'auto', 'auto']}>
              End Date
            </Bloc>
            {
              // If the deal is expired, show the expired column
              checkExpiredField(deals) && (
                <Bloc as="td" py={12} width={['auto', 'auto', 'auto']} />
              )
            }

            <Bloc
              textAlign="right"
              as="td"
              py={12}
              width={['auto', 'auto', 'auto']}
            >
              In use
            </Bloc>
          </Bloc>
        </Bloc>

        <Bloc as="tbody">
          {deals?.map((deal) => {
            let statusColor = 'accentDark';
            let statusBg = '#EBF9F5';
            if (!deal.in_use) {
              statusColor = '#FF3049';
              statusBg = '#FFD6DB';
            }

            return (
              <Bloc
                as="tr"
                key={deal.id}
                textAlign="left"
                bg={setRowBackgroundColor(deal.id)}
                borderBottom="1px solid #DFDBDB"
                onClick={(e) => gotToEditDealDetailsHandler(e, deal)}
                style={{ cursor: 'pointer' }}
              >
                {orgName && (
                  <Bloc as="td" py={3} width={['auto', 'auto', 'auto']}>
                    {textTruncate(deal?.org_name) || ''}
                  </Bloc>
                )}

                <Bloc as="td" py={3} width={['auto', 'auto', 'auto']}>
                  {Number(deal?.bonus?.toFixed(3)) || '0.0'}
                </Bloc>

                <Bloc as="td" py={3} width={['auto', 'auto', 'auto']}>
                  {Number(deal?.discount?.toFixed(3)) || '0.0'}
                </Bloc>

                <Bloc as="td" py={3} width={['auto', 'auto', 'auto']}>
                  {format(convertUTCToLocalTime(deal.start), 'MMM dd, yyyy')}
                </Bloc>
                <Bloc as="td" py={3} width={['auto', 'auto', 'auto']}>
                  {format(convertUTCToLocalTime(deal.end), 'MMM dd, yyyy')}
                </Bloc>
                {deal?.expired ? (
                  <Bloc as="td" py={3} width={['auto', 'auto', 'auto']}>
                    <Flex alignItems="center">
                      <Text
                        bg="#FFEEBA"
                        color="#757F84"
                        style={{
                          padding: '1px 10px',
                          borderRadius: '999px',
                          opacity: 0.7,
                        }}
                      >
                        expired
                      </Text>
                    </Flex>
                  </Bloc>
                ) : (
                  <>
                    {checkExpiredField(deals) && !deal.expired ? (
                      <Bloc as="td" py={3} width={['auto', 'auto', 'auto']} />
                    ) : null}
                  </>
                )}

                <Bloc
                  textAlign="right"
                  as="td"
                  display="flex"
                  justifyContent="end"
                  py={3}
                  width={['auto', 'auto', 'auto']}
                >
                  <Flex alignItems="center">
                    {showSwitchDeal && !orgName && dealId === deal.id ? (
                      <ToggleSwitch
                        small
                        id="directConnection"
                        checked={isInUse !== null ? isInUse : deal.in_use}
                        onChange={switchInUseDeal}
                      />
                    ) : (
                      <Bloc
                        bg={statusBg}
                        color={statusColor}
                        padding="4px 10px"
                        as="span"
                        fontSize={10}
                        borderRadius={27}
                      >
                        {deal.in_use ? 'Active' : 'Inactive'}
                      </Bloc>
                    )}
                  </Flex>
                </Bloc>
              </Bloc>
            );
          })}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

export default DealTable;
