import React from 'react';
import ActiveContactTable from './activeContactTable';
import ArchivedContactTable from './archivedContactTable';
import GroupsContactTable from './groupsContactTable';
import ContactFieldsTable from './contactFieldsTable';
import EmptyElement from './emptyElement';
import Spinner from '../spinner';
import useGetContactFields from '../../hooks/useGetContactFields';

const ContactDetailsForTab = ({
  activeTab,
  archivedContactsData,
  handleSwitchPage,
  handleActions,
  archivedContactsIsFetching,
  contactsData,
  groupsData,
  contactsDataIsFetching,
  isGroupeDataFetching,
}) => {
  const { fieldsData, isLoading: fieldsDataIsLoading } = useGetContactFields({
    isMessageForm: false,
  });

  switch (activeTab) {
    case 'active':
      return (
        <>
          {!contactsDataIsFetching ? (
            <>
              {contactsData && contactsData?.length > 0 ? (
                <ActiveContactTable
                  contactTableData={contactsData}
                  handleActions={handleActions}
                  handleSwitchPage={handleSwitchPage}
                />
              ) : (
                <EmptyElement
                  handleSwitchPage={handleSwitchPage}
                  title="Active Contact"
                />
              )}
            </>
          ) : (
            <Spinner size="small" />
          )}
        </>
      );
    case 'archived':
      return (
        <>
          {!archivedContactsIsFetching ? (
            <>
              {archivedContactsData && archivedContactsData.length > 0 ? (
                <ArchivedContactTable
                  archivedContactsIsFetching={archivedContactsIsFetching}
                  handleActions={handleActions}
                  archivedTableData={archivedContactsData}
                />
              ) : (
                <EmptyElement
                  handleSwitchPage={handleSwitchPage}
                  title="Archive"
                />
              )}
            </>
          ) : (
            <Spinner size="small" />
          )}
        </>
      );
    case 'groups':
      return (
        <>
          {!isGroupeDataFetching ? (
            <>
              {groupsData && groupsData?.length > 0 ? (
                <GroupsContactTable
                  activeTab={activeTab}
                  handleActions={handleActions}
                  groupTableData={groupsData}
                  handleSwitchPage={handleSwitchPage}
                />
              ) : (
                <EmptyElement
                  handleSwitchPage={handleSwitchPage}
                  title="Groups"
                />
              )}
            </>
          ) : (
            <Spinner size="small" />
          )}
        </>
      );

    case 'contactField':
      return (
        <>
          {!fieldsDataIsLoading ? (
            <>
              {fieldsData && fieldsData?.length > 0 && (
                <ContactFieldsTable
                  handleActions={handleActions}
                  handleSwitchPage={handleSwitchPage}
                  contactTableData={fieldsData}
                />
              )}
            </>
          ) : (
            <Spinner size="small" />
          )}
        </>
      );
    default:
      return null;
  }
};

export default ContactDetailsForTab;
