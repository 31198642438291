/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Bloc, Box } from 'blocjs';
import { format } from 'date-fns';
import CheckedBox from '../checkedbox';
import Icon from '../icon';
import ContactTable from '../table/contactTable';

const GroupsContactTable = ({
  groupTableData,
  handleSwitchPage,
  activeTab,
  handleActions,
}) => {
  const [selectedContacts, setSelectedContacts] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedContacts([]);
      handleActions('edit-group', []);
    } else {
      handleActions('edit-group', selectedContacts);
      setSelectedContacts(selectedContacts);
    }
    setSelectAll(!selectAll);
  }, [selectAll, selectedContacts]);

  useEffect(() => {
    if (selectedContacts.length < 1) {
      handleActions(null, null);
    }
  }, [selectedContacts.length]);

  const onCheckBox = useCallback(
    (contact, e) => {
      e.stopPropagation();
      const items = [...selectedContacts];
      const index = items.findIndex((item) => item.id === contact.id);
      if (index > -1) {
        items.splice(index, 1);
        setSelectedContacts(items);
        if (items.length < 1) {
          handleActions();
        }
      } else {
        handleActions('edit-group', [...items, contact]);
        setSelectedContacts([...items, contact]);
      }
    },
    [selectedContacts]
  );

  const onRowClick = (row, e) => {
    e.stopPropagation();
    const dataToPreview = {
      group_id: row.original.id,
      groupName: row.original.name,
    };
    handleSwitchPage('view-group', dataToPreview);
  };

  const setRowBackgroundColor = useCallback(
    (rowId) => {
      if (selectedContacts.some((item) => item.id === rowId)) {
        return '#BDFBF3';
      }
      if (selectedContacts.includes(rowId)) {
        return '#E4FFFC';
      }
      return 'transparent';
    },
    [selectedContacts]
  );

  const setCheckbox = useCallback(
    (rowId) => {
      if (selectedContacts.some((item) => item.id === rowId)) {
        return <CheckedBox color="accentDark" size={16} />;
      }
      return (
        <Box position="relative" width={16} height={16}>
          <Icon name="square" color="#959DA2" size={16} />
        </Box>
      );
    },
    [selectedContacts]
  );

  const column = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => <Bloc>{row.name}</Bloc>,
      },

      {
        Header: 'Contacts Count',
        accessor: (row) => <Bloc>{row?.contacts_count}</Bloc>,
      },
      {
        Header: 'Date Last Updated',
        accessor: (row) => (
          <Bloc>{format(new Date(row?.updated_at), 'MMM dd, yyyy')}</Bloc>
        ),
      },
      {
        Header: 'Action',
        accessor: (row) => (
          <Bloc
            py={3}
            onClick={(e) => e.stopPropagation()}
            data-test="check-col"
          >
            <Bloc
              as="span"
              style={{ cursor: 'pointer', float: 'right' }}
              data-test="check-box"
              data-testid="check-box"
              onClick={(e) => onCheckBox(row, e)}
            >
              {setCheckbox(row.id)}
            </Bloc>
          </Bloc>
        ),
      },
    ],
    [onCheckBox, setCheckbox]
  );

  return (
    <Bloc>
      <ContactTable
        selectAll={selectAll}
        selectedContacts={selectedContacts}
        toggleSelectAll={toggleSelectAll}
        setRowBackgroundColor={setRowBackgroundColor}
        columns={column}
        data={groupTableData}
        onRowClick={onRowClick}
        activeTab={activeTab}
      />
    </Bloc>
  );
};

export default GroupsContactTable;
