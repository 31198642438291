import { Bloc, Box, Flex } from 'blocjs';
import React from 'react';
import { BillingTable, Button, Text } from '../../components';
import DateFilter from '../../components/creditingSearch';

const BillingHistory = ({
  showAddFunds,
  handleShowAccountForm,
  onRowClick,
  columns,
  transactions,
  startDate,
  endDate,
  onDateChange,
  onSearch,
  isFetching,
}) => (
  <Bloc>
    <Flex
      justifyContent="space-between"
      flexWrap={['wrap', 'nowrap']}
      style={{
        gap: '15px',
      }}
    >
      <Text as="h1" fontSize={[24, 28]}>
        Crediting History
      </Text>
      {!showAddFunds && (
        <Button
          style={{
            textTransform: 'capitalize',
            fontWeight: 'normal',
            fontSize: '12px',
          }}
          profile="accentLight"
          size="dashboard"
          onClick={handleShowAccountForm}
        >
          Credit Account
        </Button>
      )}
    </Flex>

    <Box mt={20} />
    <Bloc
      mb={5}
      bg="white"
      borderRadius="8px"
      padding="16px 24px"
      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
    >
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        onDateChange={onDateChange}
        onSearch={onSearch}
        isLoading={isFetching}
      />
    </Bloc>

    <BillingTable
      onRowClick={onRowClick}
      columns={columns}
      data={transactions}
    />
  </Bloc>
);

export default BillingHistory;
