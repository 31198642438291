import React, { useState } from 'react';
import { Box, Flex, Bloc } from 'blocjs';

import UpdateOrgSettingsForm from './updateSettings';
import InviteMembersForm from '../members/inviteMembers';
import { useFetchOrg } from '../../../../hooks';
import ChangeOrgNameForm from '../changeName';
import {
  Text,
  Card,
  Button,
  Spinner,
  Inputfield,
} from '../../../../components';

const OrgSettings = ({ isMainUser }) => {
  const [currentScreen, setCurrentScreen] = useState('idle');
  const [showChangeOrgNameForm, setShowChangeOrgNameForm] = useState(false);
  const { data, status, isFetching } = useFetchOrg({ enabled: true });

  const inUseOrgData = data?.filter((org) => org.in_use)[0];
  const orgName = inUseOrgData?.name;

  const TEAM_SETTINGS_FORM = 'teamSettingsForm';
  const INVITE_MEMBERS_FORM = 'inviteMembersForm';

  const UpdateOrgSettings = () => (
    <UpdateOrgSettingsForm
      data={inUseOrgData}
      setCurrentScreen={setCurrentScreen}
      isUpdateForm={currentScreen === TEAM_SETTINGS_FORM}
    />
  );

  if (currentScreen === TEAM_SETTINGS_FORM) {
    return (
      <Card ml={[0, '16%']}>
        <UpdateOrgSettings />
      </Card>
    );
  }

  if (currentScreen === INVITE_MEMBERS_FORM) {
    return (
      <Card ml={[0, '16%']}>
        <InviteMembersForm
          setCurrentScreen={setCurrentScreen}
          orgs={inUseOrgData}
        />
      </Card>
    );
  }

  if (showChangeOrgNameForm) {
    return (
      <Card ml={[0, '16%']}>
        <ChangeOrgNameForm
          setShowChangeOrgNameForm={setShowChangeOrgNameForm}
          orgName={orgName}
        />
      </Card>
    );
  }

  return (
    <Box mb={0} display="inline-block" width="100%">
      <Box style={{ float: 'left' }} width="13.5%">
        <Text
          variant="h3"
          as="h1"
          fontSize={28}
          lineHeight="32px"
          style={{ marginBottom: '32px' }}
        >
          {' '}
          Org{' '}
        </Text>
        <Bloc
          mb={5}
          as="table"
          width="100%"
          style={{ borderCollapse: 'collapse' }}
        />
      </Box>
      <Box style={{ float: 'right' }} width={[1, '84%']}>
        <Flex justifyContent="flex-end">
          <Box ml={[3, 40]}>
            <Button
              profile="accentLight"
              size="small"
              onClick={() => setCurrentScreen(INVITE_MEMBERS_FORM)}
              data-testid="add-members"
            >
              Add members
            </Button>
          </Box>
        </Flex>
        <Card mt={6}>
          <Flex justifyContent="space-between" flexWrap="wrap">
            <Box mb={3}>
              <Text variant="h5">
                Organization Details{' '}
                {status === 'loading' && <Spinner size="large" />}
              </Text>
            </Box>
            <Box mb={3}>
              <Button
                profile="accentLight"
                size="small"
                onClick={() => setCurrentScreen(TEAM_SETTINGS_FORM)}
                data-testid="update-organization"
              >
                Update Organization
              </Button>
            </Box>
          </Flex>
          <Box mt={4} />
          {status === 'success' && !isFetching && (
            <Box>
              <Flex flexDirection={['column', 'row']}>
                <Box width={[1, 350]}>
                  <Inputfield
                    name="Org Name"
                    hint="By default we uses your username for organization"
                    defaultValue={orgName}
                    disabled
                  />
                </Box>

                {isMainUser && (
                  <Flex
                    alignSelf={[null, 'flex-end']}
                    mb={40}
                    ml={[null, 6]}
                    top={0}
                  >
                    <Text
                      color="accents.1"
                      link
                      onClick={() => setShowChangeOrgNameForm(true)}
                      data-testid="change-org-name"
                    >
                      Change Org Name
                    </Text>
                  </Flex>
                )}
              </Flex>
              <UpdateOrgSettings />
            </Box>
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default OrgSettings;
