/* eslint-disable import/no-unresolved */
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Bloc, Flex } from 'blocjs';
import posthog from 'posthog-js';

import { toast } from 'sonner';
import { Inputfield, Button } from '../../components';
import UserAPI from '../../services';

const ChangePasswordForm = ({ setShowChangePasswordForm }) => {
  const userAPI = new UserAPI();
  const { register, handleSubmit, errors, formState } = useForm();

  const { mutate, status, isPending } = useMutation({
    mutationFn: (payload) => userAPI.updateSelfPassword(payload),
  });

  const onFormSubmit = async (data) => {
    if (data) {
      const payload = {
        current_password: data.current_password,
        password: data.new_password,
        confirm_password: data.confirm_new_password,
      };
      await mutate(payload, {
        onSuccess: () => {
          toast.success('Password updated successfully');
          setTimeout(() => {
            setShowChangePasswordForm(false);
          }, 2000);
          posthog.capture('updated password', { property: true });
        },
        onError: (error) => {
          toast.error(error?.response?.data?.error?.message);
        },
      });
    }
  };

  return (
    <Bloc
      display="flex"
      style={{
        flexDirection: 'column',
        gap: '20px',
      }}
      as="form"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <Flex
        flexDirection="column"
        style={{
          gap: '10px',
        }}
      >
        <Flex
          flexWrap={['wrap', 'nowrap']}
          style={{
            gap: '10px',
          }}
        >
          <Bloc width={1}>
            <Inputfield
              placeholder="Your current password"
              type="password"
              name="Current Password"
              ref={register({ required: 'Your current password is required' })}
              error={errors?.current_password?.message}
            />
          </Bloc>
          <Bloc width={1}>
            <Inputfield
              type="password"
              placeholder="Your new password"
              name="New Password"
              ref={register({ required: 'Your new password is required' })}
              error={errors?.new_password?.message}
              data-testid="new-password"
            />
          </Bloc>
        </Flex>
        <Bloc width={['100%', '50%']}>
          <Inputfield
            type="password"
            placeholder="Confirm new password"
            name="Confirm New Password"
            ref={register({
              required: 'Confirm your new password to continue',
            })}
            error={errors?.confirm_new_password?.message}
            data-testid="confirm-password"
          />
        </Bloc>
      </Flex>

      <Flex
        justifyContent="end"
        style={{
          gap: '10px',
          borderTop: '1px solid #E5E5E5',
        }}
        pt="20px"
        flexWrap="wrap"
      >
        <Button
          profile="accentLight"
          size="contact"
          disabled={!formState.dirty}
          loading={isPending}
          type="submit"
        >
          Change Password
        </Button>
        <Button
          onClick={() => setShowChangePasswordForm(false)}
          profile="secondary"
          size="contact"
          loading={status === 'loading'}
          type="submit"
          data-testid="cancel-btn"
        >
          Cancel
        </Button>
      </Flex>
    </Bloc>
  );
};

export default ChangePasswordForm;
