export default function reverseExtraFieldsOrder(contacts) {
  return contacts.map((contact) => {
    const { extra_fields: extraField = {}, ...rest } = contact;
    const reversedExtraFields = {};
    Object.entries(extraField).forEach(([key, value]) => {
      reversedExtraFields[value] = key;
    });
    return { ...rest, extra_fields: reversedExtraFields };
  });
}
