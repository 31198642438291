/* eslint-disable import/no-unresolved */
import React, { useMemo, useState } from 'react';
import { Box, Bloc, Flex } from 'blocjs';
import posthog from 'posthog-js';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import UserAPI from '../../services';
import { Text, Button, Inputfield } from '../../components';
import useResponsiveFontSize from '../../components/payment/useResponsiveFontSize';
import CustomToast from '../../components/toast/customToast';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          color: '#4a4a4a',
          fontSize,
          fontFamily: 'Space Grotesk, sans-serif',
          '::placeholder': {
            color: '#DFDBDB',
            fontFamily: 'Space Grotesk, sans-serif',
          },
        },
        invalid: {
          color: '#EC0000',
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const ActivateInboundNumber = ({ setCurrentScreen }) => {
  const [isPaying, setIsPaying] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const userAPI = new UserAPI();
  const { handleSubmit } = useForm();
  const queryClient = useQueryClient();
  const { mutate, status, error } = useMutation({
    mutationFn: (payload) => userAPI.activateInboundNumber(payload),
  });

  const updateDataAfterSuccessPay = async () => {
    setShowToast(true);

    setTimeout(async () => {
      setCurrentScreen('idle');
      await queryClient.invalidateQueries({ queryKey: ['inboundNumbers'] });
      setIsPaying(false);
      setShowToast(false);
    }, 2000);
    posthog.capture('Activate Inbound Number');
  };

  const onActivateInbound = async () => {
    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        toast.error('Payment has not yet loaded.');
        return;
      }
      setIsPaying(true);
      mutate(
        {},
        {
          onSuccess: async (res) => {
            if (res) {
              const clientSecret = res.data.data.client_secret;
              // confirm the payment on the client
              if (clientSecret) {
                const cardDetails = elements.getElement(CardElement);
                const result = await stripe.confirmCardPayment(clientSecret, {
                  payment_method: {
                    card: cardDetails,
                  },
                });
                if (result.error) {
                  // Show error to your customer
                  toast.error(result.error.message);
                  setIsPaying(false);
                } else if (result.paymentIntent.status === 'succeeded') {
                  setShowToast(true);

                  cardDetails.clear();
                  updateDataAfterSuccessPay();
                }
              }
            }
          },
          onError: () => {
            setIsPaying(false);
            setShowToast(true);
          },
        }
      );
    } catch (err) {
      toast.error(`${err.message}, Please try again`);
      setIsPaying(false);
      setShowToast(true);
    }
  };

  return (
    <Bloc p="20px">
      <Box>
        <Text color="black" fontSize="18px" fontWeight="600">
          Create Inbound Number
        </Text>
      </Box>
      {status === 'error' && (
        <CustomToast
          showToast={showToast}
          closed
          handleHideToast={() => setShowToast(false)}
          message={error?.response?.data?.error?.message}
          status="error"
        />
      )}
      {status === 'success' && showToast && (
        <CustomToast
          showToast={showToast}
          handleHideToast={() => setShowToast(false)}
          message="You've successfully activated the inbound number"
          status="success"
          closed
        />
      )}

      <Bloc as="form" onSubmit={handleSubmit(onActivateInbound)}>
        <Bloc mt={5} width={[1, 1 / 2]}>
          <Bloc
            as="label"
            fontSize={14}
            fontWeight={500}
            mb={3}
            display="block"
          >
            Card Details
          </Bloc>
          <Bloc
            bg="#fff"
            border="1px solid"
            borderColor="#D3D3D3"
            borderRadius="4px"
            py={['10px', '8px']}
            px={3}
            maxHeight={38}
            mb={3}
          >
            <CardElement options={options} />
          </Bloc>
          <Inputfield
            type="tel"
            inputmode="numeric"
            name="amount_in_usd"
            label="Amount in USD"
            id="payInUSD"
            placeholder="Minimum amount is $5"
            data-testid="amount_in_usd"
            disabled
            value="$10.00 per month"
          />
        </Bloc>

        <Flex
          mt={4}
          pt="20px"
          style={{
            borderTop: '1px solid #DFDBDB',
            gap: '10px',
          }}
          flexWrap="wrap"
        >
          <Button
            profile="accentLight"
            size="contact"
            type="submit"
            data-testid="update-btn"
            loading={isPaying || status === 'loading'}
            disable={!stripe || !elements}
          >
            Activate Inbound Number
          </Button>
          <Button
            profile="secondary"
            size="contact"
            onClick={() => setCurrentScreen('idle')}
            data-test="cancel-btn"
          >
            Cancel
          </Button>
        </Flex>
      </Bloc>
    </Bloc>
  );
};

export default ActivateInboundNumber;
