/* eslint-disable react/no-array-index-key */
import { Bloc, Box, Flex } from 'blocjs';
import React from 'react';
import Icon from '../../icon';
import Text from '../../text';

const AddedRecipients = ({
  fileWithWarning,
  statistic,
  removeFileWithWarning,
  removeRealContact,
  removeGroupsId,
  removeUploadedGroups,
  duplicates,
  selectedContactType,
  selectedContacts,
  isUploadedContact,
  selectedGroupIds,
  selectedFileUploadedContacts,
}) => {
  const displaySelectedContacts = () => {
    const contactTypeFormatted =
      selectedContactType &&
      selectedContactType?.map((contact) => ({
        contact_type: contact,
        count:
          contact === 'archived'
            ? statistic?.archived_count
            : statistic?.active_count,
      }));
    const combined = [...contactTypeFormatted, ...selectedContacts];
    return combined;
  };

  const displayData = displaySelectedContacts();

  const showDisplayData =
    displayData.length > 0 ||
    selectedGroupIds.length > 0 ||
    isUploadedContact ||
    duplicates.length > 0 ||
    fileWithWarning.length > 0;

  return (
    <>
      {showDisplayData && (
        <Flex
          flexWrap="wrap"
          alignItems="center"
          style={{
            gap: '6px',
          }}
        >
          <Text fontSize={1} fontWeight={400}>
            Added Recipients:
          </Text>
          <Box>
            <Flex
              flexWrap="wrap"
              style={{
                gap: '5px',
              }}
            >
              {displayData?.map((contact, index) => (
                <Bloc
                  key={index}
                  display="flex"
                  alignItems="center"
                  px="5px"
                  py="4px"
                  bg="#5EEBDA63"
                  fontWeight="400"
                  fontSize="10px"
                  borderRadius="50px"
                  text-overflow="ellipsis"
                  style={{
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                    gap: '6px',
                    textTransform: 'capitalize',
                  }}
                  onClick={() => removeRealContact(contact)}
                >
                  {contact?.name} {!contact?.name && contact?.phone_number}
                  {contact?.contact_type} {contact?.count}
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    width="16px"
                    height="16px"
                    style={{
                      borderRadius: '999px',
                      backgroundColor: '#0A9080',
                    }}
                  >
                    <Icon name="close" color="white" size={6} />
                  </Flex>
                </Bloc>
              ))}

              {selectedGroupIds?.map((group, index) => (
                <Bloc
                  key={index}
                  display="flex"
                  alignItems="center"
                  px="5px"
                  py="4px"
                  bg="#5EEBDA63"
                  fontSize="10px"
                  borderRadius="50px"
                  text-overflow="ellipsis"
                  style={{
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                    textTransform: 'capitalize',
                    gap: '6px',
                  }}
                  onClick={() => removeGroupsId(group)}
                >
                  {group.name}
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    width="16px"
                    height="16px"
                    style={{
                      borderRadius: '999px',
                      backgroundColor: '#0A9080',
                      textTransform: 'initial',
                    }}
                  >
                    <Icon name="close" color="white" size={6} />
                  </Flex>
                </Bloc>
              ))}

              {isUploadedContact &&
                selectedFileUploadedContacts
                  ?.filter((items) => !items.contacts.length < 1)
                  .map((group, index) => (
                    <Bloc
                      key={index}
                      display="flex"
                      alignItems="center"
                      px="5px"
                      py="4px"
                      bg="#5EEBDA63"
                      borderRadius="50px"
                      fontSize="10px"
                      flexWrap="wrap"
                      text-overflow="ellipsis"
                      style={{
                        whiteSpace: 'nowrap',
                        cursor: 'pointer',
                        textTransform: 'capitalize',
                        gap: '6px',
                      }}
                      onClick={() => removeUploadedGroups(group)}
                    >
                      {group.group_name}
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        width="16px"
                        height="16px"
                        style={{
                          borderRadius: '999px',
                          backgroundColor: '#0A9080',
                        }}
                      >
                        <Icon name="close" color="white" size={6} />
                      </Flex>
                    </Bloc>
                  ))}

              {duplicates.length > 0 && displayData.length > 0 && (
                <Bloc as="br" />
              )}

              <Flex
                flexWrap="wrap"
                alignItems="center"
                style={{
                  gap: '3px',
                }}
              >
                <Box>
                  {fileWithWarning?.length > 0 && (
                    <Flex
                      flexWrap="wrap"
                      style={{
                        gap: '5px',
                      }}
                    >
                      {fileWithWarning.map((file, index) => (
                        <Bloc
                          key={index}
                          display="flex"
                          alignItems="center"
                          fontSize="10px"
                          px="8px"
                          py="4px"
                          color="black"
                          fontWeight="400"
                          bg="#FFB13266"
                          borderRadius="50px"
                          text-overflow="ellipsis"
                          style={{
                            whiteSpace: 'nowrap',
                            cursor: 'pointer',
                            textTransform: 'capitalize',

                            gap: '4px',
                          }}
                          onClick={() => removeFileWithWarning(file)}
                        >
                          {file.group_name}
                          <Flex
                            style={{
                              gap: '4px',
                            }}
                          >
                            {file?.duplicates?.length > 0 && (
                              <Bloc
                                display="flex"
                                alignItems="center"
                                bg="white"
                                px="6px"
                                borderRadius="50px"
                                as="span"
                                fontSize="10px"
                              >
                                {file?.duplicates?.length} duplicates
                              </Bloc>
                            )}

                            {file.invalid_contacts > 0 && (
                              <Bloc
                                display="flex"
                                alignItems="center"
                                bg="white"
                                px="6px"
                                borderRadius="50px"
                                as="span"
                                fontSize="10px"
                              >
                                {file.invalid_contacts} Invalid contacts
                              </Bloc>
                            )}

                            {file.unapproved_numbers > 0 && (
                              <Bloc
                                display="flex"
                                alignItems="center"
                                bg="white"
                                px="6px"
                                borderRadius="50px"
                                as="span"
                                fontSize="10px"
                              >
                                {file.unapproved_numbers} Unapproved numbers
                              </Bloc>
                            )}
                            {file.emptyFieldsCount > 0 && (
                              <Bloc
                                display="flex"
                                alignItems="center"
                                bg="white"
                                px="6px"
                                style={{
                                  textTransform: 'lowercase',
                                }}
                                borderRadius="50px"
                                as="span"
                                fontSize="10px"
                              >
                                {file.emptyFieldsCount} Empty fields
                              </Bloc>
                            )}

                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              width="16px"
                              height="16px"
                              style={{
                                borderRadius: '999px',
                                backgroundColor: '#6E717D',
                              }}
                            >
                              <Icon name="close" color="white" size={6} />
                            </Flex>
                          </Flex>
                        </Bloc>
                      ))}
                    </Flex>
                  )}
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default AddedRecipients;
