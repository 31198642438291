/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Bloc, Box, Flex } from 'blocjs';
import ContactContainer from './container';
import Pagination from '../pagination';
import Button from '../button';
import Icon from '../icon';
import { Inputfield } from '../inputs';
import ContactTable from '../table/contactTable';
import RenderToaster from './renderToaster';
import CheckedBox from '../checkedbox';
import { reverseExtraFieldsOrder } from '../../utils';
import Text from '../text';
import UserAPI from '../../services';
import EmptyElement from './emptyElement';
import Spinner from '../spinner';
import useGetContactFields from '../../hooks/useGetContactFields';

const PreviewGroupsContainer = ({
  handleSwitchPage,
  handleActions,
  action,
}) => {
  const userAPI = new UserAPI();

  const { location } = useHistory();
  const stateData = location.state && location.state;
  const [searchValue, setSearchValue] = useState('');
  const [fetchEnable, setFetchEnable] = useState(true);

  const groupId = stateData?.group_id;
  const groupName = stateData?.groupName;

  const itemsPerPage = 25;

  const [pageNumber, setPageNumber] = useState(1);

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  const { data: contactsData, isLoading: contactsDataIsFetching } = useQuery({
    queryKey: ['get_all_contacts', { pageNumber, groupId }],
    queryFn: () =>
      userAPI.getContacts({
        pageNumber: searchValue ? 1 : pageNumber,
        itemsPerPage,
        groupId,
        name: searchValue,
      }),
    select: (data) => ({
      contacts: reverseExtraFieldsOrder(data?.slice(0, 25)),
      pages: data?.pages || {},
    }),
    enabled: fetchEnable,
  });

  let debounceTimer;

  const handleSearch = (e) => {
    const query = e.target.value;
    e.preventDefault();
    clearTimeout(debounceTimer);

    setFetchEnable(false);

    setTimeout(() => {
      setSearchValue(query);

      setFetchEnable(true);

      setTimeout(() => {
        setSearchValue('');
      }, 0);
    }, 500);
  };

  return (
    <ContactContainer
      toast={
        <RenderToaster
          handleAction={handleActions}
          handleSwitchPage={handleSwitchPage}
          action={action}
        />
      }
      footer={
        <Bloc pt="10px">
          <Pagination
            itemsPerPage={25}
            pageNumber={pageNumber}
            pages={contactsData?.pages}
            pageCount={contactsData?.pages.pages}
            ml={0}
            isFetching={contactsDataIsFetching}
            isLoading={contactsDataIsFetching}
            changePage={changePage}
          />
        </Bloc>
      }
    >
      <>
        <Flex
          flexWrap={['wrap', 'nowrap']}
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
            gap: '10px',
          }}
          alignItems="center"
          justifyContent="space-between"
          py="20px"
          px="20px"
        >
          <Bloc
            width={['300px', '350px']}
            p={0}
            m={0}
            as="p"
            fontSize={['16px', '28px']}
            fontWeight={500}
            style={{
              whiteSpace: 'nowrap',
              color: 'black',
              overflow: 'hidden',
              textTransform: 'capitalize',
              textOverflow: 'ellipsis',
            }}
          >
            {groupName}
          </Bloc>

          {action.trigger === 'preview-group' ? (
            <Flex
              alignItems="center"
              style={{
                columnGap: '10px',
              }}
            >
              <Button
                profile="accentLight"
                size="contact"
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 'normal',
                }}
                onClick={() => {
                  handleActions(
                    'preview-group',
                    { group_id: groupId, payload: action?.result },
                    'delete-contacts-from-group'
                  );
                }}
              >
                Remove Contact
              </Button>
              <Button
                profile="secondary"
                size="contact"
                style={{
                  textTransform: 'initial',
                  fontWeight: 'normal',
                  color: 'black',
                }}
                onClick={() => {
                  handleActions(
                    'preview-group',
                    action?.result,
                    'move-to-archived'
                  );
                }}
              >
                Archive
              </Button>
            </Flex>
          ) : (
            <Flex
              alignItems="center"
              style={{
                columnGap: '10px',
              }}
            >
              <Button
                profile="accentLight"
                size="contact"
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 'normal',
                }}
                onClick={() => {
                  handleSwitchPage('edit-group', {
                    id: groupId,
                    name: groupName,
                  });
                }}
              >
                Edit
              </Button>
              <Button
                profile="secondary"
                size="contact"
                style={{
                  textTransform: 'initial',
                  fontWeight: 'normal',
                  color: 'black',
                }}
                onClick={() => {
                  handleActions(
                    'preview-group',
                    [
                      {
                        id: groupId,
                      },
                    ],
                    'delete-group'
                  );
                }}
              >
                Delete
              </Button>
              <Button
                profile="secondary"
                size="contact"
                style={{
                  textTransform: 'initial',
                  fontWeight: 'normal',
                  color: 'black',
                }}
                onClick={() => {
                  handleSwitchPage();
                }}
              >
                <Bloc as="span" hide={[true, null]}>
                  Go
                </Bloc>{' '}
                <Bloc as="span">Back</Bloc>{' '}
                <Bloc as="span" hide={[true, null]}>
                  to groups
                </Bloc>
              </Button>
            </Flex>
          )}
        </Flex>
        <Box
          py="15px"
          px="20px"
          style={{
            borderBottom: '1px solid  rgba(0, 0, 0, 0.06)',
          }}
        >
          <Flex>
            <Flex
              alignItems="center"
              py="4px"
              px="10px"
              style={{
                border: '1px solid #E7EBEF',
                columnGap: '4px',
                borderRadius: '4px',
              }}
            >
              <Icon name="search" color="#9DA1A8" size={16} dimension="24x24" />

              <Inputfield
                size="contact"
                name="text"
                style={{
                  padding: '0px',
                  border: 'none',
                }}
                placeholder="Find a contact"
                onChange={handleSearch}
                hasLabel={false}
                hasHint={false}
              />
            </Flex>
          </Flex>
        </Box>
        {contactsData?.contacts.length > 0 && !contactsDataIsFetching ? (
          <PreviewGroups
            contactsData={contactsData}
            changePage={changePage}
            action={action}
            handleSearch={handleSearch}
            handleActions={handleActions}
            pageNumber={pageNumber}
            groupId={groupId}
            groupName={groupName}
            handleSwitchPage={handleSwitchPage}
          />
        ) : contactsDataIsFetching ? (
          <Spinner size="small" />
        ) : (
          <EmptyElement title="Contacts" />
        )}
      </>
    </ContactContainer>
  );
};

export default PreviewGroupsContainer;

const PreviewGroups = ({ handleSwitchPage, contactsData, handleActions }) => {
  const [selectedContacts, setSelectedContacts] = useState([]);

  const { fieldHeaders, isLoading } = useGetContactFields({
    isMessageForm: false,
  });

  const onCheckBox = useCallback(
    (contact, e) => {
      e.stopPropagation();
      const items = [...selectedContacts];
      const index = items.findIndex((item) => item.id === contact.id);
      if (index > -1) {
        items.splice(index, 1);
        handleActions();
        setSelectedContacts(items);
      } else {
        handleActions('preview-group', [...items, contact]);
        setSelectedContacts([...items, contact]);
      }
    },
    [selectedContacts, handleActions]
  );

  const onRowClick = (row, e) => {
    e.stopPropagation();
    handleSwitchPage('view-contact', row.original);
  };

  const extraFields =
    !isLoading && fieldHeaders?.length > 0
      ? fieldHeaders.map((field) => ({
          Header: field,
          accessor: (row) => <Text>{row.extra_fields[field]}</Text>,
        }))
      : [];

  const setRowBackgroundColor = useCallback(
    (rowId) => {
      if (selectedContacts.some((item) => item.id === rowId)) {
        return '#BDFBF3';
      }
      if (selectedContacts.includes(rowId)) {
        return '#E4FFFC';
      }
      return 'transparent';
    },
    [selectedContacts]
  );

  const setCheckbox = useCallback(
    (rowId) => {
      if (selectedContacts.some((item) => item.id === rowId)) {
        return <CheckedBox color="accentDark" size={16} />;
      }
      return (
        <Box position="relative" width={16} height={16}>
          <Icon name="square" color="#959DA2" size={16} />
        </Box>
      );
    },
    [selectedContacts]
  );

  const column = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => <Bloc>{row?.name || ''}</Bloc>,
      },

      {
        Header: 'Phone',
        accessor: (row) => <Bloc>{row.phone_number}</Bloc>,
      },
      ...extraFields,
      {
        Header: 'Action',
        accessor: (row) => (
          <Bloc
            py={3}
            onClick={(e) => e.stopPropagation()}
            data-test="check-col"
          >
            <Bloc
              as="span"
              style={{ cursor: 'pointer', float: 'right' }}
              data-test="check-box"
              data-testid="check-box"
              onClick={(e) => onCheckBox(row, e)}
            >
              {setCheckbox(row.id)}
            </Bloc>
          </Bloc>
        ),
      },
    ],
    [onCheckBox, setCheckbox]
  );

  return (
    <>
      <Box>
        <ContactTable
          setRowBackgroundColor={setRowBackgroundColor}
          columns={column}
          data={contactsData?.contacts}
          onRowClick={onRowClick}
        />
      </Box>
    </>
  );
};
