import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_URL;

class UserAPI {
  authToken = localStorage.getItem('token');

  constructor() {
    axios.defaults.baseURL = backendURL;
    axios.defaults.headers.common.Authorization =
      this.authToken && `Bearer ${this.authToken}`;
  }

  makeRequest = async (token, endpoint) => {
    if (!token) return false;

    const response = await axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data?.data;
  };

  getBalance = async (token) =>
    this.makeRequest(token, '/junction/wallets/self');

  getSelfProfile = async (token) =>
    this.makeRequest(token, '/junction/profiles/self');

  getCurrentUser = async (token) =>
    this.makeRequest(token, '/junction/users/self');

  resendConfirmationEmail = async (token) =>
    this.makeRequest(token, '/junction/users/resend/confirmation');

  getToken = async ({ emailOrUsername, password }) => {
    const token = window.btoa(`${emailOrUsername}:${password}`);
    return (
      await axios.get('/junction/users/token', {
        headers: { Authorization: `Basic ${token}` },
      })
    )?.data?.data;
  };

  sendMagicLink = async (payload) =>
    (await axios.post('/junction/users/send/email/otp', payload))?.data;

  getTokenFromMagicLink = async (payload) =>
    (await axios.post('/junction/users/token/otp', payload))?.data?.data;

  getNewToken = async ({ emailOrUsername, password }) => {
    const token = window.btoa(`${emailOrUsername}:${password}`);

    return (
      await axios.get('/junction/users/refresh/token', {
        headers: { Authorization: `Basic ${token}` },
      })
    )?.data;
  };

  loginWithGithub = async (payload) =>
    (await axios.post(`/junction/users/login/github`, payload))?.data;

  loginWithGoogle = async (payload) =>
    (await axios.post(`/junction/users/login/google`, payload))?.data;

  updateUserEmail = async ({ id, email }) => {
    const response = await axios.put(`/junction/users/${id}`, {
      email,
    });
    return response;
  };

  updateAvatarPhoto = async (payload) => {
    const response = await axios.put(
      '/junction/profiles/upload/avatar',
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  };

  updateSelfProfile = async (bodyData) => {
    const response = await axios.put('/junction/profiles/self', bodyData);
    return response;
  };

  updateSelfPassword = async (bodyData) => {
    const response = await axios.put('/junction/users/password', bodyData);
    return response;
  };

  getBillingHistory = async ({ timePeriod, timezone, start, token }) => {
    if (!timePeriod && !token) return false;

    return (
      await axios.get(
        `/sms/transactions/billing?timezone_offset=${timezone}&start=${start}&timeframe=${timePeriod}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
    )?.data.data;
  };

  getCountries = async ({
    pageNumber,
    itemsPerPage,
    portabilityOption,
    regulatedOption,
    crossAttr,
  }) => {
    if (!pageNumber || !itemsPerPage) return false;

    const possibleNullParams = [];
    // Conditionally add parameters to the possibleNullParams array
    if (portabilityOption !== null && portabilityOption) {
      possibleNullParams.push(
        `has_phone_number_portability=${portabilityOption.value}`
      );
    }
    if (regulatedOption !== null && regulatedOption) {
      possibleNullParams.push(
        `is_sender_id_regulated=${regulatedOption.value}`
      );
    }
    if (crossAttr !== null && crossAttr !== '') {
      possibleNullParams.push(`cross_attr_filter=${crossAttr}`);
    }
    const finalPossibleNullParams =
      possibleNullParams.length > 0 ? `${possibleNullParams.join('&')}` : '';

    return (
      await axios.get(
        `/sms/countries/?page=${pageNumber}&per_page=${itemsPerPage}&${finalPossibleNullParams}&order_by="name"`
      )
    )?.data?.data;
  };

  getCountry = async (id) =>
    (await axios.get(`/sms/countries/${id}`))?.data?.data;

  getCountryRoutes = async (id) =>
    (await axios.get(`/sms/telcos/country-routes/?country_id=${id}`))?.data
      ?.data;

  updateCountry = async (payload) => {
    const { id, bodyData } = payload;
    const response = await axios.patch(`/sms/countries/${id}`, bodyData);
    return response;
  };

  getCountryTelcos = async (countryId) =>
    (await axios.get(`/sms/telcos/?country_id=${countryId}`))?.data?.data;

  createTelco = async (payload) =>
    (await axios.post(`/sms/telcos/`, payload))?.data;

  createService = async (payload) =>
    (await axios.post(`/sms/telcos/local-services/`, payload))?.data;

  getLocalServices = async (countryId) =>
    (await axios.get(`/sms/telcos/local-services/?country_id=${countryId}`))
      ?.data?.data;

  getServices = async () =>
    (await axios.get('/sms/telcos/local-services/'))?.data?.data
      ?.local_services;

  getAlternativeServices = async (countryId) =>
    (await axios.get(`/sms/telcos/country-services/?country_id=${countryId}`))
      ?.data?.data;

  getIntlServices = async () =>
    (await axios.get('/sms/telcos/international-services/'))?.data?.data;

  inviteOrgsMember = async ({ id, email }) =>
    (await axios.post(`/junction/orgs/${id}/invite`, email))?.data;

  acceptInvitation = async ({ token, orgId }) =>
    (await axios.get(`/junction/users/invite?token=${token}&org_id=${orgId}`))
      ?.data?.data;

  getSelfOrg = async () =>
    (await axios.get('/junction/orgs/self/'))?.data?.data;

  getOrgContacts = async (id) =>
    (await axios.get(`/junction/orgs/${id}`))?.data?.data;

  getContactStats = async () =>
    (await axios.get(`/contacts/statistics`))?.data?.data;

  getOrgMembers = async () =>
    (await axios.get(`/junction/orgs/members`))?.data?.data;

  getAllOrgs = async ({
    pageNumber,
    itemsPerPage,
    start,
    end,
    balanceStatus,
    name,
  }) => {
    const possibleNullParams = [];
    // Conditionally add parameters to the possibleNullParams array
    if (start !== null && start) {
      possibleNullParams.push(`start=${start}`);
    }
    if (end !== null && end) {
      possibleNullParams.push(`end=${end}`);
    }
    if (balanceStatus !== null && balanceStatus) {
      possibleNullParams.push(`has_sufficient_balance=${balanceStatus.value}`);
    }
    const finalPossibleNullParams =
      possibleNullParams.length > 0 ? `${possibleNullParams.join('&')}` : '';
    const response = (
      await axios.get(
        `/junction/orgs/?page=${pageNumber}&per_page=${itemsPerPage}&${finalPossibleNullParams}`,
        {
          params: {
            name,
          },
        }
      )
    )?.data?.data;
    return response;
  };

  getOrg = async (orgId) =>
    (await axios.get(`/junction/orgs/${orgId}`))?.data?.data;

  getOrgTransactions = async ({
    mainOrgId,
    pageNumber,
    itemsPerPage,
    startDate,
    endDate,
  }) => {
    const response = (
      await axios.get(
        `/junction/top-ups/?org_id=${mainOrgId}&page=${pageNumber}&per_page=${itemsPerPage}&start=${startDate}&end=${endDate}`
      )
    )?.data?.data;
    return response;
  };

  getMainUserOrg = async (orgId) =>
    (await axios.get(`/junction/orgs/${orgId}/main-user`))?.data?.data;

  getOrgSenderIDs = async (orgId) =>
    (await axios.get(`/sms/sender_ids/?org_id=${orgId}`))?.data?.data;

  // organization deal

  getOrgDeals = async ({ payload, pageNumber }) =>
    (
      await axios.post(
        `/sms/deals/list?page=${pageNumber}&per_page=${25}`,
        payload
      )
    )?.data?.data;

  createNewDeal = async (payload) =>
    (await axios.post(`/sms/deals/`, payload))?.data;

  deleteOrgDeal = async (dealId) => {
    const response = await axios.delete(`/sms/deals/${dealId}`);
    return response;
  };

  setInUseDeal = async (payload) =>
    (await axios.post(`/sms/deals/set-in-use`, payload))?.data.data;

  adminUpdateOrg = async (payload) => {
    const { orgId, bodyData } = payload;
    if (!orgId) return false;
    const response = await axios.put(`/junction/orgs/${orgId}`, bodyData);
    return response;
  };

  creditOrgWallet = async (payload) => {
    const { orgId, bodyData } = payload;
    if (!orgId) return false;
    const response = await axios.put(
      `/junction/orgs/credit/${orgId}`,
      bodyData
    );
    return response;
  };

  getSenderIDsByStatus = async ({
    status,
    pageNumber,
    name,
    itemsPerPage,
    start,
    end,
  }) => {
    const possibleNullParams = [];
    // Conditionally add parameters to the possibleNullParams array
    if (status !== null && status !== '') {
      possibleNullParams.push(`status=${status}`);
    }
    if (start !== null && start !== '') {
      possibleNullParams.push(`start=${start}`);
    }
    if (end !== null && end !== '') {
      possibleNullParams.push(`end=${end}`);
    }
    const finalPossibleNullParams =
      possibleNullParams.length > 0 ? `${possibleNullParams.join('&')}` : '';
    const response = (
      await axios.get(
        `/sms/sender_ids/?pageNumber=${pageNumber}&per_page=${itemsPerPage}&${finalPossibleNullParams}`,
        {
          params: {
            name,
          },
        }
      )
    )?.data?.data;
    return response;
  };

  reviewSenderID = async (payload) => {
    const { bodyData, senderId } = payload;
    const response = await axios.put(
      `/sms/sender_ids/${senderId}/review`,
      bodyData
    );
    return response;
  };

  removeOrgMember = async (userId) => {
    const response = await axios.put(
      `/junction/orgs/self/remove-member/${userId}`,
      null
    );
    return response;
  };

  removeOrgMembers = async (users) => {
    const response = await axios.put(`/junction/orgs/self/remove-members`, {
      user_ids: users,
    });
    return response;
  };

  activateInboundNumber = async () => {
    const response = await axios.post('/sms/inbound_numbers/subscribe');
    return response;
  };

  getInboundNumbers = async () =>
    (await axios.get(`/sms/inbound_numbers/self`))?.data?.data;

  updateInboundUrl = async (payload) => {
    const { id, webhook } = payload;
    const response = await axios.put(`/sms/inbound_numbers/self/${id}`, {
      webhook,
    });
    return response;
  };

  deleteInboundNumber = async ({ id }) => {
    const response = await axios.delete(`/sms/inbound_numbers/self/${id}`);
    return response;
  };

  updateOrgName = async ({ organizationName, id }) => {
    const payload = { name: organizationName };
    const response = await axios.put(`/junction/orgs/${id ?? 'self'}`, payload);
    return response;
  };

  switchOrg = async (orgId) => {
    const response = await axios.put(`/junction/orgs/switch-in-use-org`, {
      org_id: orgId,
    });
    return response;
  };

  updateOrganization = async (payload) => {
    const response = await axios.put('/junction/orgs/self', payload);
    return response;
  };

  getSenderIDs = async (name) =>
    (
      await axios.get('/sms/sender_ids/', {
        params: {
          name,
        },
      })
    )?.data?.data;

  getSenderIDDetails = async (id) =>
    (await axios.get(`/sms/sender_ids/${id}`))?.data?.data;

  requestSenderID = async (payload) => {
    const response = await axios.post('/sms/sender_ids', payload);
    return response;
  };

  updateSenderID = async (request) => {
    const { bodyReq, id } = request;
    const response = await axios.put(`/sms/sender_ids/${id}`, bodyReq);
    return response;
  };

  updateLocalService = async (request) => {
    const { bodyReq, id } = request;
    const response = await axios.put(
      `/sms/telcos/local-services/${id}`,
      bodyReq
    );
    return response;
  };

  switchInUseLocalService = async (payload) => {
    const response = await axios.post(
      '/sms/telcos/switch-in-use-local-service/',
      payload
    );
    return response;
  };

  updateTelco = async (request) => {
    const { bodyReq, id } = request;
    const response = await axios.put(`/sms/telcos/${id}`, bodyReq);
    return response;
  };

  createIntService = async (payload) => {
    const response = await axios.post(
      '/sms/telcos/international-services/',
      payload
    );
    return response;
  };

  updateIntService = async (request) => {
    const { bodyReq, serviceId } = request;
    const response = await axios.put(
      `/sms/telcos/international-services/${serviceId}`,
      bodyReq
    );
    return response;
  };

  setDefaultSenderID = async (id) => {
    const response = await axios.put(`/sms/sender_ids/${id}/set-default`);
    return response;
  };

  removeSenderID = async (id) => {
    const response = await axios.delete(`/sms/sender_ids/${id}`);
    return response;
  };

  removeLocalService = async (serviceId) => {
    const response = await axios.delete(
      `/sms/telcos/local-services/${serviceId}`
    );
    return response;
  };

  removeTelco = async (id) => {
    const response = await axios.delete(`/sms/telcos/${id}`);
    return response;
  };

  removeIntlService = async (serviceId) => {
    const response = await axios.delete(
      `/sms/telcos/international-services/${serviceId}`
    );
    return response;
  };

  fetchStatsData = async ({ timePeriod, timezone, token, start }) => {
    if (!timePeriod && !token) return false;
    return (
      await axios.get(
        `/v2/sms/analytics?timezone_offset=${timezone}&start=${start}&timeframe=${timePeriod}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
    )?.data?.data;
  };

  sendMessage = async (payload) => {
    const response = await axios.post('/v2/sms/bulk', payload);
    return response;
  };

  register = async (payload) => {
    const response = await axios.post('/junction/users/register', payload);
    const { data } = response;
    return data;
  };

  forgot = async (email) =>
    (await axios.post('/junction/users/forgot', { email }))?.data;

  resetPassword = async ({ token, password, confirmPassword }) =>
    (
      await axios.put(`/junction/users/recovery/${token}`, {
        password,
        confirm_password: confirmPassword,
      })
    )?.data;

  confirmEmail = async (token) =>
    (await axios.get(`/junction/users/confirm/${token}`))?.data?.data;

  filterMessages = async ({
    cursor,
    pageParam,
    statusInput,
    phoneNumber,
    direction,
    filteredEndDate,
    filteredStartDate,
    messagesPerPage,
    timezone,
  }) => {
    const cursorNull = cursor === null;
    const response = (
      await axios.get(
        `/v2/sms/self?cursor=${cursorNull ? '' : pageParam?.cursor}`,
        {
          params: {
            direction:
              direction === 'last'
                ? direction
                : pageParam?.direction || direction,
            per_page: messagesPerPage,
            to: phoneNumber,
            status: statusInput,
            start: filteredStartDate,
            end: filteredEndDate,
            timezone_offset: timezone,
          },
        }
      )
    )?.data?.data;
    return response;
  };

  exportMessages = async ({
    statusInput,
    phoneNumber,
    start,
    end,
    exportFormat,
    timezone,
  }) => {
    try {
      const encodedPhoneNumber = encodeURIComponent(phoneNumber);
      const responseType = {
        responseType: exportFormat === 'pdf' ? 'blob' : 'json',
      };
      return (
        await axios.get(
          `/v2/sms/self/export?start=${start}&end=${end}&to=${encodedPhoneNumber}&status=${statusInput}&timezone_offset=${timezone}&format=${exportFormat}`,
          responseType
        )
      )?.data;
    } catch (error) {
      const errorMessageObj = {
        data: null,
        error: { message: 'Something went wrong, Please try again' },
      };
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return error?.response?.data || errorMessageObj;
      }
      return errorMessageObj;
    }
  };

  momoPayment = async (payload) =>
    (await axios.post('/junction/top-ups/momo/make', payload))?.data;

  validateMomoPayment = async ({ paymentId }) => {
    if (!paymentId) return false;
    return (await axios.get(`/junction/top-ups/${paymentId}`))?.data;
  };

  fetchRememberedPhones = async () =>
    (await axios.get('/junction/profiles/payment_options?option=phone_numbers'))
      ?.data?.data;

  getPublishableKey = async (token) => {
    if (!token) return null;
    return (
      await axios.get('/junction/top-ups/card/pub-key', {
        headers: { Authorization: `Bearer ${token}` },
      })
    )?.data?.data;
  };

  createPaymentIntent = async (payload) =>
    (
      await axios.get(
        `/junction/top-ups/card/init?amount=${payload.amount}&save_card=${payload.save_card}`
      )
    )?.data?.data;

  fetchRememberedCards = async () =>
    (await axios.get('/junction/profiles/payment_options?option=cards'))?.data
      ?.data;

  existingCardPayment = async (payload) =>
    (
      await axios.get(
        `/junction/top-ups/card/use-saved-card?amount=${payload.amount}&last_numbers=${payload.last_numbers}`
      )
    )?.data?.data;

  removeSavedCardOrPhone = async (payload) => {
    const response = await axios.delete('/junction/profiles/payment_options', {
      data: payload,
    });
    return response;
  };

  /* Contact Management */

  createContact = async (payload) =>
    (await axios.post('/contacts', payload))?.data;

  updateContact = async (payload, id) =>
    (await axios.put(`/contacts/${id}`, payload))?.data;

  createBulkContacts = async (payload) =>
    (await axios.post('/contacts/bulk', payload))?.data;

  deleteContact = async (payload) =>
    (
      await axios.delete(`/contacts`, {
        data: payload,
      })
    )?.data;

  getContact = async (id) => (await axios.get(`/contacts/${id}`))?.data?.data;

  getContacts = async ({
    pageNumber,
    itemsPerPage = 25,
    groupId,
    name,
    phoneNumber,
    extraFields,
  }) =>
    (
      await axios.get('/contacts?state=active', {
        params: {
          page: pageNumber,
          per_page: itemsPerPage,
          group_ids: groupId,
          name,
          phone_number: phoneNumber,
          extra_fields: extraFields,
        },
      })
    )?.data?.data;

  getContactGroups = async ({ name }) =>
    (
      await axios.get('/contacts/groups', {
        params: {
          name,
        },
      })
    )?.data?.data;

  getContactGroup = async (id) =>
    (await axios.get(`/contacts/groups/${id}`))?.data?.data;

  createContactGroup = async (payload) =>
    (await axios.post('/contacts/groups', payload))?.data;

  updateContactGroup = async ({ id, payload }) =>
    (await axios.put(`/contacts/groups/${id}`, payload))?.data;

  deleteContactGroup = async (payload) =>
    (
      await axios.delete('/contacts/groups', {
        data: payload,
      })
    )?.data;

  deleteContactsInGroup = async (payload, id) =>
    (
      await axios.delete(`/contacts/groups/${id}/members`, {
        data: payload,
      })
    )?.data;

  createContactField = async (payload) =>
    (await axios.post('/contacts/fields', payload))?.data;

  updateContactField = async (payload, id) =>
    (await axios.put(`/contacts/fields/${id}`, payload))?.data;

  deleteContactField = async (payload) =>
    (
      await axios.delete('/contacts/fields', {
        data: payload,
      })
    )?.data;

  getContactField = async (id) =>
    (await axios.get(`/contacts/fields/${id}`))?.data?.data;

  getContactFields = async () =>
    (await axios.get(`/contacts/fields`))?.data?.data;

  getArchivedContacts = async () =>
    (await axios.get(`/contacts?state=archived`))?.data?.data;

  archiveContacts = async (payload) =>
    (await axios.put(`/contacts/archive`, payload))?.data?.data;

  unarchiveContacts = async (payload) =>
    (await axios.put(`/contacts/unarchive`, payload))?.data?.data;
}

export default UserAPI;
