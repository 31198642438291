/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import posthog from 'posthog-js';

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from 'sonner';
import { Bloc } from 'blocjs';
import theme from './theme';
import GlobalStyle from './global.style';
import AllRoutes from './routes';
import { useFetchUserSelf, useFetchUserProfile } from './hooks';
import { AuthContext, CurrentUserContext, MessageContext } from './contexts';
import { Icon } from './components';

const App = () => {
  const messageState = useState(null);
  const [token, setToken] = useState(null);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [loginType, setLoginType] = useState(null);

  const { data, status, isLoading } = useFetchUserSelf(token);

  const currentUser = status === 'success' &&
    data && {
      ...data,
      accountId: data.id,
    };

  const { profileData, isProfileLoading, isProfileFetching } =
    useFetchUserProfile(token, currentUser);
  let profile = useMemo(() => profileData || {}, [profileData]);

  if (currentUser && currentUser.force) profile = null;

  const addUserToken = (userToken) => {
    setToken(userToken);
    localStorage.setItem('token', userToken);
  };
  const addLoginType = (userLoginType) => {
    setLoginType(userLoginType);
    localStorage.setItem('login_type', userLoginType);
  };

  const removeUserToken = () => {
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('login_type');
  };

  const addCurrentUser = (userData) => {
    const hasAdminRole = userData?.force || false;
    setIsUserAdmin(hasAdminRole);
  };

  const removeCurrentUser = () => {
    setIsUserAdmin(false);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const userLoginType = localStorage.getItem('login_type');
    setLoginType(userLoginType);
    setToken(storedToken);
    setShowContent(true);
  }, []);

  if (currentUser) {
    posthog.identify(currentUser.email, currentUser);
    posthog.capture('loggedIn');
  }

  const isAdmin = isUserAdmin || currentUser?.force || false;

  return (
    showContent &&
    !isLoading && (
      <AuthContext.Provider
        value={{
          token,
          loginType,
          addUserToken,
          addLoginType,
          removeUserToken,
        }}
      >
        <CurrentUserContext.Provider
          value={{
            currentUser,
            addCurrentUser,
            removeCurrentUser,
            status,
            profile,
            isProfileLoading,
            isProfileFetching,
          }}
        >
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            <MessageContext.Provider value={messageState}>
              <Toaster
                position="top-center"
                offset={70}
                icons={{
                  success: (
                    <Bloc
                      bg="#3AB25C"
                      display="flex"
                      width="14px"
                      height="14px"
                      borderRadius="50%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Icon
                        name="complete_check"
                        color="white"
                        dimension="7x5"
                        size={8}
                      />
                    </Bloc>
                  ),
                  error: (
                    <Bloc
                      bg="red"
                      borderRadius="50%"
                      display="flex"
                      p="1px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Icon
                        name="second_warning"
                        color="white"
                        dimension="14x14"
                        size={12}
                      />
                    </Bloc>
                  ),
                }}
                toastOptions={{
                  classNames: {
                    toast: 'toast',
                    description: 'description',
                    actionButton: 'actionButton',
                    success: 'success',
                    error: 'error',
                    icon: 'icon',
                    cancelButton: 'cancelButton',
                  },
                }}
                style={{
                  fontFamily: 'Space Grotesk Variable',
                  fontWeight: 'normal',
                }}
                visibleToasts={2}
              />
              <AllRoutes
                isAuthenticated={!!token && !!loginType}
                isAdmin={isAdmin}
              />
            </MessageContext.Provider>
          </ThemeProvider>
        </CurrentUserContext.Provider>
      </AuthContext.Provider>
    )
  );
};

export default App;
