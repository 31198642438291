/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Box, Bloc, Flex } from 'blocjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import posthog from 'posthog-js';

import { toast } from 'sonner';
import { Button, Inputfield, PhoneInput } from '../../components';
import UserAPI from '../../services';
import { EMAIL_REGEX, validatePhoneNumber } from '../../utils';

const PersonalDetails = ({ user, profile }) => {
  const [hasError, setHasError] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors, formState } = useForm();
  const { mutate, isPending } = useMutation({
    mutationFn: (payload) => userAPI.updateSelfProfile(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['selfProfile'] });
    },
  });

  const isPhoneNumberDirty = phoneNumber !== profile.phonenumber;
  const { mutate: emailUpdateMutate, isPending: emailUpdateMutateIsPending } =
    useMutation({
      mutationFn: (payload) => userAPI.updateUserEmail(payload),
    });
  const updateDetails = async (details) => {
    if (phoneNumber) {
      const testPhoneNumber = validatePhoneNumber(phoneNumber);
      if (testPhoneNumber && testPhoneNumber.error)
        return setHasError(testPhoneNumber.error);
    }

    if (details.email !== user.email) {
      const payload = {
        id: user.accountId,
        email: details.email,
      };
      emailUpdateMutate(payload, {
        onSuccess: async () => {
          toast.success('Email updated successfully');
          await queryClient.invalidateQueries({ queryKey: ['currentUser'] });
        },
        onError: (error) => {
          toast.error(error?.response?.data?.error?.message);
        },
      });
    }
    setHasError(null);
    if (details) {
      const payload = {
        fullname: details.full_names,
        phonenumber: phoneNumber,
        address: details.address,
      };
      mutate(payload, {
        onSuccess: async () => {
          toast.success('Details updated successfully');
          await queryClient.invalidateQueries({ queryKey: 'selfProfile' });

          posthog.capture('updated self profile', { property: payload });
        },
        onError: (error) => {
          toast.error(error?.response?.data?.error?.message);
        },
      });
    }
    return true;
  };

  return (
    <Bloc as="form" onSubmit={handleSubmit(updateDetails)}>
      <Bloc
        display={['flex', 'grid']}
        flexDirection={['column', 'row']}
        pb="20px"
        style={{
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          columnGap: '20px',
        }}
      >
        <Bloc>
          <Inputfield
            name="Username"
            defaultValue={user.username}
            disabled
            hint="Fill in your username"
          />
        </Bloc>

        <Bloc>
          <Inputfield
            type="email"
            name="Email"
            ref={register({
              required: 'Your email is required',
              pattern: {
                value: EMAIL_REGEX,
                message: 'Enter a valid e-mail address',
              },
            })}
            defaultValue={user.email}
            loading={isPending}
            error={errors?.email?.message}
            hint="Fill in your email"
            data-testid="email"
          />
        </Bloc>

        <Bloc>
          <Inputfield
            type="text"
            name="Full Names"
            defaultValue={profile.fullname}
            ref={register({
              minLength: {
                value: 2,
                message: 'Full name should at least be 2 characters',
              },
            })}
            error={errors?.full_names?.message}
            hint="Fill in your full names here e.g. John D. Rockafeller"
            data-testid="full-names"
          />
        </Bloc>

        <Box>
          <Bloc
            as="label"
            fontSize="14px"
            fontWeight="500"
            mb={3}
            display="block"
          >
            Phone Number
          </Bloc>
          <Bloc role="textbox">
            <PhoneInput
              hasSearch
              phoneInput={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              existingData={profile}
              hasError={hasError}
              setHasError={setHasError}
            />
          </Bloc>
          <Bloc
            my={3}
            left={12}
            color={hasError ? 'error' : 'surfaces.2'}
            fontSize={1}
            as="p"
          >
            {hasError}
          </Bloc>
        </Box>
        <Bloc>
          <Inputfield
            type="text"
            name="Address"
            defaultValue={profile.address}
            ref={register({
              minLength: {
                value: 8,
                message: 'Address should be at least 8 characters',
              },
            })}
            error={errors?.address?.message}
            hint="Fill in your address"
            data-testid="address"
          />
        </Bloc>
      </Bloc>

      <Flex
        justifyContent="end"
        pt="20px"
        style={{
          gap: '6px',
          borderTop: '1px solid #E5E5E5',
        }}
      >
        <Button
          profile="accentLight"
          size="contact"
          loading={isPending || emailUpdateMutateIsPending}
          data-testid="updateDetailsBtn"
          disabled={
            (isPending || emailUpdateMutateIsPending || !formState.dirty) &&
            !isPhoneNumberDirty
          }
          style={{
            textTransform: 'initial',
            fontWeight: 'normal',
          }}
        >
          Save changes
        </Button>
      </Flex>
    </Bloc>
  );
};

export default PersonalDetails;
