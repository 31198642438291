import styled, { css } from 'styled-components';
import { position } from 'styled-system';

const Caret = styled('span')`
    ${({ size = 11, direction = 'down' }) => {
    const computedSize = size * 0.5;

    const caretDirectionStyle = () => {
      if (direction === 'up') {
        return css`border-top: ${computedSize}px solid transparent;`;
      }
      return css`border-bottom: ${computedSize}px solid transparent;`;
    };

    return css`
            border: ${computedSize}px solid #fff;
            border-left: ${computedSize}px solid transparent;
            border-right: ${computedSize}px solid transparent;
            ${caretDirectionStyle}
            width: 0;
            height: 0;
            vertical-align: middle;
            display: inline-block;
            ${position};
        `;
  }}
`;

export default Caret;
