import React from 'react';
import styled from "styled-components";

const ToolTipText = styled("span")(props => ({
  visibility: "hidden",
  width: "120px",
  backgroundColor: `${props.bgColor}`,
  color: `${props.color}`,
  textAlign: "center",
  borderRadius: "6px",
  position: "absolute",
  zIndex: 1,
  bottom: "150%",
  left: "50%",
  marginLeft: "-60px",
  padding: '10px',
  cursor: 'pointer',
  textDecoration: `${props.underline ? 'underline' : 'none'}`,
  ":after": {
    content: '""',
    position: "absolute",
    top: "100%",
    left: "50%",
    marginLeft: "-5px",
    borderWidth: "5px",
    borderStyle: "solid",
    borderColor: `${props.bgColor} transparent transparent transparent`
  }
}));

const ToolTipWrapper = styled("span")(props => ({
  position: "relative",
  display: "inline-block",
  borderBottom: `1px solid ${props.bgColor}`,
  cursor: 'pointer',
  color: `${props.bgColor}`,
  ":hover span": {
    visibility: "visible"
  }
}));

const ToolTip = ({ children, message, fn = () => {}, color, bgColor, underline = false}) => (
  <ToolTipWrapper color={color} bgColor={bgColor}>
    {children}
    <ToolTipText onClick={fn} color={color} bgColor={bgColor} underline={underline}>{message}</ToolTipText>
  </ToolTipWrapper>
);

export default ToolTip;
