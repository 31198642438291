const createImage = (url) =>
 new Promise((resolve, reject) => {
   const image = new Image();
   image.addEventListener("load", () => resolve(image));
   image.addEventListener("error", (error) => reject(error));
   image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues
   image.src = url;
 });


export default async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  /* setting canvas width & height allows us to 
    resize from the original image resolution */
    canvas.width = 500
    canvas.height = 500

    ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        canvas.width,
        canvas.height
    )

  // As Base64 string
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      // returning an error
      if (!blob) {
        reject(new Error("Canvas is empty"));
        return;
      }

      // creating a Object URL representing the Blob object given
      const croppedImageUrl = window.URL.createObjectURL(blob);

      resolve(croppedImageUrl);
    }, "image/jpeg");
  });
}
