/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Bloc, Flex } from 'blocjs';
import { useHistory } from 'react-router-dom';
import posthog from 'posthog-js';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import UserAPI from '../../../../services';
import { urlValidation } from '../../../../utils';
import {
  Button,
  Inputfield,
  Inputgroup,
  ToggleSwitch,
  Text,
} from '../../../../components';
import AccountContainer from '../../../userAccount/container';

const UpdateOrgSettingsForm = ({ data, isMainUser, orgName }) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const { push } = useHistory();
  const { register, handleSubmit, errors, formState } = useForm();
  const { mutate, isPending } = useMutation({
    mutationFn: (payload) => userAPI.updateOrganization(payload),
  });

  const { mutate: changeOrgNameMutate, isPending: changeOrgNameIsPending } =
    useMutation({
      mutationFn: (payload) => userAPI.updateOrgName(payload),
    });

  let defaultEnableUsageNotif = true;
  if (data && data.enable_weekly_usage_notification !== undefined) {
    defaultEnableUsageNotif = data.enable_weekly_usage_notification;
  }

  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const [weeklyNotification, setWeeklyNotification] = useState(
    defaultEnableUsageNotif
  );

  const onWeeklyNotification = (e) => {
    setIsSwitchChecked(true);
    setWeeklyNotification(e.target.checked);
  };

  const onFormSubmit = async (dataInputs) => {
    const parsedSmsRetries = Number(dataInputs.maximum_sms_retries);
    const parsedRetryInterval = Number(dataInputs.retry_interval);
    const parsedBalanceThreshold = Number(dataInputs.balance_threshold);
    const payload = {
      dlr_sms_webhook_url: dataInputs.dlr_sms_webhook_url,
      sms_max_retries: parsedSmsRetries,
      sms_retry_interval_minute: parsedRetryInterval,
      balance_threshold: parsedBalanceThreshold,
      enable_weekly_usage_notification: weeklyNotification,
    };

    if (dataInputs.org_name !== orgName) {
      changeOrgNameMutate(
        { organizationName: dataInputs.org_name },
        {
          onSuccess: async () => {
            toast.success('Organization name updated successfully');
            await queryClient.invalidateQueries({ queryKey: 'org' });
            await queryClient.invalidateQueries({ queryKey: ['selfOrg'] });
            push('/account?tab=personal_details');

            posthog.capture('updated organization name', {
              property: { organization_name: dataInputs.org_name },
            });
          },
          onError: (error) => {
            toast.error(error?.response?.data?.error?.message);
          },
        }
      );
    }

    mutate(payload, {
      onSuccess: async () => {
        toast.success('Organization updated successfully');
        await queryClient.invalidateQueries({ queryKey: 'org' });
        await queryClient.invalidateQueries({ queryKey: ['selfOrg'] });
        push('/account?tab=personal_details');

        posthog.capture('updated organization settings', { property: payload });
      },
      onError: (error) => {
        toast.error(error?.response?.data?.error?.message);
      },
    });
  };

  return (
    <AccountContainer pt={0}>
      <Flex
        flexDirection="column"
        style={{
          gap: '20px',
        }}
        p="20px"
      >
        <Text color="black" fontSize="18px" fontWeight="600">
          Account Info
        </Text>
        <Bloc as="form" onSubmit={handleSubmit(onFormSubmit)}>
          <Bloc
            display={['flex', 'grid']}
            flexDirection={['column', 'row']}
            pb="20px"
            style={{
              gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
              columnGap: '20px',
            }}
          >
            <Bloc>
              <Inputfield
                name="Org Name"
                ref={register({
                  required: 'Organization name is required',
                  minLength: {
                    value: 2,
                    message:
                      'Organization name should at least be 2 characters',
                  },
                })}
                error={errors?.org_name?.message}
                hint="By default we uses your username for organization"
                defaultValue={orgName}
                disabled={!isMainUser}
              />
            </Bloc>
            <Bloc>
              <Inputfield
                name="DLR SMS Webhook URL"
                placeholder="Webhook to manage your deliver report"
                hint="For custom delivery report management"
                ref={register({
                  pattern: urlValidation,
                })}
                error={errors?.dlr_sms_webhook_url?.message}
                defaultValue={data && data.dlr_sms_webhook_url}
                data-testid="dlr_webhook_url"
              />
            </Bloc>

            <Bloc>
              <Inputfield
                name="Daily Average Consumption"
                placeholder="Daily Average Consumption"
                defaultValue={data && data.daily_avg_consumption}
                data-testid="daily_avg_consumption"
                disabled
              />
            </Bloc>
            <Bloc>
              <Inputfield
                name="Maximum SMS Retries"
                placeholder="Times to retry sending failed SMS"
                hint="Once SMS failed this give you a chance to retry"
                ref={register({
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Please enter a number for SMS Retries',
                  },
                })}
                error={errors?.maximum_sms_retries?.message}
                defaultValue={data && data.sms_max_retries}
                data-testid="maximum_sms_retries"
              />
            </Bloc>

            <Bloc>
              <Inputgroup
                name="Balance Threshold"
                placeholder="Balance Threshold"
                hint="When your balance is below the balance threshold,"
                ref={register({
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Please enter the amount for Balance Threshold',
                  },
                })}
                error={errors?.balance_threshold?.message}
                defaultValue={data && data.balance_threshold}
                data-testid="balance_threshold"
                groupLeftText="$"
              />
            </Bloc>
            <Bloc>
              <Inputgroup
                name="Retry interval"
                placeholder="Retry interval"
                hint=""
                ref={register({
                  pattern: {
                    value: /^[0-9]*\.?[0-9]+$/,
                    message: 'Please enter a number for Retry interval',
                  },
                })}
                error={errors?.retry_interval?.message}
                defaultValue={data && data.sms_retry_interval_minute}
                data-testid="retry_interval"
                groupRightText="Minutes"
              />
            </Bloc>

            <Bloc pt="10px">
              <ToggleSwitch
                label="Enable Weekly Usage Notification"
                id="weeklyNotification"
                checked={weeklyNotification}
                onChange={onWeeklyNotification}
              />
            </Bloc>
          </Bloc>
          <Flex
            justifyContent="end"
            pt="20px"
            style={{
              gap: '6px',
              borderTop: '1px solid #E5E5E5',
            }}
          >
            <Button
              data-testid="update-btn"
              // profile="accentLight"
              profile={
                (!formState.dirty || formState.isSubmitted) && !isSwitchChecked
                  ? 'secondary'
                  : 'accentLight'
              }
              size="contact"
              loading={isPending || changeOrgNameIsPending}
              disabled={
                isPending ||
                changeOrgNameIsPending ||
                !formState.dirty ||
                formState.isSubmitted
              }
              style={{
                textTransform: 'initial',
                fontWeight: 'normal',
              }}
            >
              Save changes
            </Button>
          </Flex>
        </Bloc>
      </Flex>
    </AccountContainer>
  );
};

export default UpdateOrgSettingsForm;
