/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useHistory, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import {
  Button,
  Inputfield,
  Text,
  ToggleSwitch,
} from '../../../../../components';
import 'react-datepicker/dist/react-datepicker.css';
import { useFetchServices } from '../../../../../hooks';
import DefaultDropdownWithSearch from '../../../../../components/dropDown';
import { attachUsername } from '../../../../../utils';
import UserAPI from '../../../../../services';

const DISCOUNT_REGEX = /^([0-9]{1,2}(\.[0-9]{1,2})?|100)%?$/;
const BONUS_REGEX = /^\d+(\.\d{1,2})?$/;

const EditDeal = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();
  const userAPI = new UserAPI();
  const dealData = location.state?.deal;
  const dealStart = dealData?.start ? new Date(dealData?.start) : '';
  const dealEnd = dealData?.end ? new Date(dealData?.end) : '';
  const orgId = new URLSearchParams(location.search).get('orgId');

  const dealInUse = dealData?.in_use;

  const [startDate, setStartDate] = useState(dealStart);
  const [endDate, setEndDate] = useState(dealEnd);
  const [isInUse, setIsInUse] = useState(dealInUse || false);
  const [isItemsDropdown, setIsItemsDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [serviceError, setServiceError] = useState('');

  const [selectedService, setSelectedService] = useState({
    name: 'Select a Service',
    value: true,
  });

  const { data: serviceData, isLoading } = useFetchServices();
  const { register, handleSubmit, errors, reset } = useForm();

  const localServicesFormatted = attachUsername(serviceData || []);

  const { mutate, isPending } = useMutation({
    mutationFn: (payload) => userAPI.createNewDeal(payload),
  });

  const handleBackToDeals = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('deal');
    history.push({
      search: searchParams.toString(),
    });
  };

  const handleFilterFormDates = (type, date) => {
    if (type === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleSelectedItem = (e, item) => {
    e.stopPropagation();
    setServiceError('');
    setIsItemsDropdown(false);
    setSelectedService(item);
  };

  const switchInUseDeal = (e) => {
    setIsInUse(e.target.checked);
  };

  const onSubmit = (dataInputs) => {
    if (!startDate || !endDate) {
      setErrorMessage('Please select start and end date');
      return;
    }

    if (selectedService.value) {
      setServiceError('Please select a service');
      return;
    }

    setErrorMessage('');
    setServiceError('');

    const discount =
      dataInputs.discount === '0'
        ? parseFloat(0.0)
        : parseFloat((parseFloat(dataInputs.discount) / 100).toFixed(2));
    const payload = {
      discount,
      start: format(startDate, 'yyyy-MM-dd'),
      end: format(endDate, 'yyyy-MM-dd'),
      org_id: orgId,
      country_id: selectedService?.country_id,
    };

    if (dataInputs.bonus) {
      payload.bonus = parseFloat(Number(dataInputs.bonus).toFixed(2));
    }
    if (!selectedService?.value) {
      payload.service_id = selectedService?.id;
    }

    mutate(payload, {
      onSuccess: () => {
        toast.success('Deal updated successfully');
        setStartDate('');
        setEndDate('');
        queryClient.invalidateQueries({ queryKey: ['orgDeals'] });
        handleBackToDeals();
        reset();
        setSelectedService({
          name: 'Select a Service',
          value: true,
        });
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  return (
    <Bloc
      px={5}
      pt="20px"
      pb="40px"
      style={{
        borderTop: '1px solid #DEE1E7',
      }}
    >
      <Bloc as="form" onSubmit={handleSubmit(onSubmit)}>
        <Flex
          alignItems="start"
          flexWrap={['wrap', 'no-wrap']}
          style={{
            gap: '16px',
          }}
        >
          <Flex
            style={{
              gap: '16px',
            }}
          >
            <Box>
              <Inputfield
                type="text" // Change to "text" to allow decimal input
                name="discount"
                ref={register({
                  required: "Discount can't be empty",
                  pattern: {
                    value: DISCOUNT_REGEX,
                    message:
                      'Enter a valid discount percentage (0-100%, up to 2 decimal places)',
                  },
                })}
                defaultValue={
                  dealData?.discount
                    ? `${(dealData.discount * 100).toFixed(2)}%`
                    : ''
                }
                placeholder="Add discount here (e.g: 20.5%)"
                hint="Fill in discount percentage"
                error={errors?.discount?.message}
              />
            </Box>
            <Box>
              <Inputfield
                type="text"
                name="bonus"
                ref={register({
                  pattern: {
                    value: BONUS_REGEX,
                    message:
                      'Please enter a valid bonus with decimal places (e.g., 10.20)',
                  },
                })}
                defaultValue={dealData?.bonus || ''}
                placeholder="Add bonus here (e.g., 10.00)"
                hint="Fill in bonus "
                error={errors?.bonus?.message}
              />
            </Box>
          </Flex>
          <Box width={['100%', '25%']}>
            {!isLoading && localServicesFormatted.length > 0 && (
              <Flex
                flexDirection="column"
                style={{
                  gap: '12px',
                }}
                justifyContent="space-between"
              >
                <Text fontWeight="500" fontSize="14px">
                  Select Service *
                </Text>
                <DefaultDropdownWithSearch
                  hasSearch
                  isItemsDropdown={isItemsDropdown}
                  setIsItemsDropdown={setIsItemsDropdown}
                  handleSelectedItem={handleSelectedItem}
                  services={localServicesFormatted}
                  serviceInUseObj={selectedService}
                />
                {serviceError && (
                  <Text color="red" fontSize="12px">
                    {serviceError}
                  </Text>
                )}
              </Flex>
            )}
          </Box>

          <Flex
            flexDirection="column"
            alignItems="start"
            style={{
              gap: '10px',
            }}
          >
            <Text fontSize="14px" fontWeight="500">
              Start and End Date
            </Text>
            <Flex
              alignItems="center"
              style={{
                gap: '16px',
              }}
            >
              <Box style={{ border: '1px solid #DEE1E7', borderRadius: '4px' }}>
                <DatePicker
                  placeholderText="Start date"
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => handleFilterFormDates('start', date)}
                  calendarClassName="calendar"
                />
              </Box>
              <Box style={{ border: '1px solid #DEE1E7', borderRadius: '4px' }}>
                <DatePicker
                  placeholderText="End date"
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  minDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => handleFilterFormDates('end', date)}
                  onBlur={() => {
                    setErrorMessage('');
                  }}
                  calendarClassName="calendar"
                  calendarStartDay={1}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Bloc mt="6px">
          <Text color="red" fontSize="12px">
            {errorMessage}
          </Text>
        </Bloc>
        <Box mt="12px">
          <ToggleSwitch
            label="Deal Active"
            id="directConnection"
            checked={isInUse}
            onChange={switchInUseDeal}
          />
        </Box>
        <Flex
          mt={6}
          flexWrap="wrap"
          style={{
            gap: '20px',
          }}
        >
          <Box>
            <Button
              profile="accentLight"
              style={{
                textTransform: 'capitalize',
                fontWeight: 'normal',
              }}
              size="contact"
              type="submit"
              loading={isPending}
              disabled={isPending}
            >
              Save
            </Button>
          </Box>
          <Box>
            <Button
              profile="secondary"
              size="contact"
              type="button"
              onClick={handleBackToDeals}
            >
              Cancel
            </Button>
          </Box>
        </Flex>
      </Bloc>
    </Bloc>
  );
};

export default EditDeal;
