import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

import icons from './icons.svg';

const getViewBox = (dimension, x = 0, y = 0) => {
  const coordinates = dimension.split('x', 2);
  const dimensions = coordinates.length === 2 && !!coordinates[1]
    ? coordinates
    : [coordinates[0], coordinates[0]];
  return [x, y, ...dimensions.map((value) => parseInt(value, 10))];
};

const getAspectRatio = (dimension) => {
  const coordinates = getViewBox(dimension);
  const height = coordinates.pop();
  const width = coordinates.pop();
  return width / height;
};

const SVG = styled('svg')({
  verticalAlign: (props) => (props.align ? props.align : 'middle'),
});

const AccentSVG = ({ name, color, filled }) => {
  const themeContext = useContext(ThemeContext);
  const { colors } = themeContext;
  const hasAccent = color !== undefined;
  return (
    hasAccent && (
      <use
        fill={
          color?.split('.').reduce((o, i) => o[i], colors) || color
        }
        xlinkHref={`${icons}#${filled ? 'filled' : 'line'}_accent_${name}`}
      />
    )
  );
};

const Icon = ({
  name,
  size,
  color,
  accent,
  dimension = '512x512',
  filled = false,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);
  const { colors } = themeContext;
  const ratio = getAspectRatio(dimension);
  let width = size;
  let height = size;
  if (ratio > 1) {
    height *= ratio;
  }
  if (ratio < 1) {
    width *= ratio;
  }

  return (
    <SVG
      width={width}
      height={height}
      viewBox={getViewBox(dimension).join(' ')}
      data-testid="svg"
      {...rest}
    >
      <use
        fill={
          color?.split('.').reduce((o, i) => o[i], colors) || color
        }
        xlinkHref={`${icons}#${filled ? 'filled' : 'line'}_${name}`}
      />
      <AccentSVG name={name} color={accent} filled />
    </SVG>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default Icon;
