/* eslint-disable react/no-array-index-key */
import { Bloc, Flex } from 'blocjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, Text } from '../../components';
import { CurrentUserContext } from '../../contexts';

const Otp = ({
  length,
  refreshTokenSubmit,
  onSubmit,
  setShowOTPForm,
  isPending,
}) => {
  const inputRef = useRef([]);
  const [values, setValues] = useState(Array(length).fill(''));
  const [countdown, setCountdown] = useState(0);
  const { currentUser } = useContext(CurrentUserContext);

  const userEmail = currentUser?.email;

  const handleChange = (e, index) => {
    const inputValue = e.target.value;
    if (!inputValue.match(/^[0-9]*$/)) return;

    let newValues = [...values];

    if (inputValue.length > 1) {
      // Handle paste scenario
      const pastedValues = inputValue.slice(0, length - index).split('');
      newValues = [
        ...newValues.slice(0, index),
        ...pastedValues,
        ...newValues.slice(index + pastedValues.length),
      ].slice(0, length);

      setValues(newValues);

      // Move focus to the appropriate input field
      const nextIndex = Math.min(index + pastedValues.length, length - 1);
      inputRef.current[nextIndex].focus();
    } else {
      // Handle single character input
      newValues[index] = inputValue.slice(-1);
      setValues(newValues);

      if (inputValue && index < length - 1) {
        inputRef.current[index + 1].focus();
      }
    }

    const finalValue = newValues.join('');
    if (finalValue.length === length) {
      //   onSubmit(finalValue);
    }
  };

  const isInputSixDigit = values.join('').length === length;

  const onSubmitOtp = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const finalValue = values.join('');
    if (finalValue.length === length) {
      onSubmit(finalValue);
    }
  };

  const handleClick = (index) => {
    inputRef.current[index].setSelectionRange(1, 1);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !values[index]) {
      inputRef.current[index - 1].focus();
    }
  };

  useEffect(() => {
    if (inputRef.current[0]) {
      inputRef.current[0].focus();
    }
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    return () => {};
  }, [countdown]);

  return (
    <Flex
      flexDirection="column"
      style={{
        gap: '20px',
      }}
      p="20px"
      width="100%"
    >
      <Flex
        alignItems="center"
        style={{
          gap: '10px',
        }}
      >
        <Bloc>
          <Bloc
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="40px"
            height="40px"
            borderRadius="50%"
            bg="#9ad9d129"
          >
            <Icon name="email" color="#9ad9d129" size={20} dimension="24x24" />
          </Bloc>
        </Bloc>

        <Text fontWeight="700" fontSize={['16px', '18px']}>
          We sent you a code to make changes to your account
        </Text>
      </Flex>
      <Text fontSize="12px">
        We’ve sent a 6-character code to{' '}
        <Bloc as="span" fontWeight="600">
          {userEmail}
        </Bloc>{' '}
        to ensure secure changes to your account settings.
      </Text>
      <Bloc
        display="flex"
        flexDirection="column"
        width={1}
        style={{
          gap: '20px',
        }}
        as="form"
        onSubmit={onSubmitOtp}
      >
        <Bloc>
          {values.map((value, index) => (
            <>
              <InputFocus
                as="input"
                key={index}
                ref={(el) => {
                  inputRef.current[index] = el;
                }}
                width={['35px', '40px']}
                fontWeight="200"
                value={value}
                border={value ? '2px solid #0EB6A2' : '2px solid #DEE1E7'}
                onChange={(e) => handleChange(e, index)}
                onClick={() => handleClick(index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
              {index === 2 && (
                <Bloc color="#6E717D" as="span" fontSize="20px">
                  -
                </Bloc>
              )}
            </>
          ))}
        </Bloc>

        <Text fontSize={1} color="#6E717D">
          Didn&apos;t get a code? Check your spam folder
          {countdown > 0 ? (
            <Bloc as="span" color="#6E717D">
              {' '}
              (wait {countdown}s)
            </Bloc>
          ) : (
            <Bloc
              as="span"
              fontWeight="600"
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                refreshTokenSubmit();
                setValues(Array(length).fill(''));
                setCountdown(30); // Set 30 seconds cooldown
              }}
              color="#16c1ad"
            >
              {' '}
              send another code
            </Bloc>
          )}
        </Text>
        <Flex
          style={{
            gap: '10px',
          }}
          flexWrap="wrap"
        >
          <Button
            profile="accentLight"
            size="contact"
            disabled={!isInputSixDigit}
            loading={isPending}
            type="submit"
          >
            Refresh
          </Button>
          <Button
            profile="secondary"
            size="contact"
            onClick={() => setShowOTPForm(false)}
          >
            Cancel
          </Button>
        </Flex>
      </Bloc>
    </Flex>
  );
};

export default Otp;

const InputFocus = styled(Bloc)({
  cursor: 'pointer',

  height: '50px',
  borderRadius: '3.3px',
  margin: '5px',
  textAlign: 'center',
  fontSize: '16px',
  '&:focus': {
    outline: 'none',
    borderColor: '#0EB6A2',
  },
});
