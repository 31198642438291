/* eslint-disable import/no-unresolved */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import UserAPI from '../../../services';

const useRemoveSenderId = ({
  setSelectedSenderIDs,
  selectedSenderIDs,
  setIsSettingDefault,
  setIsRemoving,
}) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();

  const {
    mutate: mutateRemove,
    isSuccess,
    isPending,
    isError,
    error: removeError,
  } = useMutation({ mutationFn: (id) => userAPI.removeSenderID(id) });

  const handleRemoveSenderID = async (e) => {
    e.stopPropagation();

    if (selectedSenderIDs.length > 0) {
      const senderId = selectedSenderIDs.map((value) => value.id);
      mutateRemove(senderId[0], {
        onSuccess: async () => {
          toast.success('The sender ID is removed successfully');
          setIsRemoving(false);
          await queryClient.invalidateQueries({ queryKey: ['listSenderIDs'] });
          setSelectedSenderIDs([]);
          setIsSettingDefault(false);
        },
        onError: (error) => {
          toast.error(error?.response?.data?.error?.message);
          setIsRemoving(false);
        },
      });
    }
  };

  return {
    handleRemoveSenderID,
    removeError,
    isSuccess,
    isPending,
    isError,
  };
};

export default useRemoveSenderId;
