import React, { useState } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  Text,
  Button,
  CheckedBox,
  Icon,
  Spinner,
  Toast,
} from '../../../../../components';
import UserAPI from '../../../../../services';
import DefaultDropdownWithSearch from '../../../../../components/dropDown';

const data = [{}];

const TelcosTable = ({
  telcos,
  onEditTelco,
  countryData,
  isLoading,
  localServices,
  inUseServices,
}) => {
  const [selectedTelcos, setSelectedTelcos] = useState([]);
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();

  const [isRemoving, setIsRemoving] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showDropDown, setDropDown] = useState(false);
  const [isItemsDropdown, setIsItemsDropdown] = useState(false);
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useMutation({
    mutationFn: (payload) => userAPI.switchInUseLocalService(payload),
  });

  const {
    mutate: mutateRemove,
    isLoading: isRemoveLoading,
    status: removeStatus,
    error: removeError,
  } = useMutation({ mutationFn: (payload) => userAPI.removeTelco(payload) });

  const onCheckBox = (telco, e) => {
    e.stopPropagation();
    const items = [...selectedTelcos];
    const index = items.findIndex((item) => item.id === telco.id);
    if (index > -1) {
      items.splice(index, 1);
      setSelectedTelcos(items);
    } else {
      setSelectedTelcos([telco]);
    }
  };

  const setCheckbox = (rowId) => {
    if (selectedTelcos.some((item) => item.id === rowId)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16}>
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  const setRowBackgroundColor = (rowId) => {
    if (selectedTelcos.some((item) => item.id === rowId)) {
      return '#BDFBF3';
    }
    return 'transparent';
  };

  const onRemoveTelco = (e) => {
    e.stopPropagation();
    setIsRemoving(true);
  };

  const handleRemoveTelco = async (e) => {
    e.stopPropagation();

    if (selectedTelcos.length > 0) {
      const Telco = selectedTelcos.map((value) => value.id);
      await mutateRemove(Telco[0], {
        onSuccess: async () => {
          setShowToast(true);
          await queryClient.invalidateQueries({ queryKey: ['countryTelcos'] });
          setTimeout(() => {
            setSelectedTelcos([]);
            setShowToast(false);
          }, 3000);
        },
        onError: () => {
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 8000);
        },
      });
    }
  };

  const handleSwitchService = (e) => {
    e.stopPropagation();
    setDropDown(true);
  };

  const handleSelectedItem = (e, item) => {
    const telcoInfo = selectedTelcos[0];

    const payload = {
      telco_id_from: telcoInfo.id,
      telco_id_to: item.id,
    };

    mutateUpdate(payload, {
      onSuccess: async () => {
        setShowToast(true);
        await queryClient.invalidateQueries({
          queryKey: ['countryRoutes', countryData?.id],
        });
        setTimeout(async () => {
          setShowToast(false);
        }, 3000);
      },
      onError: () => {
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 8000);
      },
    });
    e.stopPropagation();
    setDropDown(false);
    setIsItemsDropdown(false);
  };

  return (
    <Bloc>
      <Flex justifyContent="space-between" p={5} pb={0}>
        <Box style={{ float: 'left' }} minWidth="16%">
          <Text as="h1" style={{ lineHeight: '32px' }} fontSize={21}>
            Telcos {isLoading && <Spinner size="large" />}
          </Text>
        </Box>
        {selectedTelcos.length === 1 && (
          <>
            {!isRemoving && (
              <Flex justifyContent="end">
                <Bloc mr={5}>
                  <Button
                    style={{
                      textTransform: 'capitalize',
                      fontWeight: 'normal',
                    }}
                    profile="accentLight"
                    size="small"
                    onClick={() => onEditTelco(selectedTelcos[0].id)}
                    data-test="update-btn"
                  >
                    Edit details
                  </Button>
                </Bloc>
                {!showDropDown && (
                  <Bloc mr={5}>
                    <Button
                      style={{
                        textTransform: 'capitalize',
                        fontWeight: 'normal',
                      }}
                      profile="accentLight"
                      size="small"
                      onClick={(e) => handleSwitchService(e)}
                      data-test="update-btn"
                    >
                      Switch Service
                    </Button>
                  </Bloc>
                )}

                <Bloc>
                  <Button
                    style={{
                      textTransform: 'capitalize',
                      fontWeight: 'normal',
                    }}
                    profile="secondary"
                    size="small"
                    loading={isLoading}
                    data-testid="refresh-token"
                    onClick={(e) => onRemoveTelco(e)}
                  >
                    Remove Telco
                  </Button>
                </Bloc>
              </Flex>
            )}

            {isRemoving && (
              <Bloc
                display="flex"
                alignItems="center"
                backgroundColor="warning"
                borderRadius={2}
                px={4}
                py={22}
                justifyContent="space-between"
              >
                {removeStatus === 'error' && (
                  <Toast
                    message={removeError?.response?.data?.error?.message}
                    status="error"
                  />
                )}
                {removeStatus === 'success' && showToast && (
                  <Toast message="Removed successfully" status="success" />
                )}
                <Text fontSize={12} color="accents.8">
                  Are you sure you want to delete this Telco ?
                </Text>

                <Flex alignItems="center">
                  <Button
                    profile="primary"
                    size="small"
                    type="button"
                    loading={isRemoveLoading}
                    onClick={handleRemoveTelco}
                    style={{ marginLeft: '10px', marginRight: '10px' }}
                    data-testid="yes-remove"
                  >
                    Yes
                  </Button>

                  <Button
                    profile="accentLight"
                    size="small"
                    type="button"
                    onClick={() => {
                      setIsRemoving(false);
                      setSelectedTelcos([]);
                    }}
                    data-testid="no-remove"
                  >
                    No
                  </Button>
                </Flex>
              </Bloc>
            )}
          </>
        )}
      </Flex>

      {statusUpdate === 'error' && showToast && (
        <Toast
          message={errorUpdate?.response?.data?.error?.message}
          status="error"
        />
      )}
      {statusUpdate === 'success' && showToast && (
        <Toast message="Service switched successfully" status="success" />
      )}
      {!isLoading && data && (
        <Box mb={0} display="inline-block" width="100%" mt={22}>
          <Bloc display="flex" flexDirection="column">
            <Bloc
              mb={0}
              as="table"
              width="100%"
              style={{ borderCollapse: 'collapse' }}
            >
              <Bloc as="thead">
                <Bloc
                  as="tr"
                  fontWeight="bold"
                  textAlign="left"
                  borderBottom="1px solid #DFDBDB"
                >
                  <Bloc as="td" fontSize={9} pl={5} py={3}>
                    Name
                  </Bloc>
                  <Bloc as="td" fontSize={9} pl={5} py={3}>
                    MCC/MNC
                  </Bloc>
                  <Bloc as="td" fontSize={9} pl={5} py={3}>
                    Direct Connection
                  </Bloc>
                  <Bloc as="td" fontSize={9} pl={5} py={3}>
                    Service In-Use
                  </Bloc>
                  <Bloc as="td" fontSize={9} pl={5} py={3} textAlign="right">
                    Selected
                  </Bloc>
                </Bloc>
              </Bloc>
              <Bloc as="tbody" pb={3}>
                {telcos.map((telco) => {
                  let statusColor = '#088C7D';
                  let statusBg = '#EBF9F5';

                  let checkServiceInUse = null;
                  let localServiceInUseId = null;

                  if (inUseServices.length > 0) {
                    checkServiceInUse = inUseServices.find(
                      (item) => item.telco_id === telco.id
                    );
                  }
                  if (
                    checkServiceInUse &&
                    checkServiceInUse?.local_service_id
                  ) {
                    localServiceInUseId = checkServiceInUse?.local_service_id;
                  }

                  let serviceInUse = 'None';
                  const serviceInUseId = localServiceInUseId;
                  let serviceInUseObj = {};

                  if (serviceInUseId) {
                    serviceInUseObj = localServices.find(
                      (item) => item.id === serviceInUseId
                    );
                    serviceInUse = serviceInUseObj?.name;
                  }

                  if (telco.has_direct_connection) {
                    statusColor = '#088C7D';
                    statusBg = '#EBF9F5';
                  } else {
                    statusColor = '#FF3049';
                    statusBg = '#FFD6DB';
                  }
                  return (
                    <Bloc
                      as="tr"
                      textAlign="left"
                      borderBottom="1px solid #DFDBDB"
                      background={setRowBackgroundColor(telco.id)}
                      id="tr-telco"
                      key={telco.id}
                    >
                      <Bloc as="td" pl={5}>
                        <Bloc
                          fontSize={12}
                          mr={35}
                          style={{ boxSizing: 'content-box' }}
                        >
                          <Text fontSize={12} color="#757F84">
                            {telco.name}
                          </Text>
                        </Bloc>
                      </Bloc>
                      <Bloc as="td" pl={5}>
                        <Bloc
                          fontSize={12}
                          mr={35}
                          style={{ boxSizing: 'content-box' }}
                        >
                          <Text fontSize={12} color="#757F84">
                            {telco.mnc}
                          </Text>
                        </Bloc>
                      </Bloc>
                      <Bloc as="td" pl={5}>
                        <Bloc
                          display="flex"
                          p={0}
                          alignItems="center"
                          fontSize={12}
                          pl={0}
                        >
                          <Bloc
                            pl={0}
                            style={{ whiteSpace: 'initial' }}
                            width={1}
                          >
                            <Bloc
                              as="span"
                              color={statusColor}
                              bg={statusBg}
                              padding="4px 10px"
                              borderRadius={27}
                              fontSize={10}
                              fontWeight="bold"
                              display="block"
                              boxSizing="border-box"
                              width="fit-content"
                              style={{ textTransform: 'uppercase' }}
                            >
                              {telco?.has_direct_connection ? 'True' : 'False'}
                            </Bloc>
                          </Bloc>
                        </Bloc>
                      </Bloc>
                      <Bloc as="td" pl={5} pr={0}>
                        {showDropDown &&
                        telco.id === selectedTelcos[0]?.id &&
                        serviceInUse !== 'None' ? (
                          <DefaultDropdownWithSearch
                            hasSearch
                            isItemsDropdown={isItemsDropdown}
                            setIsItemsDropdown={setIsItemsDropdown}
                            handleSelectedItem={handleSelectedItem}
                            services={telcos}
                            serviceInUseObj={serviceInUseObj}
                          />
                        ) : (
                          <Bloc
                            display="flex"
                            fontSize={12}
                            p={0}
                            alignItems="center"
                          >
                            <Text>{serviceInUse}</Text>
                          </Bloc>
                        )}
                      </Bloc>
                      <Bloc
                        as="td"
                        onClick={(e) => e.stopPropagation()}
                        data-test="check-col"
                        style={{ float: 'right' }}
                        display="flex"
                        alignItems="center"
                      >
                        <Bloc
                          as="span"
                          style={{ cursor: 'pointer', float: 'right' }}
                          data-test="check-box"
                          data-testid="check-box"
                          onClick={(e) => onCheckBox(telco, e)}
                        >
                          {setCheckbox(telco.id)}
                        </Bloc>
                      </Bloc>
                    </Bloc>
                  );
                })}
              </Bloc>
            </Bloc>
          </Bloc>
        </Box>
      )}
    </Bloc>
  );
};

export default TelcosTable;
