/* eslint-disable import/no-unresolved */
import React from 'react';
import { Bloc, Box } from 'blocjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import UserAPI from '../../services';
import Toast from '../toast';
import CardPayment from './cardPayment';
import Text from '../text';

const AddFunds = ({ handleHideAccountForm }) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();

  const {
    mutate: mutateRemoveSavedMethod,
    isError: isRemoveSavedError,
    error: removeSavedError,
    status: removeSavedStatus,
    isLoading: isLoadingRemoveCard,
  } = useMutation({
    mutationFn: (payload) => userAPI.removeSavedCardOrPhone(payload),
  });

  const removeSavedMethod = async (payMethod, number) => {
    // const lastFourDigits = number.slice(-4);
    const payload = {
      option: payMethod,
      last_numbers: number,
    };
    await mutateRemoveSavedMethod(payload, {
      onSuccess: async () => {
        if (payMethod === 'cards') {
          await queryClient.invalidateQueries({
            queryKey: ['rememberedCards'],
          });
          toast.success('The card was removed successfully');
        } else if (payMethod === 'phone_numbers') {
          await queryClient.invalidateQueries({
            queryKey: ['rememberedPhones'],
          });
          toast.success('The phone number was removed successfully');
        }
      },
    });
  };

  return (
    <Bloc>
      {isRemoveSavedError && (
        <Toast
          message={removeSavedError?.response?.data?.error?.message}
          status="error"
        />
      )}
      {removeSavedStatus === 'error' && (
        <Toast message={removeSavedError?.message} status="error" />
      )}

      <Bloc
        py={18}
        px={25}
        borderRadius={3}
        boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
        bg="surfaces.0"
      >
        <Box display="inline-block" width="100%">
          <Box style={{ float: 'left' }} minWidth="16%">
            <Text as="h1" fontSize={[24, 28]}>
              Crediting account
            </Text>
          </Box>
        </Box>
        <Bloc width={1} bg="#0000000F" height="1px" my="15px" />

        <Bloc mt="10px">
          <CardPayment
            handleHideAccountForm={handleHideAccountForm}
            isLoadingRemoveCard={isLoadingRemoveCard}
            removeSavedMethod={removeSavedMethod}
          />
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

export default AddFunds;
