/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Bloc, Flex } from 'blocjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from 'sonner';
import UserAPI from '../../../../services';
import { Button, Icon, Text } from '../../../../components';
import { EMAIL_REGEX } from '../../../../utils';

const InviteMembersForm = ({ setAddMember, orgs }) => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (payload) => userAPI.inviteOrgsMember(payload),
  });

  const [emailsArray, setEmailsArray] = React.useState([]);
  const [emailArrayError, setEmailArrayError] = React.useState('');
  const [emailInput, setEmailInput] = React.useState('');

  const handleKeyDown = (e) => {
    if (
      e.keyCode === 9 || // tab
      e.keyCode === 13 || // enter
      e.keyCode === 188 // comma
    ) {
      e.preventDefault();
      e.stopPropagation();
      const { value } = e.target;
      if (!EMAIL_REGEX.test(value)) {
        setEmailArrayError('Invalid email address');
        return;
      }
      if (value && value.length >= 60) {
        setEmailArrayError('email should not exceed 60 characters');
      } else {
        const checkIfSelected = emailsArray.includes(value);
        if (checkIfSelected) {
          return;
        }
        setEmailsArray((prev) => [...prev, value]);
      }
      setEmailInput('');
      setEmailArrayError(null);
    }
  };

  const onChangeInput = (e) => {
    const { value } = e.target;

    if (value && value.length >= 60) {
      setEmailArrayError('Option name should not exceed 60 characters');
    } else {
      setEmailInput(value);
      setEmailArrayError(null);
    }
  };

  const handleRemoveSelection = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    const filterSelection = emailsArray.filter((option) => item !== option); // remove selection
    setEmailsArray(filterSelection);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (emailsArray.length === 0) {
      setEmailArrayError('Please enter email address');
      return;
    }
    const emails = emailsArray.map((email) => ({
      email: email.trim(),
    }));

    emails.forEach(async (email) => {
      const payload = { id: orgs?.id, email };
      mutate(payload, {
        onSuccess: () => {
          toast.success('Members invited successfully');
          setAddMember(false);
          queryClient.invalidateQueries({
            queryKey: ['listMembers'],
          });
        },
        onError: (error) => {
          toast.error(error?.response?.data?.error?.message);
        },
      });
    });
  };

  return (
    <Bloc
      border="1px solid #E5E5E5"
      borderRadius="7.741px"
      p="20px"
      as="form"
      onSubmit={onFormSubmit}
    >
      <Bloc
        fontSize="18px"
        fontWeight="500"
        borderBottom="1px solid #E5E5E5"
        color="black"
        pb="20px"
      >
        Invite others to join
      </Bloc>

      <Bloc pt="20px">
        <Bloc
          borderRadius="4px"
          py="4px"
          px="16px"
          style={{
            border: '1px solid #E5E5E5',
          }}
        >
          {emailsArray.length > 0 && (
            <>
              <Flex
                marginBottom="8px"
                style={{
                  columnGap: '8px',
                }}
              >
                {emailsArray.map((email, index) => (
                  <Flex
                    key={index}
                    justifyContent="space-between"
                    alignItems="center"
                    px="4px"
                    py="4px"
                    style={{
                      marginTop: '6px',
                      backgroundColor: '#BDFBF3',
                      columnGap: '6px',
                      borderRadius: '999px',
                      fontSize: '12px',
                    }}
                  >
                    <Bloc>{email}</Bloc>
                    <Flex
                      onClick={(e) => handleRemoveSelection(e, email)}
                      alignItems="center"
                      justifyContent="center"
                      width="16px"
                      height="16px"
                      style={{
                        borderRadius: '999px',
                        backgroundColor: '#0A9080',
                        cursor: 'pointer',
                      }}
                    >
                      <Icon name="close" color="white" size={6} />
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </>
          )}

          <Bloc
            as="input"
            type="text"
            value={emailInput}
            placeholder="Type in comma separated tokens"
            onChange={(e) => onChangeInput(e)}
            onKeyDown={(e) => handleKeyDown(e)}
            width="100%"
            backgroundColor="transparent"
            border="0"
            fontSize="14px"
            fontWeight={400}
            padding="5px"
            data-testid="email"
            name="phoneNumber"
            style={{
              fontFamily: 'Space Grotesk Variable',
            }}
          />
        </Bloc>
        {emailArrayError && (
          <Text fontSize="10px" color="red">
            {emailArrayError}
          </Text>
        )}
      </Bloc>
      <Flex
        mt={4}
        style={{
          gap: '10px',
          borderTop: '1px solid #E5E5E5',
        }}
        flexWrap="wrap"
        pt="20px"
      >
        <Button
          profile="accentLight"
          size="contact"
          type="submit"
          loading={isPending}
          disabled={isPending || emailsArray.length === 0}
          data-testid="invite-btn"
        >
          Invite Members
        </Button>
        <Button
          profile="secondary"
          size="contact"
          disabled={isPending}
          onClick={() => {
            setAddMember(false);
          }}
          data-test="cancel-btn"
        >
          Cancel
        </Button>
      </Flex>
    </Bloc>
  );
};

export default InviteMembersForm;
