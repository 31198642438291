import React from 'react';
import { Bloc } from 'blocjs';

const InputControl = ({ children, text, value, onChange, type, name, inputMode, placeholder, borderError, ...rest }) => (
    <Bloc position="relative" zIndex="0">
        <Bloc 
            as="input" 
            type={type} 
            inputMode={inputMode} 
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            bg="#fff"
            color="#4a4a4a"
            display="block"
            width="100%"
            height="auto"
            padding="30px 8px 10px"
            boxSizing="border-box"
            border="1px solid"
            borderColor={borderError ? '#dc2727': "#D3D3D3"}
            borderRadius="4px"
            fontSize={2}
            {...rest}
        />
        <Bloc 
            as="label" 
            htmlFor={name}
            position="absolute"
            left="8px"
            top="12px"
            fontSize={14}
            fontWeight="700"
            style={{ textTransform: "uppercase" }}
        >
            {text}
        </Bloc>
        {children}
    </Bloc>
);

export default InputControl;
