import React, { useState } from 'react';
import { Flex } from 'blocjs';
import {
  Route,
  Redirect,
  Switch,
  BrowserRouter as Router,
} from 'react-router-dom';

import { ghLocationHost, ghBaseName, defaultBaseName } from './constants';
import { Navigation, Footer, Message } from './components';
import {
  Home,
  Login,
  Register,
  Messages,
  Dashboard,
  Confirmation,
  Confirmed,
  Recovery,
  Reset,
  Recovered,
  BillingDashboard,
  PageNotFound,
  Unauthorized,
  Forbidden,
  PrivacyPolicy,
  TermsOfUse,
  InviteAccepted,
  AdminManageOrg,
  SenderIDDetails,
  AdminDashboard,
  AdminManageSenderIds,
  SenderIDPolicies,
  CountriesList,
  CountryDetails,
  ManageBilling,
  ManageIntlServices,
  InvoicePage,
  CreditingPage,
  Contacts,
  Forgot,
  Account,
} from './pages';
import ManageOrgDeals from './pages/admin/manage/deals';

const Routes = ({ isAuthenticated, isAdmin }) => {
  const baseName =
    window.location.host === ghLocationHost ? ghBaseName : defaultBaseName;
  const [uniqueKey, setUniqueKey] = useState(Date.now());

  return (
    <Router basename={baseName}>
      <Flex
        flexWrap="wrap"
        flex={1}
        width={1}
        style={{
          flexDirection: 'column',
        }}
        pb={172}
      >
        <Navigation
          authenticated={isAuthenticated}
          uniqueKey={uniqueKey}
          isAdmin={isAdmin}
        />

        {isAuthenticated && <Message />}
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/confirmation">
            <Confirmation />
          </Route>
          <Route path="/confirm">
            <Confirmed />
          </Route>
          <Route path="/recovered">
            <Recovered />
          </Route>
          <Route path="/forgot">
            <Forgot />
          </Route>
          <Route path="/recovery">
            <Recovery />
          </Route>
          <Route path="/reset">
            <Reset />
          </Route>
          <Route path="/usersinvite">
            <InviteAccepted isAuth={isAuthenticated} />
          </Route>
          <Protected
            path="/dashboard"
            isAuth={isAuthenticated}
            isAdmin={isAdmin}
          >
            <Dashboard />
          </Protected>
          <AdminProtected
            path="/admin/dashboard"
            isAuth={isAuthenticated}
            isAdmin={isAdmin}
          >
            <AdminDashboard />
          </AdminProtected>
          <AdminProtected
            path="/admin/manage/organizations"
            isAuth={isAuthenticated}
            isAdmin={isAdmin}
          >
            <AdminManageOrg />
          </AdminProtected>
          <AdminProtected
            path="/admin/manage/senderids"
            isAuth={isAuthenticated}
            isAdmin={isAdmin}
          >
            <AdminManageSenderIds />
          </AdminProtected>
          <AdminProtected
            path="/admin/manage/countries"
            isAuth={isAuthenticated}
            isAdmin={isAdmin}
          >
            <CountriesList />
          </AdminProtected>
          <AdminProtected
            path="/admin/manage/country-details"
            isAuth={isAuthenticated}
            isAdmin={isAdmin}
          >
            <CountryDetails />
          </AdminProtected>
          <AdminProtected
            path="/admin/manage/billing"
            isAuth={isAuthenticated}
            isAdmin={isAdmin}
          >
            <ManageBilling />
          </AdminProtected>
          <AdminProtected
            path="/admin/manage/international-services"
            isAuth={isAuthenticated}
            isAdmin={isAdmin}
          >
            <ManageIntlServices />
          </AdminProtected>
          <AdminProtected
            path="/admin/manage/deals"
            isAuth={isAuthenticated}
            isAdmin={isAdmin}
          >
            <ManageOrgDeals />
          </AdminProtected>
          <Protected path="/account" isAuth={isAuthenticated} isAdmin={isAdmin}>
            <Account setUniqueKey={setUniqueKey} uniqueKey={uniqueKey} />
          </Protected>
          <Protected path="/sender-id-details" isAuth={isAuthenticated}>
            <SenderIDDetails />
          </Protected>
          <Protected
            path="/messages"
            isAuth={isAuthenticated}
            isAdmin={isAdmin}
          >
            <Messages />
          </Protected>
          <Protected
            path="/contacts"
            isAuth={isAuthenticated}
            isAdmin={isAdmin}
          >
            <Contacts />
          </Protected>
          <Protected path="/billing" isAuth={isAuthenticated} isAdmin={isAdmin}>
            <BillingDashboard />
          </Protected>
          <Protected path="/invoice" isAuth={isAuthenticated} isAdmin={isAdmin}>
            <InvoicePage />
          </Protected>
          <Protected path="/receipt" isAuth={isAuthenticated} isAdmin={isAdmin}>
            <InvoicePage />
          </Protected>
          <Protected
            path="/crediting"
            isAuth={isAuthenticated}
            isAdmin={isAdmin}
          >
            <CreditingPage />
          </Protected>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/terms-of-use">
            <TermsOfUse />
          </Route>
          <Route exact path="/senderid-policies">
            <SenderIDPolicies />
          </Route>
          <Route exact path="/page-not-found">
            <PageNotFound />
          </Route>
          <Route exact path="/unauthorized">
            <Unauthorized />
          </Route>
          <Route exact path="/forbidden">
            <Forbidden />
          </Route>
          <Redirect to="/page-not-found" />
        </Switch>
        <Footer />
      </Flex>
    </Router>
  );
};

export const Protected = ({ isAuth, isAdmin = false, children, ...rest }) => {
  const handleRender = ({ location }) => {
    if (isAuth) {
      if (isAdmin) {
        return (
          <Redirect
            to={{
              pathname: '/unauthorized',
              state: { from: location },
            }}
          />
        );
      }
      return children;
    }

    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  };

  return (
    <Route render={({ location }) => handleRender({ location })} {...rest} />
  );
};

export const AdminProtected = ({ isAuth, isAdmin, children, ...rest }) => {
  const handleRender = ({ location }) => {
    if (isAuth) {
      if (isAdmin) {
        return children;
      }

      return (
        <Redirect
          to={{
            pathname: '/unauthorized',
            state: { from: location },
          }}
        />
      );
    }
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  };

  return (
    <Route render={({ location }) => handleRender({ location })} {...rest} />
  );
};

export default Routes;
