import React from 'react';
import { Bloc } from 'blocjs';

const ErrorBelowInput = (message) => (
        <Bloc as="p"
            color="#dc2727"
            marginBottom="0"
            textAlign="left"
            fontSize="12px"
            display="block"
            width="100%"
            marginTop="5px"
        >
            {message}
        </Bloc>
    );

export default ErrorBelowInput;
