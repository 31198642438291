import React from 'react';
import { Bloc } from 'blocjs';
import { Layout } from '../../components';

const AccountContainer = ({ children, overflow = false, ...rest }) => (
  <Layout {...rest}>
    <Bloc
      bg="white"
      borderRadius="8px"
      overflow={overflow ? 'hidden' : ''}
      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
    >
      {children}
    </Bloc>
  </Layout>
);

export default AccountContainer;
