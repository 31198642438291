import Payment from 'payment';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js/mobile';

export const URL_REGEX =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export const EMAIL_REGEX =
  /^[A-Z0-9]+(?:[.-]?[A-Z0-9]+)*@[A-Z0-9]+(?:[.-]?[A-Z0-9]+)*\.[A-Z]{2,}$/i;

export const USERNAME_REGEX = /^[a-zA-Z0-9_.]+$/;

export const DOUBLE_REGEX = /^-?\d+(\.\d+)?$/;

export const isEmptyObj = (obj) => Object.keys(obj).length === 0;

export const validateCard = (cardNumber, cardExpiry, cardCvc) => {
  const formErrors = {};

  const validNumber = Payment.fns.validateCardNumber(cardNumber);
  if (!validNumber) {
    formErrors.cardNumber = 'Invalid Card Number.';
  }

  const validExpiryDate = Payment.fns.validateCardExpiry(cardExpiry);
  if (!validExpiryDate) {
    formErrors.cardExpiry =
      'Card may be expired. Please recheck date or try another card.';
  }

  const validCvc = Payment.fns.validateCardCVC(cardCvc);
  if (!validCvc) {
    formErrors.cardCvc = "Card's security code is incomplete.";
  }
  return formErrors;
};

export const urlValidation = {
  value: URL_REGEX,
  message: 'Please enter a valid url',
};

export const validatePhoneNumber = (phoneNumber) => {
  if (!isPossiblePhoneNumber(phoneNumber)) {
    return { error: 'The length of the phone number is incorrect' };
  }

  if (!isValidPhoneNumber(phoneNumber)) {
    return { error: 'The phone number is invalid' };
  }
  return null;
};

export const handleToastErrorMessage = (status, error) => {
  let errorMessage = '';
  if (status === 'error') {
    if (error && error.message === 'Network Error') {
      errorMessage = 'Network error';
    } else if (error && error.response) {
      errorMessage = error?.response?.data?.error?.message;
    }
  }
  return errorMessage;
};
