/* eslint-disable import/no-unresolved */
import React, { useRef, useState, useContext, useEffect } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import posthog from 'posthog-js';
import { toast } from 'sonner';
import { Text, Spinner, Button, CheckedBox, Icon } from '../../components';
import { useFetchOrgInboundNumbers, useFetchPublishableKey } from '../../hooks';
import ChangeSMSCodeForm from './changeSMSCode';
import ActivateInboundNumber from './activateInboundNumber';
import { AuthContext } from '../../contexts';
import ListInboundNumbers from './listInboundNumbers';
import AccountContainer from '../userAccount/container';
import CustomToast from '../../components/toast/customToast';
import UserAPI from '../../services';

const options = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap',
      family: 'Space Grotesk',
    },
  ],
};

const LIST_INBOUND_NUMBERS = 'idle';
const CREATE_INBOUND_NUMBER = 'createInboundNumber';
const MANAGE_INBOUND_NUMBER = 'manageInboundNumber';

const InboundNumbers = () => {
  const userAPI = new UserAPI();
  const queryClient = useQueryClient();

  const [stripeObject, setStripeObject] = useState(null);
  const [currentScreen, setCurrentScreen] = useState(LIST_INBOUND_NUMBERS);
  const [smsCode, setSMSCode] = useState({});
  const [showToast, setShowToast] = useState(null);
  const [sortedSMSCodes, setSortedSMSCodes] = useState([]);

  const { data: inboundNumbersData, status } = useFetchOrgInboundNumbers();

  const auth = useContext(AuthContext);
  const { token } = auth;
  const { data: publishableKeyData } = useFetchPublishableKey(token);

  const changeSMSCode = (code) => {
    setCurrentScreen(MANAGE_INBOUND_NUMBER);
    setSMSCode(code);
  };

  const fetchStripeObject = useRef(() => {});
  fetchStripeObject.current = async () => {
    try {
      const res = await loadStripe(publishableKeyData.publishable_key);
      setStripeObject(res);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (publishableKeyData && publishableKeyData.publishable_key) {
      fetchStripeObject.current();
    }
  }, [fetchStripeObject, publishableKeyData]);

  const smsCodes = inboundNumbersData?.codes || [];

  const onCheckBox = (inbound, e) => {
    e.stopPropagation();
    const items = [...sortedSMSCodes];

    const index = items.findIndex((item) => item.id === inbound.id);
    if (index > -1) {
      items.splice(index, 1);
      setSortedSMSCodes(items);
    } else {
      setSortedSMSCodes([inbound]);
    }
  };

  const setCheckbox = (rowId) => {
    if (sortedSMSCodes.some((item) => item.id === rowId)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return (
      <Box position="relative" width={16} height={16}>
        <Icon name="square" color="#959DA2" size={16} />
      </Box>
    );
  };

  const {
    mutate: mutateDeleteInbound,
    isSuccess: deleteInboundSuccess,
    error: deleteInboundError,
    isError: deleteInboundIsError,
    isPending: isDeleteInboundPending,
  } = useMutation({
    mutationFn: (payload) => userAPI.deleteInboundNumber(payload),
  });

  const onCancelTheSubscription = async (e, id) => {
    e.stopPropagation();
    mutateDeleteInbound(
      { id },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: 'inboundNumbers' });
          setShowToast(true);
          setTimeout(() => {
            setCurrentScreen('idle');
            setShowToast(false);
            setSortedSMSCodes([]);
          }, 2000);
          posthog.capture('Canceled the inbound number subscription');
        },
        onError: () => {
          setShowToast(true);
          setSortedSMSCodes([]);
        },
      }
    );
  };

  const setRowBackgroundColor = (rowId) => {
    if (sortedSMSCodes.some((item) => item.id === rowId)) {
      return '#BDFBF3';
    }
    return 'transparent';
  };

  return (
    <>
      {deleteInboundIsError && (
        <CustomToast
          showToast={showToast}
          message={deleteInboundError?.response?.data?.error?.message}
          status="error"
        />
      )}
      {deleteInboundSuccess && (
        <CustomToast
          showToast={showToast}
          message="You've successful removed the Inbound Number"
          status="success"
        />
      )}

      {showToast && showToast.message && showToast.status && (
        <CustomToast
          showToast={showToast}
          handleHideToast={() => setShowToast(null)}
          message={showToast.message}
          status={showToast.status}
        />
      )}
      {currentScreen === LIST_INBOUND_NUMBERS && (
        <AccountContainer pt={0}>
          <Flex
            justifyContent="flex-end"
            style={{
              borderBottom: '1px solid #DFDBDB',
              gap: '10px',
            }}
            px="20px"
            py="12px"
          >
            {sortedSMSCodes.length > 0 ? (
              <>
                <Button
                  profile="secondary"
                  size="contact"
                  onClick={(e) => {
                    onCancelTheSubscription(e, sortedSMSCodes[0].id);
                  }}
                  loading={isDeleteInboundPending}
                >
                  Deactivate number
                </Button>
              </>
            ) : (
              <Button
                profile="accentLight"
                size="contact"
                onClick={() => setCurrentScreen(CREATE_INBOUND_NUMBER)}
              >
                Lease a new number
              </Button>
            )}
          </Flex>
          <Bloc borderRadius="3px" bg="surfaces.0">
            <Flex justifyContent="space-between" flexWrap="wrap">
              <Box mb={3} padding="0 25px">
                <Text variant="h5">
                  {' '}
                  {status === 'loading' && <Spinner size="large" />}
                </Text>
              </Box>
            </Flex>
            <ListInboundNumbers
              data={smsCodes}
              setCheckbox={setCheckbox}
              setRowBackgroundColor={setRowBackgroundColor}
              handleRowClick={changeSMSCode}
              onCheckBox={onCheckBox}
              changeSMSCode={changeSMSCode}
            />
            <Box pt={0} />
          </Bloc>
        </AccountContainer>
      )}

      {currentScreen === CREATE_INBOUND_NUMBER && (
        <AccountContainer pt={0}>
          {stripeObject && (
            <Elements stripe={stripeObject} options={options}>
              <ActivateInboundNumber setCurrentScreen={setCurrentScreen} />
            </Elements>
          )}
        </AccountContainer>
      )}

      {currentScreen === MANAGE_INBOUND_NUMBER && (
        <ChangeSMSCodeForm
          setCurrentScreen={setCurrentScreen}
          smsCode={smsCode}
          isDeleteInboundPending={isDeleteInboundPending}
          onCancelTheSubscription={onCancelTheSubscription}
        />
      )}
    </>
  );
};

export default InboundNumbers;
