/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useContext } from 'react';
import { Box } from 'blocjs';
import { format } from 'date-fns';
import { toast } from 'sonner';
import { Layout } from '../../components';
import {
  getTimezone,
  extractDateLabelsBilling,
  calculateTotalCount,
} from '../../utils';
import GeneraleStats from './generaleStats';
import getStartDateOfPeriod from '../../utils/startDateFunction';
import UsageStats from './usageStats';
import BillingHistory from './billingHistory';
import { useFetchBilling } from '../../hooks';
import { AuthContext } from '../../contexts';
import { sumInvoiceRates } from '../../lib/sumInvoiceRates';

const BillingDashboard = () => {
  const [period, setPeriod] = useState('Today');
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    localStorage.setItem(
      'startDate',
      JSON.stringify({
        dateFrom: startDate,
        period,
      })
    );
  }, [period, startDate]);

  const handleChangePeriod = (date, selectPeriod) => {
    const dateFrom = getStartDateOfPeriod(
      date,
      selectPeriod === 'today' ? 'day' : selectPeriod
    );

    setStartDate(dateFrom);
  };

  const timezone = getTimezone();
  const starter = format(startDate, 'yyyy-MM-dd');

  let timePeriod;
  let displayTableDate;

  switch (period) {
    case 'Today':
      timePeriod = 'day';
      displayTableDate = format(startDate, 'LLL dd, yyyy');
      break;
    case 'Week':
      timePeriod = 'week';
      displayTableDate = 'Week';

      break;
    case 'Month':
      timePeriod = 'month';
      displayTableDate = format(startDate, 'LLL, yyyy');

      break;
    case 'Quarter':
      timePeriod = 'quarter';
      displayTableDate = format(startDate, 'QQQ, yyyy');

      break;
    case 'Year':
      timePeriod = 'year';
      displayTableDate = format(startDate, 'yyyy');

      break;
    default:
  }

  const auth = useContext(AuthContext);
  const { token } = auth;

  const { billingStatusDate, status, isFetching, isLoading, error } =
    useFetchBilling(starter, timePeriod, timezone, token);

  const DEFAULT_STATS_LABELS = 0;
  let totalMessageItems;
  let consumedAmount;
  let totalMessageSent;
  let allInitial;
  let allItemInitial;
  let allRetries;
  let statusLabels;
  let allInitialRetries;

  const rateParSMS = (billingStatusDate && billingStatusDate.sms) || [];

  if (!billingStatusDate) {
    totalMessageItems = 0;
    totalMessageSent = 0;
    allInitial = 0;
    allRetries = 0;
    allInitialRetries = 0;
    allItemInitial = 0;

    consumedAmount = 0;
    statusLabels = DEFAULT_STATS_LABELS;
  } else {
    consumedAmount = calculateTotalCount(rateParSMS, 'amount');
    allInitial = calculateTotalCount(rateParSMS, 'sms_count');
    allInitialRetries = calculateTotalCount(rateParSMS, 'sms_item_retries');
    allRetries = calculateTotalCount(rateParSMS, 'sms_retries');
    statusLabels = extractDateLabelsBilling(rateParSMS, timePeriod);
    allItemInitial = calculateTotalCount(rateParSMS, 'sms_item_count');
    totalMessageSent = allInitial + allRetries;
    totalMessageItems = allItemInitial + allInitialRetries;
  }
  const timePeriodRange = timePeriod === 'day';

  useEffect(() => {
    if (status === 'error') {
      const errorMessage = error?.response?.data?.error?.message;
      toast.error(errorMessage);
    }
  }, [error?.response?.data?.error?.message, status]);

  const messagesSent = {
    totalMessageSent,
    cards: {
      initial: allInitial,
      retries: allRetries,
    },
  };

  const messageItems = {
    totalMessageItems,
    cards: {
      itemInitial: allItemInitial,
      itemRetries: allInitialRetries,
    },
  };

  let billingData = [];
  if (billingStatusDate) {
    const removeUndefined = Object.fromEntries(
      Object.entries(rateParSMS)
        .filter(([, value]) => value.length > 0)
        .filter(([, value]) => value.some((obj) => obj.amount > 0))
    );

    billingData = Object.entries(removeUndefined).map(([key, value], index) => {
      let historyData = [];

      const date = timePeriodRange
        ? key
        : `${key} ${timePeriod === 'week' ? '' : format(startDate, 'yyyy')}`;

      historyData = {
        id: index + 1, // Incrementing index by 1 to maintain proper indexing after adding the initial object
        date,
        rates: value,
        consumedAmount: value.reduce((acc, obj) => acc + (obj.amount || 0), 0),
        allItemInitial: value.reduce(
          (acc, obj) => acc + (obj.sms_item_count || 0),
          0
        ),
        totalMessageItems: value.reduce(
          (sum, obj) =>
            sum + ((obj.sms_item_count || 0) + (obj.sms_retries || 0)),
          0
        ),

        allInitialRetries: value.reduce(
          (acc, obj) => acc + (obj.sms_item_retries || 0),
          0
        ),
        allInitial: value.reduce((acc, obj) => acc + (obj.sms_count || 0), 0),
      };

      return historyData;
    });

    // Inserting the initial object at the beginning of the array
    billingData.unshift({
      id: 0,
      date: displayTableDate,
      consumedAmount,
      totalMessageItems,
      allItemInitial,
      allInitial,
      totalMessageSent,
      allInitialRetries,
    });
  }

  const billingRates =
    Object.values(rateParSMS).flatMap((items) => items) || [];

  function calculateTotalAmountByTime(data) {
    return Object.entries(data).reduce((acc, [time, entries]) => {
      acc[time] = entries.reduce((sum, entry) => sum + entry?.amount, 0);
      return acc;
    }, {});
  }

  const totalAmountByTime = calculateTotalAmountByTime(rateParSMS);

  const summedData = sumInvoiceRates(billingRates);

  return (
    <Layout>
      <GeneraleStats
        handleChangePeriod={handleChangePeriod}
        period={period}
        setPeriod={setPeriod}
        setStartDate={setStartDate}
        startDate={startDate}
      />

      <Box mt={4} />

      <UsageStats
        consumedAmount={consumedAmount}
        messageItems={messageItems}
        messageSent={messagesSent}
        isFetching={isLoading}
        statusLabels={statusLabels}
        billingStatusDate={totalAmountByTime}
      />

      <Box mt={5} />

      {!isLoading && !isFetching && billingStatusDate && (
        <BillingHistory
          billingHistoryData={billingData}
          billingRates={billingRates}
          summedData={summedData}
        />
      )}

      <Box mt={200} />
    </Layout>
  );
};

export default BillingDashboard;
