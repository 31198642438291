const mapContacts = (contacts) =>
  contacts.map((contact) => {
    const { phone_number: phoneNumber, phonenumber, ...rest } = contact;
    return {
      ...rest,
      phonenumber: phonenumber || phoneNumber,
    };
  });

const constructSendMessagePayload = (
  selContacts,
  selGroupIds,
  selUploadedContacts
) => {
  const contactsTo = mapContacts(selContacts);

  const fileUploadedContacts = selUploadedContacts.flatMap(
    (contact) => contact?.contacts || []
  );

  const combinedContacts = [...contactsTo, ...fileUploadedContacts];

  const uniqueContactsMap = new Map();

  combinedContacts.forEach((contact) => {
    uniqueContactsMap.set(contact.phonenumber, contact);
  });

  const recipients = Array.from(uniqueContactsMap.values());
  const groupIdsSet = new Set(selGroupIds.map((group) => group.id));
  const groupIds = Array.from(groupIdsSet);

  const payload = {
    recipients,
  };
  if (groupIds.length > 0) {
    payload.group_ids = groupIds;
  }

  return payload;
};

export default constructSendMessagePayload;
