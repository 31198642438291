import React from 'react';
import { Bloc } from 'blocjs';

import { format } from 'date-fns';
import { convertUTCToLocalTime } from '../../utils';
import truncateLongString from '../../utils/trucanteLongString';

const ListInboundNumbers = ({
  data,
  onCheckBox,
  setCheckbox,
  setRowBackgroundColor,
  handleRowClick,
}) => {
  const renderInboundNumbers = () =>
    data.map((inbound) => (
      <Bloc
        as="tr"
        key={inbound?.id}
        textAlign="left"
        borderBottom={
          inbound?.id === inbound[data.length - 1]?.id
            ? ''
            : '1px solid #DFDBDB'
        }
        background={setRowBackgroundColor(inbound.id)}
        onClick={() => handleRowClick(inbound)}
        style={{ cursor: 'pointer' }}
      >
        <Bloc as="td" py={3}>
          {inbound?.number || ''}
        </Bloc>
        <Bloc as="td" py={3}>
          {truncateLongString(inbound?.webhook?.url || '', 30) || ''}
        </Bloc>

        <Bloc as="td" py={3}>
          {inbound?.webhook?.method?.split('.')[1] || ''}
        </Bloc>
        <Bloc as="td" py={3}>
          {inbound?.created_at &&
            format(convertUTCToLocalTime(inbound.created_at), 'MMM, dd yyyy')}
        </Bloc>
        <Bloc
          as="td"
          py={3}
          onClick={(e) => e.stopPropagation()}
          data-test="check-col"
        >
          <Bloc
            as="span"
            style={{ cursor: 'pointer', float: 'right' }}
            data-test="check-box"
            data-testid="check-box"
            onClick={(e) => onCheckBox(inbound, e)}
          >
            {setCheckbox(inbound?.id)}
          </Bloc>
        </Bloc>
      </Bloc>
    ));

  return (
    <Bloc overflowX="auto">
      {data && (
        <Bloc
          mb={0}
          as="table"
          width="100%"
          style={{ borderCollapse: 'collapse' }}
        >
          <Bloc as="thead">
            <Bloc
              as="tr"
              fontWeight="bold"
              textAlign="left"
              borderBottom={`${data.length > 0 ? '1px' : '0px'} solid`}
              borderColor="#DFDBDB"
            >
              <Bloc as="td" py={3} width={['auto', 'auto', 250]}>
                Phone Numbers
              </Bloc>
              <Bloc as="td" py={3} width={['auto', 'auto', 300]}>
                Webhook URL
              </Bloc>
              <Bloc as="td" py={3} width={['auto', 'auto', 300]}>
                method
              </Bloc>
              <Bloc as="td" py={3}>
                Created date
              </Bloc>
              <Bloc as="td" textAlign="right">
                SELECT
              </Bloc>
            </Bloc>
          </Bloc>
          <Bloc as="tbody">{renderInboundNumbers()}</Bloc>
        </Bloc>
      )}
    </Bloc>
  );
};

export default ListInboundNumbers;
