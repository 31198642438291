import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Bloc } from 'blocjs';

import Icon from '../icon';

const Logo = ({
  size, bg, color, accent, ...props
}) => (
  <Bloc width={size} height={size} bg={bg} data-testid="logo" {...props}>
    <Flex
      width={size}
      height={size}
      ml="2%"
      pt="1%"
      alignItems="center"
      justifyContent="center"
    >
      <Icon
        name="logo"
        dimension="405x512"
        color={color}
        accent={accent}
        size={size * 0.4}
        filled
      />
    </Flex>
  </Bloc>
);

Logo.propTypes = {
  size: PropTypes.number.isRequired,
};

export default Logo;
