import React, { useEffect, useRef, useState } from 'react';
import { Bloc } from 'blocjs';
import Layout from '../layout';

const ContactContainer = ({
  children,
  footer = null,
  toast,
  overflow = false,
}) => {
  const toastRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (toast && toastRef.current) {
      if (toastRef.current.children.length > 0) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [toast]);

  return (
    <Layout>
      <Bloc
        bg="white"
        mb="20px"
        borderRadius="10px"
        boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
        ref={toastRef}
      >
        {toast}
      </Bloc>

      <Bloc
        bg="white"
        borderRadius="8px"
        overflow={overflow ? 'hidden' : ''}
        boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
        opacity={isDisabled ? 0.4 : 1}
        style={{
          pointerEvents: isDisabled ? 'none' : 'auto',
        }}
      >
        {children}
      </Bloc>

      {footer && (
        <Bloc
          opacity={isDisabled ? 0.4 : 1}
          style={{
            pointerEvents: isDisabled ? 'none' : 'auto',
          }}
        >
          {footer}
        </Bloc>
      )}
      <Bloc mt="60px" />
    </Layout>
  );
};

export default ContactContainer;
