import React from 'react';
import { Bloc } from 'blocjs';
import { format } from 'date-fns';
import { Text, Card } from '../../components';
import { formatStatus } from '../../utils';

const ReadMessage = ({ selectedSMS }) => {

  const smsDetails = [
    {title: 'Sender', value: selectedSMS.sender},
    {title: 'Recipient', value: selectedSMS.to},
    {title: 'Status', value: formatStatus(selectedSMS.status)},
    {title: 'Sent Date', value: format(new Date(selectedSMS.created_at), 'MMM dd, yyyy hh:mm a')},
    {title: 'Message', value: selectedSMS.text}
  ];

  return (
    <Card>
      <Bloc as="table" width="100%" className="not-selectable"> 
        <Bloc as="tbody">
          {smsDetails.map((element) => (
            <Bloc as="tr" key={element.title}>
              <Bloc as="td" pl={0} color="rgb(62, 72, 77)" pb="10px" fontSize={16} width="100px">
                  <Bloc as="b">{element.title}</Bloc>
              </Bloc>
              <Bloc as="td" pl={0} color="rgb(62, 72, 77)" pb="10px" fontSize={16} 
                textAlign="justify"
                style={{ whiteSpace: 'initial' }}>
                <Text>{element.value}</Text>
              </Bloc>
            </Bloc>
          ))}
        </Bloc>
      </Bloc>
    </Card>
  );
}




export default ReadMessage;
