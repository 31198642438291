import React from 'react';
import { Bloc } from 'blocjs';

const Progressbar = ({ progressPercentage }) => (
    <Bloc 
        width={`${progressPercentage}%`} 
        color="#fff" 
        backgroundColor="accent" 
        px={3} 
        height="100%" 
        lineHeight="20px" 
        textAlign="center" 
        fontSize="12px"
        style={{
            float: "left", 
            WebkitTransition: "width .6s ease", 
            OTransition: "width .6s ease", 
            transition: "width .6s ease"
        }} 
        role="progressbar" aria-valuenow={progressPercentage} aria-valuemin="0" aria-valuemax="100">
        <Bloc as="span" className="sr-only">{progressPercentage}% Complete</Bloc>
    </Bloc>
);

export default Progressbar;
